import React from 'react'

const PaymentSuccess = () => {
  return (
    <div className='h-[100vh] flex justify-center items-center flex-col'>
        <p className='text-3xl my-2'>Welcome aboard</p>
        <p className='text-xl my-2'>We have received your fee</p>
        <a href='/reseller/login' className='text-xl bg-[#154425] text-white py-3 px-10 rounded-xl my-2'>Please login with your credentials!</a>
    </div>
  )
}

export default PaymentSuccess