import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import ProductCard from "../productCard";
import Button from "../Button";
const Products = ({ title, products }) => {

  
  return (
    <>
      <div className="text-4xl font-bold mb-10 flex items-center">
        <div className="sm:text-xl text-green-600">{title}</div>

        <div className="w-[100px] h-[2px] bg-[#154425] ml-4"></div>
      </div>
      <div className="flex flex-wrap justify-center">
        {products.map((p, i) => {
          return (
            // <div key={i} className="flex w-full">
                <ProductCard product={p} index={i} />
            // </div>
          );
        })}
      </div>
      
<div className="w-full flex justify-center">

<Button text={"Shop Now"} link={"/shops"} bgColor="#1aed60" />

</div>
    </>
  );
};

export default Products;
