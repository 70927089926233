import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../api/api'


export const get_reseller = createAsyncThunk(
    'reseller/get_reseller',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/reseller/getResellerInfo`)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)





export const resellerReducer = createSlice({
    name: 'reseller',
    initialState: {
        successMessage: '',
        errorMessage: '',
        loader: false,
        resellers: [],
        totalResellers: 0,
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ""
            state.successMessage = ""
        }
    },
    extraReducers: {
        
        [get_reseller.pending]: (state, { payload }) => {
            state.loader = true
        },
        [get_reseller.rejected]: (state, { payload }) => {
            state.loader = false
            state.errorMessage = payload
        },
        [get_reseller.fulfilled]: (state, { payload }) => {
            state.reseller = payload
        },
        // [reseller_status_update.fulfilled]: (state, { payload }) => {
        //     state.reseller = payload.reseller
        //     state.successMessage = payload.message
        // },
        // [get_active_resellers.fulfilled]: (state, { payload }) => {
        //     state.resellers = payload.reseller
        //     state.totalReseller = payload.totalReseller
        // },
        // [active_stripe_connect_account.pending]: (state, { payload }) => {
        //     state.loader = true
        // },
        // [active_stripe_connect_account.rejected]: (state, { payload }) => {
        //     state.loader = false
        //     state.errorMessage = payload.message
        // },
        // [active_stripe_connect_account.fulfilled]: (state, { payload }) => {
        //     state.loader = false
        //     state.successMessage = payload.message
        // },
    }

})
export const { messageClear } = resellerReducer.actions
export default resellerReducer.reducer