import React from "react";

const Box = ({children, color}) => {
  return (
      <div style={{backgroundColor: color}} className="rounded-3xl py-2 px-6 w-full flex items-center cursor-pointer">
        {children}
      </div>
  );
};

export default Box;
