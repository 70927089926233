import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { useSelector } from "react-redux";
import { AiFillShopping, AiFillHeart } from "react-icons/ai";
import { FaHome } from "react-icons/fa";
import { IoLogoWechat } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
const Footer = () => {
  const { card_product_count, wishlist_count } = useSelector(
    (state) => state.card
  );
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <>
      <footer className="bg-[#030a05]">
        <div className="flex flex-wrap text-white w-[90%] mx-auto justify-evenly gap-1 py-10">
          <div className="w-[250px] flex flex-col ">
            <di className="text-green-500 mb-8 text-2xl sm:text-center">
              Support
            </di>
            <div className="flex flex-col">
              <a
                href="tel:+01911182363"
                className="flex items-center border border-white text-xl rounded-full hover:border-green-500 py-4 w-[250px] justify-center"
              >
                <span>
                  <FaPhoneAlt />
                </span>
                <span className="w-[1px] h-[45px] bg-white mx-4"></span>
                <span className="border-l-white hover:text-green-500">
                  01911182363
                </span>
              </a>
              <div className="flex justify-between w-[90%] mx-auto mt-4">
                <a href="https://www.facebook.com/profile.php?id=61556140054784&mibextid=ZbWKwL" className="text-3xl bg-gray-900 p-2 rounded-[5px] hover:bg-white group">
                  <FaFacebook className="group-hover:text-blue-700"/>
                </a>
                <a href="https://www.facebook.com/groups/aladdinshopee" className="text-3xl bg-gray-900 p-2 rounded-[5px] hover:bg-white group">
                  <FaFacebook className="group-hover:text-blue-700"/>
                </a>
                <a href="https://www.tiktok.com/@aladdin.shopee" className="text-3xl bg-gray-900 p-2 rounded-[5px] hover:bg-white group">
                  <FaTiktok className="group-hover:text-black"/>
                </a>
                <a href="https://www.instagram.com/aladdinshopee/?igsh=eTRtZW4xb2g1eHQ5" className="text-3xl bg-gray-900 p-2 rounded-[5px] hover:bg-white group">
                  <FaInstagramSquare className="group-hover:text-red-500"/>
                </a>
              </div>
            </div>
          </div>
          <div className="w-[250px] flex flex-col sm:mt-5 sm:w-[320px]">
            <div className="text-green-500 mb-8 text-2xl sm:text-center">Our Company</div>
            <div className="flex flex-col sm:flex-row flex-wrap sm:justify-center">
              <Link to="/about-us" className="mb-4 text-sm sm:text-[13px] sm:border-r-[1px] sm:mr-[4px] pr-[4px] sm:mb-0 hover:text-green-500 border-r-green-500 whitespace-nowrap">About Us</Link>
              <Link to="/terms" className="mb-4 text-sm sm:text-[13px] sm:border-r-[1px] sm:mr-[4px] pr-[4px] sm:mb-0 hover:text-green-500 border-r-green-500 whitespace-nowrap">Terms & Conditions</Link>
              <Link to="/privacy" className="mb-4 text-sm sm:text-[13px] sm:border-r-[1px] sm:mr-[4px] pr-[4px] sm:mb-0 hover:text-green-500 border-r-green-500 whitespace-nowrap">Privacy Policy</Link>
              <Link to="/refund-policy" className="mb-4 text-sm sm:text-[13px] sm:border-r-[1px] sm:mr-[4px] pr-[4px] sm:mb-0 hover:text-green-500 border-r-green-500 whitespace-nowrap">Refund & Return Policy</Link>
            </div>
          </div>
          <div className="w-[250px] flex flex-col lg:mt-5">
            <div className="text-green-500 mb-8 text-2xl sm:text-center">Customer Service</div>
            <div className="flex flex-col sm:flex-row flex-wrap sm:justify-center">
              {/* <Link to="/contact-us" className="mb-4 text-sm  sm:text-[13px] sm:border-r-[1px] sm:mr-[4px] pr-[4px] sm:mb-0 hover:text-green-500 border-r-green-500 whitespace-nowrap">Contact Us</Link> */}
              <a href="https://steadfast.com.bd/tracking" target="_blank" className="mb-4 text-sm  sm:text-[13px] sm:border-r-[1px] sm:mr-[4px] pr-[4px] sm:mb-0 hover:text-green-500 border-r-green-500 whitespace-nowrap">Track Your Orders</a>
              <Link to="/replace-policy" className="mb-4 text-sm  sm:text-[13px] sm:border-r-[1px] sm:mr-[4px] pr-[4px] sm:mb-0 hover:text-green-500 border-r-green-500 whitespace-nowrap">Replacement Policy</Link>
            </div>
          </div>
          <div className="w-[250px] flex flex-col lg-mt-5 ">
            <div className="text-green-500 mb-8 text-2xl sm:text-center">Connect With Us</div>
            <div className="flex flex-col  sm:flex-row flex-wrap sm:justify-center">
              <a href="https://www.facebook.com/profile.php?id=61556140054784&mibextid=ZbWKwL"className="mb-4 text-sm  sm:text-[13px] sm:border-r-[1px] sm:mr-[4px] pr-[4px] sm:mb-0 hover:text-green-500 border-r-green-500 whitespace-nowrap">Facebook</a>
              <a href="https://www.facebook.com/groups/aladdinshopee"className="mb-4 text-sm  sm:text-[13px] sm:border-r-[1px] sm:mr-[4px] pr-[4px] sm:mb-0 hover:text-green-500 border-r-green-500 whitespace-nowrap">Facebook group</a>
              <a href="https://www.instagram.com/aladdinshopee/?igsh=eTRtZW4xb2g1eHQ"className="mb-4 text-sm  sm:text-[13px] sm:border-r-[1px] sm:mr-[2px] pr-[2px] sm:mb-0 hover:text-green-500 border-r-green-500 whitespace-nowrap">Instagram</a>
              <a href="mailto:aladdinshopee@gmail.com"className="mb-4 text-sm  sm:text-[13px] sm:border-r-[1px] sm:mr-[4px] pr-[4px] sm:mb-0 hover:text-green-500 border-r-green-500 whitespace-nowrap">Email</a>
            </div>
          </div>
        </div>

        {/* <div className="flex items-center justify-center border-b-2 border-neutral-200 p-6 dark:border-white/10 lg:justify-between"></div>
        <div className="max-w-[1200px] w-[70%] mx-auto py-10 md:text-left">
          <div className="flex justify-between flex-wrap lg:flex-col">
            <div className="flex-1 lg:mt-2">
              <span>
                <Link to={"/"}>
                  <img
                    src="/main-logo-white.webp"
                    className="w-20"
                    alt="main logo"
                  />
                </Link>
              </span>
            </div>

            <div className="flex-1  lg:mt-2 m-2">
              <h6 className="mb-4 flex font-semibold uppercase md:justify-start text-white">
                Useful links
              </h6>
              <div className="text-gray-500 font-bold">
                <p className="mb-2">
                  <Link to={userInfo ? `/dashboard/my-orders` : `/login`}>
                    Orders
                  </Link>
                </p>
                <p className="mb-2">
                  <Link to={userInfo ? `/dashboard` : `/login`}>Dashboard</Link>
                </p>
                <p className="mb-2">
                  <Link to={userInfo ? `/card` : `/login`}>Cart</Link>
                </p>
                <p>
                  <Link to="/shops">Shop</Link>
                </p>
              </div>
            </div>
            <div className="flex-1 lg:mt-2">
              <h6 className="mb-4 flex font-semibold uppercase md:justify-start text-white">
                Contact
              </h6> */}
              {/* <p class="mb-2 flex">
              <span class="me-3 [&>svg]:h-5 [&>svg]:w-5">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                >
                <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                </svg>
                </span>
                New York, NY 10012, US
              </p> */}
              {/* <p className="mb-2 flex ">
                <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="text-gray-500 "
                  >
                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                    <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                  </svg>
                </span>
                <a
                  href="mailto:Aladdinshopee@gmail.com"
                  className="text-gray-500 font-bold"
                >
                  aladdinshopee@gmail.com
                </a>
              </p>
              <p className="mb-2 flex ">
                <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="text-gray-500 "
                  >
                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                    <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                  </svg>
                </span>
                <a
                  href="mailto:info@aladdinshopee.com"
                  className="text-gray-500 font-bold"
                >
                  info@aladdinshopee.com
                </a>
              </p>
              <p className="mb-2 flex  ">
                <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="text-gray-500 "
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>

                <a href="tel:01911182363" className="text-gray-500 font-bold">
                  +8801911182363
                </a>
              </p>
              <p className="mb-2 flex  ">
                <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="text-gray-500 "
                  >
                    <IoLogoWechat />
                  </svg>
                </span>

                <a href="tel:01911182363" className="text-gray-500 font-bold">
                  +8801911182363
                </a>
              </p>
              <p className="mb-2 flex  ">
                <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="text-gray-500 "
                  >
                    <IoLogoWhatsapp />
                  </svg>
                </span>

                <a href="tel:01911182363" className="text-gray-500 font-bold">
                  +8801911182363
                </a>
              </p>
              <p className="mb-2 flex  ">
                <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <FaHome className="text-gray-500 " />
                </span>

                <p className="text-gray-500 font-bold">Dhaka, Bangladesh</p>
              </p>
            </div>
            <div className="flex-1 lg:mt-2 m-2">
              <h2 className="text-lg mb-2 text-white  font-semibold">
                Business
              </h2>
              <ul className="flex flex-col gap-2 text-slate-600 text-sm">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=61556140054784&mibextid=ZbWKwL"
                    className="text-gray-500 font-bold"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/groups/aladdinshopee"
                    className="text-gray-500 font-bold"
                  >
                    Facebook Group
                  </a>
                </li>
                <li>
                  <a
                    href="tiktok.com/@aladdin.shopee"
                    className="text-gray-500 font-bold"
                  >
                    Tiktok
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/aladdinshopee/?igsh=eTRtZW4xb2g1eHQ5"
                    className="text-gray-500 font-bold"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://portal.aladdinshopee.com/register"
                    className="text-gray-500 font-bold"
                  >
                    Sell with Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        {!userInfo && (
          <div className="container p-6 bg-[#154425] text-white">
            <div className="">
              <p className="flex items-center justify-center">
                <span className="me-4">Register As Reseller</span>
                <Link
                  to="/reseller/register"
                  className="inline-block rounded-full border-2 border-green-50 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-green-50 transition duration-150 ease-in-out hover:border-green-300 hover:text-green-200 focus:border-green-300 focus:text-green-200 focus:outline-none focus:ring-0 active:border-green-300 active:text-green-200 dark:hover:bg-green-600 dark:focus:bg-green-600"
                  replace
                >
                  Sign up!
                </Link>
              </p>
            </div>
          </div>
        )}
        <div className="w-[100%] flex flex-wrap justify-center items-center text-slate-400 mx-auto py-5 text-center text-xs font-bold bg-black sm:pb-24">
          <span>Copyright ©2024 All rights reserved</span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
