import React, { useState,useEffect } from 'react'
import Headers from '../components/Headers'
import Footer from '../components/Footer'
import { FaFacebookF } from 'react-icons/fa'
import { Link,useNavigate } from 'react-router-dom'
import { AiOutlineGoogle } from 'react-icons/ai'
import FadeLoader from 'react-spinners/FadeLoader'
import { useSelector, useDispatch } from 'react-redux'
import { customer_login, messageClear } from '../store/reducers/authReducer'
import toast from 'react-hot-toast'

import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

const Login = () => {
const [showPass, setShowPass] = useState(false)
    const { loader, successMessage, errorMessage, userInfo } = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const [state, setState] = useState({
        email: '',
        password: ''
    })

    const inputHandle = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    const login = (e) => {
        e.preventDefault()
        dispatch(customer_login(state))
    }

    useEffect(() => {
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
        }
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if(userInfo){
            navigate('/')
        }
    }, [successMessage, errorMessage])

    return (
        <div>
            <Headers />
            {
                loader && <div className='w-screen h-screen flex justify-center items-center fixed left-0 top-0 bg-[#38303033] z-[999]'>
                    <FadeLoader />
                </div>
            }
            <div className='border  border-t-[#0002] mt-4'>
                <div className='w-full justify-center items-center p-10 sm:px-0'>
                <div className="flex justify-center mb-6">
            <Link to={'/login'} className="text-3xl text-gray-900 mr-3 font-extrabold">Log In</Link>
            <Link to={'/register'} className="text-3xl text-gray-400 ml-3 font-extrabold">Register</Link>

          </div>
                     <div className="min-w-[350px] w-[40%]  mx-auto bg-white rounded-3xl border border-[#0002]">
                        <div className='px-8 py-8 w-full'>
                            <h2 className='text-center w-full text-xl text-slate-600 font-bold'>Login</h2>
                            <div>
                                <form onSubmit={login} className='text-slate-600'>
                                    <div className='relative py-3 gap-1 mb-2'>
                                        <input onChange={inputHandle} value={state.email} type="email" className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md' id='email' name='email' required />
                                        
                                        <label htmlFor="email"  className="absolute label">Email</label>
                                    </div>
                                    <div className='relative py-3 mb-4'>
                                        
                                        <input onChange={inputHandle} value={state.password} type={showPass?"text":"password"} className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md' id='password' name='password' required/>
                                        <label htmlFor="password"  className="absolute label">Password</label>
                                        
                    <div
                      className="absolute right-[20px] top-[50%] translate-y-[-50%] cursor-pointer"
                      onClick={() => {
                        setShowPass(!showPass);
                      }}
                    >
                      {showPass ? <FaEye /> : <FaEyeSlash />}
                    </div>
                                    </div>
                                    <button className='px-8 w-full py-2 bg-[#154425] shadow-lg hover:shadow-[#1544258b] text-white rounded-md'>Login</button>
                                </form>
                                <a href='/forgot-password/customer' className='text-blue-500 mt-3 block'>forgot password?</a>
                                <div className='flex justify-center items-center py-2'>
                                    <div className='h-[1px] bg-slate-300 w-[95%]'></div>
                                    <span className='px-3 text-slate-600'>or</span>
                                    <div className='h-[1px] bg-slate-300 w-[95%]'></div>
                                </div>
                            </div>
                            <div className='text-center text-slate-600 pt-1'>
                                <p>You have no account ? <Link className='text-blue-500' to='/register'>Register</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Login