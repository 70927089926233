import React, { useEffect, useState, Fragment } from "react";
import { Link, useSearchParams, useParams } from "react-router-dom";
import { Range } from "react-range";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import Products from "../components/products/Products";
import { AiFillStar } from "react-icons/ai";
import { CiStar } from "react-icons/ci";
import { BsFillGridFill } from "react-icons/bs";
import { FaThList } from "react-icons/fa";
import ShopProducts from "../components/products/ShopProducts";
import Pagination from "../components/Pagination";
import {
  price_range_product,
  query_products,
} from "../store/reducers/homeReducer";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../components/productCard";

import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import BottomNav from "../components/BottomNav";

import {
  add_to_card,
  messageClear,
  add_to_wishlist,
} from "../store/reducers/cardReducer";
import toast from 'react-hot-toast'
import SearchBox from "../components/SearchBox";
const CategoryShops = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const { cateId } = useParams();
  const {
    products,
    totalProduct,
    latest_product,
    priceRange,
    parPage,
    categorys,
    allColors,
    allBrands,
    allStorages
  } = useSelector((state) => state.home);

  const { errorMessage, successMessage } = useSelector((state) => state.card);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [styles, setStyles] = useState("grid");
  const [filter, setFilter] = useState(true);
  const [state, setState] = useState({
    values: [priceRange.low, priceRange.high],
  });
  const [rating, setRatingQ] = useState("");
  const [sortPrice, setSortPrice] = useState("");

  
  // const [allColors, setAllColors]= useState('')
  const [selectedColor, setSelectedColor]= useState('')

  
  const [selectedBrand, setSelectedBrand]= useState(undefined)
  
  const [selectedStorage, setSelectedStorage]= useState(undefined)

  const [category, setCategory] = useState("");
  useEffect(() => {
    dispatch(price_range_product());
  }, []);
  useEffect(() => {
    setState({
      values: [priceRange.low, priceRange.high],
    });
  }, [priceRange]);

  useEffect(() => {
    dispatch(
      query_products({
        low: state.values[0] || "",
        high: state.values[1] || "",
        category: cateId,
        rating,
        sortPrice,
        pageNumber,
        
    selectedColor,
    selectedBrand,
    selectedStorage,

      })
    );
  }, [state.values[0], state.values[1], rating, pageNumber, sortPrice,
  selectedColor,
  selectedBrand,
  selectedStorage,]);

  // const resetRating = () => {
  //   setRatingQ("");
  //   dispatch(
  //     query_products({
  //       low: state.values[0],
  //       high: state.values[1],
  //       category: cateId,
  //       rating: "",
  //       sortPrice,
  //       pageNumber,
  //     })
  //   );
  // };

  const queryCategoey = (e, value) => {
    if (e.target.checked) {
      setCategory(value);
    } else {
      setCategory("");
    }
  };
  const queryColor = (e, value) => {
    if (e.target.checked) {
      setSelectedColor(value);
    } else {
      setSelectedColor("");
    }
  };
  const queryBrand = (e, value) => {
    if (e.target.checked) {
      setSelectedBrand(value);
    } else {
      setSelectedBrand(undefined);
    }
  };
  const queryStorage = (e, value) => {
    if (e.target.checked) {
      setSelectedStorage(value);
    } else {
      setSelectedStorage(undefined);
    }
  };
  
  const filters = [
    {
      id: "category",
      name: "Category",
      options: [
        { value: "new-arrivals", label: "New Arrivals", checked: false },
        { value: "sale", label: "Sale", checked: false },
        { value: "travel", label: "Travel", checked: true },
        { value: "organization", label: "Organization", checked: false },
        { value: "accessories", label: "Accessories", checked: false },
      ],
    },
  ];
  
  
  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, successMessage]);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }



  const allBrand = [
    {
      id: "brand",
      name: "Brands",
      options: [
        { value: "new-arrivals", label: "New Arrivals", checked: false },
        { value: "sale", label: "Sale", checked: false },
        { value: "travel", label: "Travel", checked: true },
        { value: "organization", label: "Organization", checked: false },
        { value: "accessories", label: "Accessories", checked: false },
      ],
    },
  ];
  const allSizes = [
    {
      id: "sizes",
      name: products[0]?.parentCategory.name == "Smart phone & accessories"? "Storage": "Sizes",
      options: [
        { value: "new-arrivals", label: "New Arrivals", checked: false },
        { value: "sale", label: "Sale", checked: false },
        { value: "travel", label: "Travel", checked: true },
        { value: "organization", label: "Organization", checked: false },
        { value: "accessories", label: "Accessories", checked: false },
      ],
    },
  ];










  

  const myboi = [
    {
      id: "Colors",
      name: "Colors",
      options: [
        { value: "new-arrivals", label: "New Arrivals", checked: false },
        { value: "sale", label: "Sale", checked: false },
        { value: "travel", label: "Travel", checked: true },
        { value: "organization", label: "Organization", checked: false },
        { value: "accessories", label: "Accessories", checked: false },
      ],
    },
  ];

  
  // const getAllColors = () => {
  //   const colors = [];

  //   products?.map((product) => {
  //     product.colors.map((color) => colors.push(color));
  //   });

  //   const uniqueColors = [...new Set(colors)];

  //   setAllColors(uniqueColors);
  // };


  
  // useEffect(()=>{
  //   getAllColors()
  // },[])
  return (
    <div>
      <Headers />
      
      <SearchBox/>
      <section className="pb-10">
        <div className="bg-white">
          <div>
            {/* Mobile filter dialog */}
            <Transition.Root show={mobileFiltersOpen} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-40"
                onClose={setMobileFiltersOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                      <div className="flex items-center justify-between px-4">
                        <h2 className="text-lg font-medium text-gray-900">
                          Filters
                        </h2>
                        <button
                          type="button"
                          className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                          onClick={() => setMobileFiltersOpen(false)}
                        >
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>

                      {/* Filters */}
                      <form className="mt-4 border-t border-gray-200">
                        <h3 className="sr-only">Categories</h3>

                        {filters.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.id}
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                    <span className="font-medium text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <MinusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <PlusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-6">
                                    {categorys.map((c, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <input
                                          checked={
                                            category === c._id ? true : false
                                          }
                                          onChange={(e) =>
                                            queryCategoey(e, c._id)
                                          }
                                          type="checkbox"
                                          id={c.name}
                                          name={c.name}
                                          // defaultChecked={category.checked}
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={c.name}
                                          className="ml-3 min-w-0 flex-1 text-gray-500"
                                        >
                                          {c.name}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      </form>


                      <form className="mt-4 border-t border-gray-200 w-[85%] mx-auto">
                        <div className="py-2 flex flex-col gap-5">
                          <h2 className="text-xl font-bold mb-1 text-slate-600">
                            Price range
                          </h2>
                          <Range
                            step={5}
                            min={priceRange.low}
                            max={priceRange.high}
                            values={state.values}
                            onChange={(values) => setState({ values })}
                            renderTrack={({ props, children }) => (
                              <div
                                {...props}
                                className="w-full h-[6px] bg-slate-200 rounded-full cursor-default"
                              >
                                {children}
                              </div>
                            )}
                            renderThumb={({ props }) => (
                              <div
                                className="w-[15px] h-[15px] bg-green-500 rounded-full"
                                {...props}
                              />
                            )}
                          />
                          <div>
                            <span className="text-green-500 font-bold text-lg">
                              ৳{Math.floor(state.values[0])} - ৳
                              {Math.floor(state.values[1])}
                            </span>
                          </div>
                        </div>
                      </form>
                      <form className="mt-4 border-t border-gray-200">
                        <h3 className="sr-only">Colors</h3>

                        {myboi.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.id}
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                    <span className="font-medium text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <MinusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <PlusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  </Disclosure.Button>
                                </h3>

                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-6">
                                    {allColors?.map((c, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <input
                                          checked={
                                            selectedColor === c ? true : false
                                          }
                                          onChange={(e) =>
                                            queryColor(e, c)
                                          }
                                          type="checkbox"
                                          id={c}
                                          name={c}
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={c}
                                          className="ml-3 min-w-0 flex-1 text-gray-500"
                                        >
                                          {c}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      </form>




                      <form className="mt-4 border-t border-gray-200">
                        <h3 className="sr-only">Colors</h3>

                        {allBrand.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.id}
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                    <span className="font-medium text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <MinusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <PlusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  </Disclosure.Button>
                                </h3>

                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-6">
                                    {allBrands?.map((c, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <input
                                          checked={
                                            selectedBrand === c ? true : false
                                          }
                                          onChange={(e) =>
                                            queryBrand(e, c)
                                          }
                                          type="checkbox"
                                          id={c}
                                          name={c}
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={c}
                                          className="ml-3 min-w-0 flex-1 text-gray-500"
                                        >
                                          {c}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      </form>

                      <form className="mt-4 border-t border-gray-200">
                        <h3 className="sr-only">Colors</h3>

                        {allSizes.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.id}
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                    <span className="font-medium text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <MinusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <PlusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  </Disclosure.Button>
                                </h3>

                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-6">
                                    {allStorages?.map((c, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <input
                                          checked={
                                            selectedStorage === c ? true : false
                                          }
                                          onChange={(e) =>
                                            queryStorage(e, c)
                                          }
                                          type="checkbox"
                                          id={c}
                                          name={c}
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={c}
                                          className="ml-3 min-w-0 flex-1 text-gray-500"
                                        >
                                          {c}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      </form>



                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            <main className="mx-auto max-w-7xl px-4 sm:px-2 lg:px-8">
              <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-4">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                  Products
                </h1>

                <div className="flex items-center">
                <div>
                <select
                        onChange={(e) => setSortPrice(e.target.value)}
                        className="p-1 border outline-0 text-slate-600 font-semibold sm:max-w-[100px]"
                        name=""
                        id=""
                      >
                        <option value="">Sort By</option>
                        <option value="new" className="text-xs">New Arrival</option>
                        <option value="low-to-high" className="text-xs">Low to High Price</option>
                        <option value="high-to-low" className="text-xs">High to Low Price</option>
                      </select>
                </div>
              <button
                type="button"
                className="-m-2 ml-6 p-2 text-gray-400 hover:text-gray-500 sm:ml-2"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Filters</span>
                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
              </div>

              <section
                aria-labelledby="products-heading"
                className="pb-24 pt-6"
              >
                <h2 id="products-heading" className="sr-only">
                  Products
                </h2>

                {/* Product grid */}
                <div className="max-w-[1200px] w-[90%] sm:w-[95%] flex flex-wrap mx-auto sm:grid sm:grid-cols-2">
                  {products.map((product, index) => (
                    <ProductCard product={product} index={index} />
                  ))}
                </div>
                {/* </div> */}

                <div className="w-full mt-3 flex justify-center">
                  {totalProduct > parPage && (
                    <Pagination
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      totalItem={totalProduct}
                      parPage={parPage}
                      showItem={Math.floor(totalProduct / parPage)}
                    />
                  )}
                </div>
              </section>
            </main>
          </div>
        </div>
      </section>

      <BottomNav />
      <Footer />
    </div>
  );
};

export default CategoryShops;
