import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../api/api";
import addToken from "../../api/addToken";
import Loader from "../../utils/Loader";
import ProfileCard from "./ProfileCard";
import { useNavigate } from "react-router-dom";

const Team = () => {
  const [teamMembers, setTeamMembers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()

  const getTeamMember = async () => {
    setIsLoading(true);
    try {
      const res = await api.get("/reseller/get-reseller-team", addToken());
      setTeamMembers(res.data);
    } catch (err) {
      if(err.response.status === 401){
        toast.error("session expired! login again")
        localStorage.removeItem('Token')
        navigate('/login')
      }else{
        toast(err.message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTeamMember();
  }, []);
  console.log(teamMembers)
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : !teamMembers.length ? <div>you haven't referred anyone yet</div>:(
        <div className="h-full flex flex-col overflow-y-scroll max-h-[100%] scrollbar md:pr-0">
          <p className="text-3xl font-semibold">Your Sales Team members</p>
          <p className="text-xl font-semibold">Total - {!isLoading && teamMembers?.length}</p>
          <div className="flex flex-wrap">
            {isLoading ? (
              <Loader />
            ) : (
              teamMembers.map((member, index) => {
                return <ProfileCard key={index} info={member} />;
              })
            )}
          </div>
        </div>
      )
      }
    </>
  );
};

export default Team;
