import React, { useEffect, useLayoutEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar/";
import Header from "./header";
import { useSelector } from "react-redux";
import api from "../../api/api";
import addToken from "../../api/addToken";
import toast from 'react-hot-toast'
const ResellerDashboard = () => {
  const navigate = useNavigate()
  const { userInfo } = useSelector((state) => state.auth);

  const getUserInfo = async () => {
    try{
      const { data } = await api.get(`/reseller/getResellerInfo`, addToken());
    if (data.data[0].status == "payment-pending") {
      return navigate('pay')
    }
    if (data.data[0].status == "pending") {
      return navigate('pending')
    }
    if (data.data[0].status == "active") {
      return <Outlet />
    }
    }catch(err){
      console.log(err)
      if(err.response.status === 401){
        toast.error("session expired! login again")
        localStorage.removeItem('Token')
            navigate('/login')
      }
    }
  };
  useLayoutEffect(() => {
    getUserInfo();
  }, []);

  return (
    <section className="flex overflow-hidden">
      <Sidebar />
      <div className="flex flex-col w-full max-h-[100vh]  md:max-w-[90vw]">
        <Header />
        <div className="bg-[#e5fcf5] h-full max-h-[90%] w-full p-5 md:p-4 sm:p-1 pb-0">
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default ResellerDashboard;
