import React, { useEffect, useState } from "react";
import { FaUser, FaList } from "react-icons/fa";
import { AiFillHeart, AiFillShopping } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  get_card_products,
  get_wishlist_products,
} from "../store/reducers/cardReducer";

import { BiLogInCircle } from "react-icons/bi";
import api from "../api/api";
import { user_reset } from "../store/reducers/authReducer";
import { reset_count } from "../store/reducers/cardReducer";
import { RxDashboard } from "react-icons/rx";
import { MdSpaceDashboard } from "react-icons/md";
import { BsHeart } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { FaCartShopping } from "react-icons/fa6";
import SideBarCategory from "./SideBarCategory";
import { MdAccountCircle } from "react-icons/md";
import { BiSolidPurchaseTag } from "react-icons/bi";
const Headers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categorys } = useSelector((state) => state.home);

  const { userInfo } = useSelector((state) => state.auth);
  // console.log(userInfo);

  const { card_product_count, wishlist_count } = useSelector(
    (state) => state.card
  );

  const { pathname } = useLocation();
  const [showSidebar, setShowSidebar] = useState(true);
  const [categoryShow, setCategoryShow] = useState(true);
  const [category, setCategory] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [isMenuShowing, setIsMenuShowing] = useState(false);

  const redirect_card_page = () => {
    if (userInfo) {
      navigate(`/card`);
    } else {
      navigate(`/login`);
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(get_card_products(userInfo.id));
      dispatch(get_wishlist_products(userInfo.id));
    }
  }, [userInfo]);

  const logout = async () => {
    try {
      const { data } = await api.get("/customer/logout");
      localStorage.removeItem("Token");
      dispatch(user_reset());
      dispatch(reset_count());
      navigate("/login");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  // console.log(userInfo);
  return (
    <div className="w-full bg-white">
      <div className="w-white">
        <div className="max-w-[1200px] w-[80%] lg:w-[80%] mx-auto">
          <div className="flex justify-between items-center flex-wrap">
            <div className="md-lg:w-full w-3/12 md-lg:pt-2">
              <div className="flex justify-between items-center relative sm:py-3">
                <div className="hidden md-lg:block top-10 right-[100px] rounded-full">
                  <div className="w-full h-full flex gap-3 justify-center items-center">
                    {userInfo.role !== "reseller" && (
                      <div>
                        <div
                          onClick={() =>
                            navigate(userInfo ? "/card" : "/login")
                          }
                          className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-green-500"
                        >
                          <span className="text-xl text-white">
                            <AiFillShopping />
                          </span>
                          {card_product_count !== 0 && (
                            <div className="w-[20px] h-[20px] absolute bg-green-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
                              {card_product_count}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div>
                      <div
                        onClick={() =>
                          navigate(
                            userInfo ? "/dashboard/my-wishlist" : "/login"
                          )
                        }
                        className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-green-500"
                      >
                        <span className="text-xl text-white">
                          <AiFillHeart />
                        </span>
                        {wishlist_count !== 0 && (
                          <div className="w-[20px] h-[20px] absolute bg-green-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
                            {wishlist_count}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/" className="sm:absolute sm:left-[50%] sm:translate-x-[-50%]">
                  <img
                    src={"/main-logo-white.webp"}
                    className="h-[70px]"
                    alt="main logo"
                  />
                </a>
                <div
                  className="justify-center items-center w-[30px] h-[30px] bg-white text-slate-900 border border-slate-600 rounded-sm cursor-pointer lg:hidden md-lg:flex xl:hidden hidden"
                  onClick={() => setShowSidebar(false)}
                >
                  <span>
                    <FaList />
                  </span>
                </div>
              </div>
            </div>
            <div className="md-lg:w-full">
              <div className="flex justify-between md-lg:justify-center items-center flex-wrap pl-8">
                {/* <ul className="flex justify-start items-start gap-8 text-sm font-bold uppercase md-lg:hidden">
                  <li>
                    <Link to={'/'}
                      className={`p-2 block ${
                        pathname === "/" ? "text-[#154425]" : "text-slate-900"
                      }`}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/shops"
                      className={`p-2 block ${
                        pathname === "/shop"
                          ? "text-[#154425]"
                          : "text-slate-900"
                      }`}
                    >
                      Shop
                    </Link>
                  </li>
                </ul> */}
                <div className="flex md-lg:hidden justify-center items-center gap-5">
                  {userInfo ? (
                    <div className="flex justify-center gap-5">
                      <div
                        onClick={() =>
                          navigate(
                            userInfo ? "/dashboard/my-wishlist" : "/login"
                          )
                        }
                        className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#154425]"
                      >
                        <span className="text-xl text-white">
                          <AiFillHeart />
                        </span>
                        {wishlist_count !== 0 && (
                          <div className="w-[20px] h-[20px] absolute bg-green-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
                            {wishlist_count}
                          </div>
                        )}
                      </div>
                      {userInfo.role !== "reseller" && (
                        <div
                          onClick={redirect_card_page}
                          className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#154425]"
                        >
                          <span className="text-xl text-white">
                            <AiFillShopping />
                          </span>
                          {card_product_count !== 0 && (
                            <div className="w-[20px] h-[20px] absolute bg-green-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
                              {card_product_count}
                            </div>
                          )}
                        </div>
                      )}
                      {userInfo && (
                        <Link
                          className="flex cursor-pointer justify-center items-center gap-2 text-sm"
                          to="/dashboard/"
                        >
                          <div className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#154425]">
                            <span>
                              <FaUser className="text-white" />
                            </span>
                          </div>
                        </Link>
                      )}
                      {userInfo.role === "reseller" &&
                        userInfo.status === "active" && (
                          <Link
                            className="flex cursor-pointer justify-center items-center gap-2 text-sm"
                            to="/reseller/"
                            target="_blank"
                          >
                            <div className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#154425]">
                              <span>
                                <MdSpaceDashboard className="text-white" />
                              </span>
                            </div>
                          </Link>
                        )}
                    </div>
                  ) : (
                    <div className="flex flex-row gap-8 text-lg font-semibold text-gray-900">
                      <Link
                        to={"/register"}
                        className="after:content-[''] after:absolute relative after:h-[2px] after:left-[100%] after:w-full after:bg-slate-600 overflow-hidden hover:after:left-0 transition-all after:transition-all after:bottom-0"
                      >
                        join
                      </Link>
                      <Link
                        to={"/login"}
                        className="after:content-[''] after:absolute relative after:h-[2px] after:left-[100%] after:w-full after:bg-slate-600 overflow-hidden hover:after:left-0 transition-all after:transition-all after:bottom-0"
                      >
                        Login
                      </Link>
                      <Link
                        to={"/shops"}
                        className="after:content-[''] after:absolute relative after:h-[2px] after:left-[100%] after:w-full after:bg-slate-600 overflow-hidden hover:after:left-0 transition-all after:transition-all after:bottom-0"
                      >
                        All Items
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md-lg:block">
        <div
          onClick={() => setShowSidebar(true)}
          className={`fixed duration-200 transition-all ${
            showSidebar ? "invisible" : "visible"
          } hidden md-lg:block w-screen h-screen bg-[rgba(0,0,0,0.5)] top-0 left-0 z-20`}
        ></div>
        <div
          className={`w-[300px] z-[9999] transition-all duration-200 fixed  ${
            showSidebar ? "-left-[300px]" : "left-0"
          } top-0 overflow-y-auto bg-white h-screen py-6 px-2`}
        >
          <div className="flex justify-start flex-col gap-1">
            <a href="/">
              <img
                src="/main-logo-white.webp"
                className="w-[25vw] max-w-[60px] mx-auto"
                alt="logo"
              />
            </a>
            <div className="flex mb-2">
              <div
                onClick={() => setIsMenuShowing(true)}
                className={`w-[50%] text-center py-2 ${
                  isMenuShowing ? "bg-green-400" : "bg-green-50"
                }`}
              >
                menu
              </div>
              <div
                onClick={() => setIsMenuShowing(false)}
                className={`w-[50%] text-center py-2  ${
                  !isMenuShowing ? "bg-green-400" : "bg-green-50"
                }`}
              >
                Categories
              </div>
            </div>
            {isMenuShowing ? (
              <div className="flex justify-start flex-col gap-1 font-bold text-gray-900">
                <Link to={"/dashboard"} className="flex items-center text-xl">
                  <MdAccountCircle />
                  <div className="ml-3">Dashboard</div>
                </Link>
                <Link to={"/"} className="flex items-center text-xl">
                  <AiFillHome />
                  <div className="ml-3">Home</div>
                </Link>
                <Link to={"/shops"} className="flex items-center text-xl">
                  <FaCartShopping />
                  <div className="ml-3">Shop</div>
                </Link>
                {userInfo ? (
                  userInfo.role === "reseller" ? (
                    <>
                      <Link
                        to={"/reseller"}
                        className="flex items-center text-xl"
                      >
                        <MdSpaceDashboard />
                        <div className="ml-3">Reseller Panel</div>
                      </Link>
                      <Link
                        to={"/dashboard/my-orders"}
                        className="flex items-center text-xl"
                      >
                        <BiSolidPurchaseTag />
                        <div className="ml-3">Orders</div>
                      </Link>
                      <Link
                        to={"/dashboard/my-wishlist"}
                        className="flex items-center text-xl"
                      >
                        <FaHeart />
                        <div className="ml-3">Wishlists</div>
                      </Link>
                      <Link
                        to={"/dashboard/my-wishlist"}
                        className="flex items-center text-xl"
                      >
                        <FaLock />
                        <div className="ml-3">Change Password</div>
                      </Link>
                      <div
                        onClick={logout}
                        className="flex items-center text-xl"
                      >
                        <BiLogInCircle />
                        <div className="ml-3">Logout</div>
                      </div>
                    </>
                  ) : (
                    <>
                    <Link
                      to={"/dashboard/my-orders"}
                      className="flex items-center text-xl"
                    >
                      <BiSolidPurchaseTag />
                      <div className="ml-3">Orders</div>
                    </Link>
                    <Link
                      to={"/dashboard/my-wishlist"}
                      className="flex items-center text-xl"
                    >
                      <FaHeart />
                      <div className="ml-3">Wishlists</div>
                    </Link>
                    <Link
                      to={"/dashboard/my-wishlist"}
                      className="flex items-center text-xl"
                    >
                      <FaLock />
                      <div className="ml-3">Change Password</div>
                    </Link>
                      <div
                        onClick={logout}
                        className="flex items-center text-xl"
                      >
                        <BiLogInCircle />
                        <div className="ml-3">Logout</div>
                      </div>
                    </>
                  )
                ) : (<>
                  <Link to={'/register'} className="flex items-center text-xl">
                    <BiLogInCircle />
                    <div className="ml-3">Register</div>
                  </Link>
                  <Link to={'/reseller/register'} className="flex items-center text-xl">
                    <BiLogInCircle />
                    <div className="ml-3">Register as Reseller</div>
                  </Link>
                  <a href={'https://portal.aladdinshopee.com/register'} className="flex items-center text-xl">
                    <BiLogInCircle />
                    <div className="ml-3">Register as Seller</div>
                  </a>
                </>
                )}
              </div>
            ) : (
              <div className="flex justify-start flex-col gap-1">
                {categorys?.map((category, i) => {
                  return <SideBarCategory category={category} key={i} />;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-[80%] lg:w-[90%] mx-auto">
        <div className="flex w-full flex-wrap md-lg:gap-8">
          {/* <div className="w-3/12 md-lg:w-full">
            <div className="bg-white relative">
              <div
                className={`${
                  categoryShow ? "h-0" : "h-[400px]"
                } overflow-hidden transition-all md-lg:relative duration-500 absolute z-[99999] bg-white w-full border-x`}
              >
                <ul className="py-2 text-slate-900 font-medium h-full overflow-auto">
                  {categorys.map((c, i) => {
                    return (
                      <li
                        key={i}
                        className="flex justify-start items-center gap-2 px-[24px] py-[6px]"
                      >
                        <img
                          src={c.image}
                          className="w-[30px] h-[30px] rounded-full overflow-hidden"
                          alt={c.name}
                        />
                        <Link
                          to={`/products?category=${c.name}`}
                          className="text-sm block"
                        >
                          {c.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div> */}

          {/* <div class="max-w-lg mx-auto">
    <div class="flex">
        <label for="search-dropdown" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Your Email</label>
        <button id="dropdown-button" data-dropdown-toggle="dropdown" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button">All categories <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
  </svg></button>
        <div id="dropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
        <select
                      onChange={(e) => setCategory(e.target.value)}
                      className="w-[150px] text-slate-900 font-semibold bg-transparent px-2 h-full outline-0 border-none"
                      name=""
                      id=""
                    >
                      <option value="">Select category</option>
                      {categorys.map((c, i) => (
                        <option key={i} value={c.name}>
                          {c.name}
                        </option>
                      ))}
                    </select>
            
        </div>
        <div class="relative w-full">
            <input type="search" id="search-dropdown" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="Search Mockups, Logos, Design Templates..." onChange={(e) => setSearchValue(e.target.value)} required />
            <button type="submit" class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" 
                    onClick={search}>
                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
                <span class="sr-only" >Search</span>
            </button >
        </div>
    </div>
</div> */}
        </div>
      </div>
    </div>
  );
};

export default Headers;
