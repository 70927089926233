import React, { useEffect, useState } from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import {
  useLocation,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { place_order } from "../store/reducers/orderReducer";
import toast from "react-hot-toast";
import Loader from "../utils/Loader";
import Upazila from "./upazila";
import {
  getAllDivision,
  getAllDistrict,
  getAllUpazila,
  getAllUnion,
} from "bd-divisions-to-unions";

import data from "../utils/data.json";
import { useLayoutEffect } from "react";
const address = require("@bangladeshi/bangladesh-address");

const Shipping = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [canBuy, setCanBuy] = useState(true);
  const [thana, setThana] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const {
    state: { products, price, items, resellerPrice, size, color },
  } = useLocation();
  const districts = address.allDistict();
  const gg = getAllUpazila("en");
  const [selectedDistrict, setSelectedDistrict] = useState("");

  const [res, setRes] = useState(false);
  // const [state, setState] = useState({
  //   name: "",
  //   address: "",
  //   phone: "",
  //   post: "",
  //   province: "",
  //   city: "",
  //   area: "",
  //   BusinessName: "",
  // });
  useLayoutEffect(() => {
    !userInfo && navigate("/login");
  }, []);

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    fullAddress: "",
    phone: "",
    post: "",
    district: "",
    upazila: "",
  });
  const [shippingFee, setShippingFee] = useState(60);
  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const save = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const { name, address, phone, post, province, city, area } = state;
    if (name && address && phone && post && province && city && area) {
      setRes(true);
    }
    setIsLoading(false);
  };
  const placeOrder = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (state.phone.length < 11 || state.phone.length > 11) {
      toast.error("Phone number cannot be more or less than 11 digits");
      return;
    } else {
      dispatch(
        place_order({
          price,
          products,
          shipping_fee: parseInt(shippingFee),
          shippingInfo: {
            state: state,
            totalAddress:
              state.fullAddress + ", " + thana + ", " + state.district,
          },
          userId: userInfo.id,
          navigate,
          resellerPrice,
          items,
          role: userInfo.role,
        })
      );
    }
    setIsLoading(false);
  };
  // console.log(state)
  useEffect(() => {
    // if(price+resellerPrice)
  }, []);
  const getData = (thanaName) => {
    setState({ ...state, upazila: thanaName });
    // console.log(thanaName)
    setThana(thanaName);
    for (let i = 0; i < data.length; i++) {
      const districts = data[i].district.thana;
      for (let j = 0; j < districts.length; j++) {
        const thana = districts[j];
        if (thana.thanaName.toLowerCase() === thanaName.toLowerCase()) {
          // console.log(thana)
          setShippingFee(thana.deliveryCharge);
        }
      }
    }
    return null;
  };

  return (
    <div>
      <Headers />

      <div class="bg-gray-100">
        <div class="w-full max-w-3xl mx-auto px-8 pt-8">
          <div class="w-full max-w-7xl md:px-5 lg-6 mx-auto">
            <div class="flex flex-col gap-4">
              <div class="w-full flex items-start flex-col gap-8 max-xl:mx-auto">
                <div class="p-6 border bg-white rounded-3xl w-full group transition-all duration-500 border-gray-400 ">
                  <h2 class="font-manrope font-bold text-3xl leading-10 text-black pb-6 border-b border-gray-200 ">
                    Order Summary
                  </h2>
                  <div class="data py-6 border-b border-gray-200">
                    <div class="flex items-center justify-between gap-4 mb-5">
                      <p class="font-normal text-lg leading-8 text-gray-400 transition-all duration-500 group-hover:text-gray-700">
                        Product Cost
                      </p>
                      <p class="font-medium text-lg leading-8 text-gray-900">
                        ৳{price}
                      </p>
                    </div>
                    <div class="flex items-center justify-between gap-4 mb-5">
                      <p class="font-normal text-lg leading-8 text-gray-400 transition-all duration-500 group-hover:text-gray-700">
                        Shipping
                      </p>
                      <p class="font-medium text-lg leading-8 text-gray-600">
                        ৳{shippingFee}
                      </p>
                    </div>
                  </div>
                  <div class="total flex items-center justify-between pt-6">
                    <p class="font-normal text-xl leading-8 text-black ">
                      Subtotal
                    </p>
                    <h5 class="font-manrope font-bold text-2xl leading-9 text-green-600">
                      ৳{price + shippingFee}
                    </h5>
                  </div>
                </div>
              </div>
              {products.map((p, i) => (
                <>
                  {p.products.map((pt, j) => (
                    <div class="w-full mx-auto">
                      <div class="flex w-full">
                        <div class="sm:flex-col w-full rounded-3xl p-6 bg-white flex md:items-center gap-5 border transition-all duration-500 border-gray-400">
                          <div class="img-box ">
                            <img
                              src={"/products/" + pt.productInfo.images[0]}
                              alt={pt.productInfo.name}
                              class="w-full max-w-[60px] object-cover"
                            />
                          </div>
                          <div class="flex justify-between items-center w-full gap-1">
                            <div class="">
                              <h2 class="text-lg font-bold text-black">
                                {pt.productInfo.name}
                              </h2>
                              {pt?.size !== false && <p>Size: {pt.size}</p>}
                              {pt?.color !== false && <p>Color: {pt.color}</p>}
                            </div>
                            {userInfo.role === "reseller" ? (
                              <div>
                                <h6 class="font-medium text-base text-green-600">
                                  ৳
                                   {pt.size !== false ? parseInt(
                                    pt.productInfo.sizes.find(
                                      (size) => size.size === pt.size
                                    )?.price
                                  ) +
                                    pt.productInfo.adminProfit +
                                    parseInt(resellerPrice) -
                                    pt.productInfo?.discount:
                                    pt.productInfo.totalPrice -
                                      pt.productInfo?.discount
                                    }
                                </h6>
                              </div>
                            ) : (
                              <div>
                                <h6 class="font-medium text-base text-green-600">
                                  ৳
                                  {pt.size !== "false"
                                    ? parseInt(
                                        pt.productInfo.sizes.find(
                                          (size) => size.size === pt.size
                                        )?.price
                                      ) -
                                      parseInt(pt.productInfo?.discount) +
                                      parseInt(pt.productInfo.AdminPrice)
                                    : pt.productInfo.totalPrice -pt.productInfo?.discount}
                                </h6>
                                {pt.productInfo.discount > 0 && (
                                  <>
                                    <p className="line-through">
                                      ৳
                                      {pt.size !== "false"
                                        ? parseInt(
                                            pt.productInfo.sizes.find(
                                              (size) => size.size === pt.size
                                            )?.price
                                          ) + pt.productInfo.AdminPrice
                                        : pt.productInfo.totalPrice}
                                    </p>
                                    <p>
                                      -
                                      {Math.floor(
                                        100 -
                                          ((pt.productInfo.totalPrice -
                                            pt.productInfo.discount) /
                                            pt.productInfo.totalPrice) *
                                            100
                                      )}
                                      %
                                    </p>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-100">
        <div class="w-full max-w-3xl mx-auto p-8">
          <form
            class="bg-white p-8 rounded-3xl shadow-md border dark:border-gray-700"
            onSubmit={placeOrder}
          >
            <h1 class="text-2xl font-bold text-gray-800  mb-4">Checkout</h1>

            {/* <!-- Shipping Address --> */}
            <div class="mb-6">
              <h2 class="text-xl font-semibold text-gray-700  mb-2">
                Shipping Address
              </h2>
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <label for="first_name" class="block text-gray-700  mb-1">
                    First Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    value={state.firstName}
                    onChange={inputHandle}
                    name="firstName"
                    class="w-full rounded-lg border py-2 px-3 dark:bg-gray-200  dark:border-none"
                    required
                  />
                </div>
                <div>
                  <label for="last_name" class="block text-gray-700  mb-1">
                    Last Name <span className="text-red-600">*</span>
                  </label>

                  <input
                    type="text"
                    value={state.lastName}
                    name="lastName"
                    onChange={inputHandle}
                    id="last_name"
                    class="w-full rounded-lg border py-2 px-3 dark:bg-gray-200  dark:border-none"
                    required
                  />
                </div>
              </div>

              <div class="mt-4">
                <label for="address" class="block text-gray-700  mb-1">
                  Street Address <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  value={state.fullAddress}
                  name="fullAddress"
                  onChange={inputHandle}
                  id="address"
                  class="w-full rounded-lg border py-2 px-3 dark:bg-gray-200  dark:border-none"
                  required
                />
              </div>

              <div class="mt-4">
                <label for="address" class="block text-gray-700  mb-1">
                  Phone Number <span className="text-red-600">*</span>
                </label>
                <input
                  type="phone"
                  value={state.phone}
                  name="phone"
                  onChange={inputHandle}
                  id="phone"
                  class="w-full rounded-lg border py-2 px-3 dark:bg-gray-200  dark:border-none"
                  required
                />
              </div>

              <div class="mt-4">
                <label for="city" class="block text-gray-700  mb-1">
                  State <span className="text-red-600">*</span>
                </label>
                <select
                  value={state.district}
                  name="district"
                  onChange={inputHandle}
                  id="city"
                  class="w-full rounded-lg border py-2 px-3 dark:bg-gray-200  dark:border-none"
                  required
                >
                  <option value="">--select State--</option>

                  {data.map((district, index) => {
                    return (
                      <option value={district.district.name} key={index}>
                        {district.district.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* {selectedDistrict !== false && (
                <Upazila district={selectedDistrict} />
              )} */}
              {state.district !== "" && (
                <Upazila
                  district={state.district}
                  data={data}
                  getData={getData}
                  state={state}
                />
              )}

              {userInfo.role == "reseller" && (
                <div class="grid grid-cols-2 gap-4 mt-4">
                  <div>
                    <label for="zip" class="block text-gray-700  mb-1">
                      Business Name
                    </label>
                    <input
                      type="text"
                      id="businessName"
                      value={state.businessName}
                      name="businessName"
                      onChange={inputHandle}
                      class="w-full rounded-lg border py-2 px-3 dark:bg-gray-200  dark:border-none"
                      required
                    />
                  </div>
                </div>
              )}
            </div>

            <div class="mt-8 flex justify-end">
              <button class="bg-teal-500 text-white px-4 py-2 rounded-lg hover:bg-teal-700 dark:bg-teal-600  dark:hover:bg-teal-900">
                Place Order
              </button>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Shipping;
