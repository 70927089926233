import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Button from "./Button";
const Banner = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="w-full md-lg:mt-6 sm:mt-2 flex items-center justify-center">
      <div className="max-w-[1200px] w-[90%] min-w-[300px] sm:w-[95%] py-40 sm:h-[240px] sm:py-0 bg-[url(https://aladdinshopee.com/images/home-banner.webp)] bg-cover bg-[#154425] bg-blend-multiply rounded-3xl sm:flex items-center">
        <div className="flex items-center justify-center">
          <div>
            <h1 className="text-5xl sm:text-3xl sm:px-4 font-extrabold text-white mb-10 sm:mb-3 text-center">
              Your One Stop Market!
            </h1>
            <div className="flex justify-center">
              <Button text={"Shop Now"} link={"/shops"} bgColor="#1aed60" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
