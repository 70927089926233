import React from 'react'

import Chart from "react-apexcharts";


const SalesReportChart = ({chart}) => {





  function getDatesFromStartOfMonth() {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1); // Start of current month
    const currentDay = today.getDate(); // Current day of the month
  
    const dates = [];
    for (let day = 1; day <= currentDay; day++) {
      const dateObj = new Date(startDate.getFullYear(), startDate.getMonth(), day);
      const date = dateObj.getDate();
      const month = dateObj.toLocaleString('en-US', { month: 'long' }); // Get month name in English
      dates.push(`${date} ${month}`);
    }
  
    return dates;
  }
  
  const dates = getDatesFromStartOfMonth();


  var options = {
    chart: {
      height: 280,
      type: "area"
    },
    dataLabels: {
      enabled: true
    },
    series: [
      {
        name: "Series 1",
        data: [45, 52, 38, 45, 19, 23, 2]
      }
    ],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    xaxis: {
      categories: getDatesFromStartOfMonth()
    }
  };

  const data = {
    series: [
      {
        name: "series-1",
        data: chart
      }
      
    ]
  };

  
  return (
    <div className='bg-white h-[60vh] md:h-[300px] p-5 md:px-1 md:py-5 rounded-3xl shadow-lg md:w-[95%]'>
       <Chart
              options={options}
              series={data.series}
              type="area"
              width="100%"
              height="100%"
            />
    </div>
  )
}

export default SalesReportChart