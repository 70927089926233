import { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import Shops from './pages/Shops';
import Card from './pages/Card';
import Details from './pages/Details';
import Register from './pages/Register';
import Login from './pages/Login';
import ResellerLogin from './pages/resellerLogin';
import ResellerRegister from './pages/resellerRegister ';
import Shipping from './pages/Shipping';
import { useDispatch } from 'react-redux';
import { get_category } from './store/reducers/homeReducer'
import CategoryShops from './pages/CategoryShop';
import SearchProducts from './pages/SearchProducts';
import Payment from './pages/Payment';
import Dashboard from './pages/Dashboard';
import ProtectUser from './utils/ProtectUser';
import ProtectReseller from './utils/protectReseller';
import Index from './components/dashboard/Index';
import Orders from './components/dashboard/Orders';
import Wishlist from './components/dashboard/Wishlist';
import ChangePassword from './components/dashboard/ChangePassword';
import Order from './components/dashboard/Order';
import Chat from './components/dashboard/Chat';
import ConfirmOrder from './pages/ConfirmOrder';
import ResellerDashboard from './pages/reseller';
import ResellerHome from './pages/reseller/Home';
import Transactions from './pages/reseller/Transactions';
import ResellerOrders from './pages/reseller/Orders';
import Profile from './pages/reseller/Profile';
import Withdraw from './pages/reseller/Withdraw';
import ResellerPayment from './pages/reseller/resellerPayment';
import ResellerPending from './pages/reseller/resellerPending';
import PaymentSuccess from './pages/reseller/paymentSuccess';
import ProfileEdit from './pages/reseller/profileEdit';
import ViewProfile from './pages/reseller/viewProfile';
import Team from './pages/reseller/Team';
import OrderPaymentFailed from './pages/OrderPaymentFailed';
import OrderPaymentSuccess from './pages/OrderPaymentSuccess';
import ReferTree from './pages/reseller/referTree';
import SubCategory from './pages/subCategory';
import VerifyOtp from './pages/VerifyOtp';
import ForgotPass from './pages/forgotPass';
import ResetPass from './pages/resetPass';
import VerifyEmail from './pages/reseller/VerifyEmail';
import ScrollToTop from './utils/ScrollToTop';
import Terms from './pages/Terms';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import PrivecyPolicy from './pages/PrivecyPolicy';
import RefundPolicy from './pages/RefundPolicy';
import ReplacePolicy from './pages/ReplacePolicy';
function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(get_category())
  }, [])
  return (
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
        
        <Route path='/register' element={<Register />} />
        <Route path='/reseller/register' element={<ResellerRegister />} />
        <Route path='/reseller/verify-email' element={<VerifyEmail />} />
        <Route path='/login' element={<Login />} />
        <Route path='/reseller/login' element={<ResellerLogin />} />
        <Route path='/' element={<Home />} />
        <Route path='/shops' element={<Shops />} />
        <Route path='/products/:cateId' element={<CategoryShops />} />
        <Route path='/sub-categories/:cateId' element={<SubCategory />} />
        <Route path='/search/products?' element={<SearchProducts />} />
        <Route path='/card' element={<Card />} />
        <Route path='/order/confirm?' element={<ConfirmOrder />} />
        <Route path='/shipping' element={<Shipping />} />
        <Route path='/payment' element={<Payment />} />
        <Route path='/product/details/:slug' element={<Details />} />
        <Route path='/signup/success' element={<PaymentSuccess />} />
        <Route path='/failed' element={<OrderPaymentFailed />} />
        <Route path='/payment/successful' element={<OrderPaymentSuccess />} />
        <Route path='/forgot-password/:role' element={<ForgotPass />} />
        <Route path='/verify-otp/:role/:email' element={<VerifyOtp />} />
        <Route path='/reset-pass/:role/:email/:otp' element={<ResetPass />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/privacy' element={<PrivecyPolicy />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/replace-policy' element={<ReplacePolicy />} />

        <Route path='/dashboard' element={<ProtectUser />}>
          <Route path='' element={<Dashboard />}>
            <Route path='' element={<Index />} />
            <Route path='my-orders' element={<Orders />} />
            <Route path='my-wishlist' element={<Wishlist />} />
            <Route path='order/details/:orderId' element={<Order />} />
            <Route path='change-password' element={<ChangePassword />} />
            <Route path='chat' element={<Chat />} />
            <Route path='chat/:sellerId' element={<Chat />} />
          </Route>
        </Route>

        <Route path='/reseller/' element={<ProtectReseller />}>
          <Route path='' element={<ResellerDashboard />}>
            <Route path='' element={<ResellerHome />} />
            <Route path='transactions' element={<Transactions />} />
            <Route path='orders' element={<ResellerOrders />} />
            <Route path='profile' element={<Profile />} />
            <Route path='withdraw' element={<Withdraw />} />
            <Route path='profile-edit' element={<ProfileEdit />} />
            <Route path='team-members' element={<Team />} />
            <Route path='refer-tree' element={<ReferTree />} />
            <Route path='/reseller/viewProfile/:id' element={<ViewProfile/>} />
            <Route path='/reseller/withdraw-request/:id' element={<Withdraw/>} />
            <Route path='*' element={<div>not found</div>} />
          </Route>
          <Route path='pay' element={<ResellerPayment />} />
            <Route path='pending' element={<ResellerPending />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
