import React,{useState} from 'react'
const address = require("@bangladeshi/bangladesh-address");

const Upazila = ({district, data, getData}) => {
    const [selectedUpazila, setSelectedUpazila] = useState('')


    function getThanaByDistrict(districtName,data) {
      for (let i = 0; i < data.length; i++) {
          const district = data[i].district;
          if (district.name.toLowerCase() === districtName.toLowerCase()) {
              return district.thana;
          }
      }
      return null;
  }
  
  
  const thanaArray = getThanaByDistrict(district, data).sort(function (a, b) {
    if (a.thanaName < b.thanaName) {
      return -1;
    }
    if (a.thanaName > b.thanaName) {
      return 1;
    }
    return 0;
  })
  
    
  return (
    <div class="mt-4">
                <label for="city" class="block text-gray-700  mb-1">
                  Upazila <span className="text-red-600">*</span>
                </label>
                <select
                  value={selectedUpazila}
                  onChange={(e)=>{ 
                    setSelectedUpazila(e.target.value) 
                    getData(e.target.value)}}
                  id="city"
                  class="w-full rounded-lg border py-2 px-3 dark:bg-gray-200  dark:border-none"
                  required
                >
                  <option value="">--select Upazila--</option>
                  {thanaArray.map((thana, index) => {
                    return (
                      <option value={thana.thanaName} key={index}>
                        {thana.thanaName}
                      </option>
                    );
                  })}
                </select>
              </div>
  )
}

export default Upazila