import React from "react";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import { FaLock } from "react-icons/fa6";

const ResellerPayment = () => {
  const navigate = useNavigate();
  const handlePayment = async () => {
    const { data } = await api.post("/payment/bkash/payment/create");
    window.location.href = data.bkashURL;
  };
  return (
    <div className="w-full h-[100vh] flex justify-center items-center">
      <div>
      <p>Reseller Sign-up fee: <b>1200 BDT</b></p>
      <button
        onClick={handlePayment}
        className="bg-pink-600 px-28 py-3 rounded text-white font-bold flex items-center"
      >
        <FaLock  className="mr-4"/>
        <span>Join As Reseller</span>
      </button>
      </div>

    </div>
  );
};

export default ResellerPayment;
