import React from "react";
import Box from "../../../components/box";
import { FaSackDollar } from "react-icons/fa6";
import { FaBagShopping } from "react-icons/fa6";
import { FaDatabase } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";
const StateBox = ({totalBalance, remainingBalance, totalTransactions, totalWithdrew, totalOrders}) => {
  return (
    <div className="shadow-xl w-full bg-white h-full rounded-3xl py-5 md:mt-3">
      <Box>
        <div className="h-12 aspect-square bg-yellow-400 flex items-center justify-center rounded-2xl shadow-md mr-4">
          <FaSackDollar className="text-xl text-white" />
        </div>
        <div className="flex w-full justify-between">
          <div>
            <p className="text-xs text-gray-400">Total Earnings</p>
            <p className="text-xl">
              <b>{totalBalance}৳</b>
            </p>
          </div>
          {/* <div className="flex items-center text-xs text-gray-600">
            <FaLongArrowAltUp className="text-[#3eb648]" />
            <span>+12.233%</span>
          </div> */}
        </div>
      </Box>
      <Box>
        <div className="h-12 aspect-square bg-purple-900 flex items-center justify-center rounded-2xl shadow-md mr-4">
          <FaBagShopping className="text-xl text-white" />
        </div>
        <div className="flex w-full justify-between">
          <div>
            <p className="text-xs  text-gray-400">Total Orders</p>
            <p className="text-xl">
              <b>{totalOrders}</b>
            </p>
          </div>
          {/* <div className="flex items-center text-xs text-gray-600">
            <FaLongArrowAltUp className="text-[#3eb648]" />
            <span>+12.233%</span>
          </div> */}
        </div>
      </Box>
      <Box>
        <div className="h-12 aspect-square bg-orange-500 flex items-center justify-center rounded-2xl shadow-md mr-4">
          <FaDatabase className="text-xl text-white" />
        </div>
        <div className="flex w-full justify-between">
          <div>
            <p className="text-xs text-gray-400">
              Total Transactions
            </p>
            <p className="text-xl">
              <b>{totalTransactions}</b>
            </p>
          </div>
          {/* <div className="flex items-center text-xs text-gray-600">
            <FaLongArrowAltDown className="text-[#bd3535]" />
            <span>+12.233%</span>
          </div> */}
        </div>
      </Box>
      <Box>
        <div className="h-12 aspect-square bg-[#154425] flex items-center justify-center rounded-2xl shadow-md mr-4">
          <FaWallet className="text-xl text-white" />
        </div>
        <div className="flex w-full justify-between">
          <div>
            <p className="text-xs text-gray-400">
              Total Balance
            </p>
            <p className="text-xl">
              <b>{remainingBalance}৳</b>
            </p>
          </div>
          {/* <div className="flex items-center text-xs text-gray-600">
            <FaLongArrowAltDown className="text-[#bd3535]" />
            <span>+12.233%</span>
          </div> */}
        </div>
      </Box>
    </div>
  );
};

export default StateBox;
