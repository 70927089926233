import React, { Fragment, useEffect, useState } from "react";
import { Range } from "react-range";
import Headers from "../components/Headers";
import Footer from "../components/Footer";

import Pagination from "../components/Pagination";
import {
  price_range_product,
  query_products,
} from "../store/reducers/homeReducer";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../components/productCard";
import BottomNav from "../components/BottomNav";

import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";

import {
  add_to_card,
  messageClear,
  add_to_wishlist,
} from "../store/reducers/cardReducer";
import toast from "react-hot-toast";
import SearchBox from "../components/SearchBox";
const Shops = () => {
  const { errorMessage, successMessage } = useSelector((state) => state.card);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const {
    products,
    totalProduct,
    latest_product,
    categorys,
    priceRange,
    parPage,
    allColors,
    allBrands,
    allStorages
  } = useSelector((state) => state.home);

  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);

  const [filter, setFilter] = useState(true);
  const [category, setCategory] = useState("");
  const [state, setState] = useState({
    values: [priceRange.low, priceRange.high],
  });
  const [rating, setRatingQ] = useState("");
  const [sortPrice, setSortPrice] = useState("");

  // const [allColors, setAllColors]= useState('')
  const [selectedColor, setSelectedColor]= useState(undefined)

  // const [allBrands, setAllBrands]= useState('')
  const [selectedBrand, setSelectedBrand]= useState(undefined)

  // const [allStorages, setAllStorages]= useState('')
  const [selectedStorage, setSelectedStorage]= useState(undefined)

  useEffect(() => {
    setState({
      values: [priceRange.low, priceRange.high],
    });
  }, [priceRange]);

  const queryCategoey = (e, value) => {
    if (e.target.checked) {
      setCategory(value);
    } else {
      setCategory("");
    }
  };


  const queryColor = (e, value) => {
    if (e.target.checked) {
      setSelectedColor(value);
    } else {
      setSelectedColor(undefined);
    }
  };
  const queryBrand = (e, value) => {
    if (e.target.checked) {
      setSelectedBrand(value);
    } else {
      setSelectedBrand(undefined);
    }
  };
  const queryStorage = (e, value) => {
    if (e.target.checked) {
      setSelectedStorage(value);
    } else {
      setSelectedStorage(undefined);
    }
  };
  useEffect(() => {
    dispatch(
      query_products({
        low: state.values[0],
        high: state.values[1],
        category,
        rating,
        sortPrice,
        pageNumber,
        selectedColor,
        selectedBrand,
        selectedStorage
      })
    );
  }, [
    state.values[0],
    state.values[1],
    category,
    rating,
    pageNumber,
    sortPrice,
    selectedColor,
    selectedBrand,
    selectedStorage
  ]);

  
  
  



  const sortOptions = [
    { name: "Price: Low to High", href: "#", current: false },
    { name: "Price: High to Low", href: "#", current: false },
  ];

  const filters = [
    {
      id: "category",
      name: "Category",
      options: [
        { value: "new-arrivals", label: "New Arrivals", checked: false },
        { value: "sale", label: "Sale", checked: false },
        { value: "travel", label: "Travel", checked: true },
        { value: "organization", label: "Organization", checked: false },
        { value: "accessories", label: "Accessories", checked: false },
      ],
    },
  ];

  const myboi = [
    {
      id: "Colors",
      name: "Colors",
      options: [
        { value: "new-arrivals", label: "New Arrivals", checked: false },
        { value: "sale", label: "Sale", checked: false },
        { value: "travel", label: "Travel", checked: true },
        { value: "organization", label: "Organization", checked: false },
        { value: "accessories", label: "Accessories", checked: false },
      ],
    },
  ];
  const allBrand = [
    {
      id: "brand",
      name: "Brands",
      options: [
        { value: "new-arrivals", label: "New Arrivals", checked: false },
        { value: "sale", label: "Sale", checked: false },
        { value: "travel", label: "Travel", checked: true },
        { value: "organization", label: "Organization", checked: false },
        { value: "accessories", label: "Accessories", checked: false },
      ],
    },
  ];
  const allSizes = [
    {
      id: "sizes",
      name: "Sizes",
      options: [
        { value: "new-arrivals", label: "New Arrivals", checked: false },
        { value: "sale", label: "Sale", checked: false },
        { value: "travel", label: "Travel", checked: true },
        { value: "organization", label: "Organization", checked: false },
        { value: "accessories", label: "Accessories", checked: false },
      ],
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }


//   const getAllColors = (pp) => {
//     const colors = [];
// pp?.map((product) => {
//       product.colors.map((color) => colors.push(color));
//     });

//     const uniqueColors = [...new Set(colors)];

//     setAllColors(uniqueColors);
//   };

//   const getAllBrands = (pp) => {
//     const brands = [];

//     pp?.map((product) => brands.push(product.brand));

//     const uniqueColors = [...new Set(brands)];

//     setAllBrands(uniqueColors);
//   };

//   const getAllStorages = (pp) => {
//     const storages = [];

//     pp?.map((product) =>{
//       product.sizes.map(size=>{
//         storages.push(size.size)
//       })
//     });

//     const uniqueColors = [...new Set(storages)];

//     setAllStorages(uniqueColors);
//   };


  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, successMessage]);


  useEffect(() => {
    
    // getAllColors(products)
    // getAllBrands(products)
    // getAllStorages(products)
    dispatch(price_range_product());
  }, []);

  // console.log(allStorages)
  return (
    <div>
      <Headers />
      
      <SearchBox/>
      <section className="pb-10">
        {/* <div className="w-[80%] md:w-[80%%] sm:w-[95%] lg:w-[90%] h-full mx-auto"> */}
      {/* <section className=' overflow-hidden rounded-xl bg-[url("http://localhost:3000/images/banner/shop.gif")] bg-[#242222bd] bg-blend-multiply  h-[15vw] max-h-[200px] mt-6 bg-cover bg-no-repeat relative bg-left my-7'>
            <div className="backdrop-blur-sm flex flex-col justify-center gap-1 items-center h-full w-full text-white">
              <h2 className="text-3xl font-bold">AladdinShopee</h2>
              <div className="flex justify-center items-center gap-2 text-2xl w-full">
                <Link to="/">Home</Link>
                <span className="pt-1">
                  <MdOutlineKeyboardArrowRight />
                </span>
                <span>Products</span>
              </div>
            </div>
          </section> */}
        {/* <div className="mt-10">
            <div className={`md:block hidden ${!filter ? "mb-6" : "mb-0"}`}>
              <button
                onClick={() => setFilter(!filter)}
                className="text-center w-full py-2 px-3 bg-indigo-500 text-white"
              >
                Filter Product
              </button>
            </div>
            <div className="w-full flex flex-wrap">
              <div
                className={`w-3/12 md-lg:w-4/12 md:w-full pr-8 ${
                  filter
                    ? "md:h-0 md:overflow-hidden md:mb-6"
                    : "md:h-auto md:overflow-auto md:mb-0"
                }`}
              >
                <h2 className="text-3xl font-bold mb-3 text-slate-600">
                  Category
                </h2>
                <div className="py-2">
                  {categorys.map((c, i) => (
                    <div
                      className="flex  flex-col gap-2 py-1 "
                      key={i}
                    >
                      <div
                        className="flex justify-start items-center gap-2 py-1 "
                        key={i}
                      >
                        <input
                          checked={category === c._id ? true : false}
                          onChange={(e) => queryCategoey(e, c._id)}
                          type="checkbox"
                          id={c.name}
                        />
                        <label
                          className="text-slate-600 block cursor-pointer"
                          htmlFor={c.name}
                        >
                          {c.name}
                        </label>
                      </div>

                      {c?.childId?.length > 0 &&
                        c?.childId.map((child, index) => {
                          return (
                            <div
                              className="ml-6 flex justify-start items-center gap-2 py-1"
                              key={index}
                            >
                              <input
                                checked={category === child._id ? true : false}
                                onChange={(e) => queryCategoey(e, child._id)}
                                type="checkbox"
                                id={child.name}
                              />
                              <label
                                className="text-slate-600 block cursor-pointer"
                                htmlFor={child.name}
                              >
                                {child.name}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  ))}
                </div>
                <div className="py-2 flex flex-col gap-5">
                  <h2 className="text-xl font-bold mb-1 text-slate-600">
                    Price range
                  </h2>
                  <Range
                    step={5}
                    min={priceRange.low}
                    max={priceRange.high}
                    values={state.values}
                    onChange={(values) => setState({ values })}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        className="w-full h-[6px] bg-slate-200 rounded-full cursor-default"
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        className="w-[15px] h-[15px] bg-green-500 rounded-full"
                        {...props}
                      />
                    )}
                  />
                  <div>
                    <span className="text-green-500 font-bold text-lg">
                      ৳{Math.floor(state.values[0])} - ৳
                      {Math.floor(state.values[1])}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-9/12 md-lg:w-8/12 md:w-full">
                <div className="pl-8 md:pl-0">
                  <div className="py-4 bg-white mb-10 px-3 rounded-md flex justify-between items-start border">
                    <h2 className="text-lg font-medium text-slate-600">
                      {totalProduct} Products
                    </h2> */}
        {/* <div className="flex justify-center items-center gap-3">
                      <select
                        onChange={(e) => setSortPrice(e.target.value)}
                        className="p-1 border outline-0 text-slate-600 font-semibold"
                        name=""
                        id=""
                      >
                        <option value="">Sort By</option>
                        <option value="low-to-high">Low to High Price</option>
                        <option value="high-to-low">High to Low Price</option>
                      </select> */}
        {/* <div className="flex justify-center items-start gap-4 md-lg:hidden">
                        <div
                          onClick={() => setStyles("grid")}
                          className={`p-2 ${
                            styles === "grid" && "bg-slate-300"
                          } text-slate-600 hover:bg-slate-300 cursor-pointer rounded-sm`}
                        >
                          <BsFillGridFill />
                        </div>
                        <div
                          onClick={() => setStyles("list")}
                          className={`p-2 ${
                            styles === "list" && "bg-slate-300"
                          } text-slate-600 hover:bg-slate-300 cursor-pointer rounded-sm`}
                        >
                          <FaThList />
                        </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="pb-8 flex flex-wrap justify-center"> */}
        {/* <ShopProducts products={products} styles={styles} /> */}
        {/* {products.map((product, index) => (
                      <ProductCard product={product} index={index} />
                    ))}
                  </div>
                  <div>
                    {totalProduct > parPage && (
                      <Pagination
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        totalItem={totalProduct}
                        parPage={parPage}
                        showItem={Math.floor(totalProduct / parPage)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="bg-white">
          <div>
            {/* Mobile filter dialog */}
            <Transition.Root show={mobileFiltersOpen} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-40"
                onClose={setMobileFiltersOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                      <div className="flex items-center justify-between px-4">
                        <h2 className="text-lg font-medium text-gray-900">
                          Filters
                        </h2>
                        <button
                          type="button"
                          className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                          onClick={() => setMobileFiltersOpen(false)}
                        >
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>

                      {/* Filters */}
                      <form className="mt-4 border-t border-gray-200">
                        <h3 className="sr-only">Categories</h3>

                        {filters.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.id}
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                    <span className="font-medium text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <MinusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <PlusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  </Disclosure.Button>
                                </h3>

                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-6">
                                    {categorys.map((c, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <input
                                          checked={
                                            category === c._id ? true : false
                                          }
                                          onChange={(e) =>
                                            queryCategoey(e, c._id)
                                          }
                                          type="checkbox"
                                          id={c.name}
                                          name={c.name}
                                          // defaultChecked={category.checked}
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={c.name}
                                          className="ml-3 min-w-0 flex-1 text-gray-500"
                                        >
                                          {c.name}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      </form>
                      <form className="mt-4 border-t border-gray-200 w-[85%] mx-auto">
                        <div className="py-2 flex flex-col gap-5">
                          <h2 className="text-xl font-bold mb-1 text-slate-600">
                            Price range
                          </h2>
                          <Range
                            step={5}
                            min={priceRange.low}
                            max={priceRange.high}
                            values={state.values}
                            onChange={(values) => setState({ values })}
                            renderTrack={({ props, children }) => (
                              <div
                                {...props}
                                className="w-full h-[6px] bg-slate-200 rounded-full cursor-default"
                              >
                                {children}
                              </div>
                            )}
                            renderThumb={({ props }) => (
                              <div
                                className="w-[15px] h-[15px] bg-green-500 rounded-full"
                                {...props}
                              />
                            )}
                          />
                          <div>
                            <span className="text-green-500 font-bold text-lg">
                              ৳{Math.floor(state.values[0])} - ৳
                              {Math.floor(state.values[1])}
                            </span>
                          </div>
                        </div>
                      </form>

                      <form className="mt-4 border-t border-gray-200">
                        <h3 className="sr-only">Colors</h3>

                        {myboi.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.id}
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                    <span className="font-medium text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <MinusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <PlusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  </Disclosure.Button>
                                </h3>

                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-6">
                                    {allColors?.map((c, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <input
                                          checked={
                                            selectedColor === c ? true : false
                                          }
                                          onChange={(e) =>
                                            queryColor(e, c)
                                          }
                                          type="checkbox"
                                          id={c}
                                          name={c}
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={c}
                                          className="ml-3 min-w-0 flex-1 text-gray-500"
                                        >
                                          {c}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      </form>

                      <form className="mt-4 border-t border-gray-200">
                        <h3 className="sr-only">Colors</h3>

                        {allBrand.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.id}
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                    <span className="font-medium text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <MinusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <PlusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  </Disclosure.Button>
                                </h3>

                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-6">
                                    {allBrands?.map((c, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <input
                                          checked={
                                            selectedBrand === c ? true : false
                                          }
                                          onChange={(e) =>
                                            queryBrand(e, c)
                                          }
                                          type="checkbox"
                                          id={c}
                                          name={c}
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={c}
                                          className="ml-3 min-w-0 flex-1 text-gray-500"
                                        >
                                          {c}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      </form>

                      <form className="mt-4 border-t border-gray-200">
                        <h3 className="sr-only">Colors</h3>

                        {allSizes.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.id}
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                    <span className="font-medium text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <MinusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <PlusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  </Disclosure.Button>
                                </h3>

                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-6">
                                    {allStorages?.map((c, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <input
                                          checked={
                                            selectedStorage === c ? true : false
                                          }
                                          onChange={(e) =>
                                            queryStorage(e, c)
                                          }
                                          type="checkbox"
                                          id={c}
                                          name={c}
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={c}
                                          className="ml-3 min-w-0 flex-1 text-gray-500"
                                        >
                                          {c}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            <main className="mx-auto max-w-7xl px-4 sm:px-2 lg:px-8">
              <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-4">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                  All Products
                </h1>

                <div className="flex items-center">
                  <div>
                    <select
                      onChange={(e) => setSortPrice(e.target.value)}
                      className="p-1 border outline-0 text-slate-600 font-semibold sm:max-w-[100px]"
                      name=""
                      id=""
                    >
                      <option value="">Sort By</option>
                      <option value="new" className="text-xs">
                        New Arrival
                      </option>
                      <option value="low-to-high" className="text-xs">
                        Low to High Price
                      </option>
                      <option value="high-to-low" className="text-xs">
                        High to Low Price
                      </option>
                    </select>
                  </div>
                  <button
                    type="button"
                    className="-m-2 ml-6 p-2 text-gray-400 hover:text-gray-500 sm:ml-2"
                    onClick={() => setMobileFiltersOpen(true)}
                  >
                    <span className="sr-only">Filters</span>
                    <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>

              <section
                aria-labelledby="products-heading"
                className="pb-24 pt-6"
              >
                <h2 id="products-heading" className="sr-only">
                  Products
                </h2>

                {/* <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4"> */}
                {/* Filters */}
                {/* <form className="hidden lg:block">
                <h3 className="sr-only">Categories</h3>
                <ul role="list" className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                  {subCategories.map((category) => (
                    <li key={category.name}>
                      <a href={category.href}>{category.name}</a>
                    </li>
                  ))}
                </ul>

                {filters.map((section) => (
                  <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">{section.name}</span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon className="h-5 w-5" aria-hidden="true" />
                              ) : (
                                <PlusIcon className="h-5 w-5" aria-hidden="true" />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <div key={option.value} className="flex items-center">
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  type="checkbox"
                                  defaultChecked={option.checked}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form> */}

                {/* Product grid */}
                <div className="max-w-[1200px] w-[90%] sm:w-[95%] flex flex-wrap mx-auto sm:grid sm:grid-cols-2">
                  {products.map((product, index) => (
                    <ProductCard product={product} index={index} />
                  ))}
                </div>
                {/* </div> */}

                <div className="w-full mt-3 flex justify-center">
                  {totalProduct > parPage && (
                    <Pagination
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      totalItem={totalProduct}
                      parPage={parPage}
                      showItem={Math.floor(totalProduct / parPage)}
                    />
                  )}
                </div>
              </section>
            </main>
          </div>
        </div>
      </section>

      <BottomNav />
      <Footer />
    </div>
  );
};

export default Shops;
