import React, { useState, useEffect } from "react";
import api from "../../../api/api";
import addToken from "../../../api/addToken";
import Loader from '../../../utils/Loader'
import toast from "react-hot-toast";

const TransactionChart = () => {
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState();
  const [data, setData] = useState();
  const time = new Date().getHours();
  const getUser = async () => {
    
    setLoading(true);
    try {
      const { data } = await api.get(`/reseller/wallet`, addToken());
      setData(data);
    } catch (err) {
      toast.error(err.response.data.message);
    }
    
    setLoading(false);
  };
  useEffect(() => {
    
    setLoading(true);
    getUser();
    if (time >= 5 && time < 12) setMsg("Morning");
    else if (time >= 12 && time < 18) setMsg("Afternoon");
    else setMsg("Evening");
    
    setLoading(false);
  }, []);

  const convertUTCToLocalTime = (utcDateString) => {
    const utcDate = new Date(utcDateString);

    const timeZoneOffset = utcDate.getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() + timeZoneOffset * 60 * 1000);
    const formattedLocalTime = localDate.toLocaleDateString("en-US");

    return formattedLocalTime;
  };

  return (
    <div className="bg-white p-4 rounded-3xl mt-5 shadow-lg overflow-hidden">
      <div className="flex justify-between items-center">
        {/* <select className='outline-none px-3 py-1 border rounded-md text-slate-600' value={state} onChange={(e) => setState(e.target.value)}>
                  <option value="all">--order status---</option>
                  <option value="placed">Placed</option>
                  <option value="pending">Pending</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="warehouse">Warehouse</option>
              </select> */}
      </div>
      <div className="pt-4">
        <div className="relative overflow-x-auto">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            {
              loading ? <Loader/> :
              <table class="w-full text-sm text-left rtl:text-right text-blue-100 rounded-3xl overflow-hidden">
              <thead class="text-xs text-white uppercase bg-[#44ab5a] dark:text-white">
                <tr>
                  {/* <th scope="col" class="px-6 py-3">
                    Payment Id
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Tran. Id
                  </th> */}
                  <th scope="col" class="px-6 py-3">
                    Tran. amount
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Tran. Date
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Tran. status
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Tran. desc.
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((tran, index) => {
                  return (
                    <tr
                      key={index}
                      class={tran.type === "debit" ? "bg-green-300 border-b border-green-600": "bg-[#fc9d9d] border-b border-red-100"}
                    >
                      {/* <td
                        scope="row"
                        class="px-6 py-2 font-medium text-white whitespace-nowrap"
                      >
                        {tran.paymentID ? tran.paymentID : "---"}
                      </td>
                      <td
                        scope="row"
                        class="px-6 py-2 font-medium text-white whitespace-nowrap"
                      >
                        {tran.trxID ? tran.trxID : "---"}
                      </td> */}
                      <td
                        scope="row"
                        class="px-6 py-2 font-medium text-white whitespace-nowrap"
                      >
                        {tran.amount ? tran.amount : "---"}
                      </td>
                      <td
                        scope="row"
                        class="px-6 py-2 font-medium text-white whitespace-nowrap"
                      >
                        {convertUTCToLocalTime(tran.createdAt)}
                      </td>
                      <td
                        scope="row"
                        class="px-6 py-2 font-medium text-white whitespace-nowrap"
                      >
                        {tran.transactionStatus
                          ? tran.transactionStatus
                          : "---"}
                      </td>
                      <td
                        scope="row"
                        class="px-6 py-2 font-medium text-white whitespace-nowrap"
                      >
                        {tran.description ? tran.description : "---"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionChart;
