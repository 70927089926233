import React from 'react'
import Headers from '../components/Headers'
import BottomNav from '../components/BottomNav'
import Footer from '../components/Footer'

const AboutUs = () => {
  return (
    <div>
      <Headers/>
      <BottomNav/>
      <div className='max-w-[1200px] w-[80vw] mx-auto mt-4'>
      <h1 className='text-3xl font-bold'>Who We are -</h1>
      </div>
     <div className='bg-gray-100'>
     <div className='max-w-[1200px] w-[80vw] mx-auto py-10'>
        
        AladdinShopee is a one-stop tech shop that offers tech enthusiasts authentic smartphones, gadgets, and accessories from renowned brands so they can have a first-hand experience with the real thing. We aim to achieve the highest customer satisfaction by ensuring top-notch service through multichannel shopping stores, online store, EMI facility, exchange offers, free home delivery, dedicated service centers, and many more. <br /> <br />
  
  We built our business on client's trust, and we are committed to doing so as long as the clients are with us! We also have the vision to be Bangladesh's largest tech smartphones, gadgets, and accessories retailer. In AladdinShopee, we are continuously growing ourselves to meet the challenge of a new age and a new client base. We know that client satisfaction is a never-ending journey. Also, we have a strong dedicated team that thrusts us towards perfection and quality service.
  
        </div>
     </div>
      <Footer/>
    </div>
  )
}

export default AboutUs