import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MdSpaceDashboard } from "react-icons/md";
import { MdAdminPanelSettings } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import { FaClipboardCheck } from "react-icons/fa6";
import { BiMoneyWithdraw } from "react-icons/bi";
import "./sidebar.css";
import { RiTeamLine } from "react-icons/ri";
import { VscReferences } from "react-icons/vsc";
import api from "../../../api/api";
import addToken from "../../../api/addToken";

import { useDispatch, useSelector } from 'react-redux'

const Sidebar = () => {
  
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useLocation();
  return (
    <>
      <div className=" bg-white border border-r-indigo-100 h-[100vh] z-10">
        <div className="mb-12 flex justify-center">
          <Link to={'/'}>
            
          <img src="/main-logo-white.webp" alt="main-logo" className="max-w-[100px] w-[8vw] min-w-[50px]" />
          </Link>
        </div>

        <ul className="px-[1vw]">
          <li>
            <Link
              to=""
              className={`my-3 py-1 px-2 cursor-pointer flex items-center hover:bg-[#e5fcf5] ${
              navigate.pathname === "/reseller/" || navigate.pathname === "/reseller" && "bg-[#e5fce7]"
            }`}
            >
              <span style={{ width: "22px", marginRight: "8px" }}>
                <MdSpaceDashboard
                  size={22}
                  className={`mr-3  ${
                    navigate.pathname === "/reseller/" || navigate.pathname === "/reseller"
                      ? "text-[#50C878]"
                      : "text-gray-400"
                  }  `}
                />
              </span>
              <span
                className={`font-semibold ${
                  navigate.pathname === "/reseller/" || navigate.pathname === "/reseller"
                    ? "text-[#50C878]"
                    : "text-gray-400"
                }  lg:hidden`}
              >
                Dashboard
              </span>
            </Link>
          </li>

          <li>
            <Link
              to="/reseller/transactions" replace
              className={`my-3 py-1 px-2 cursor-pointer flex items-center hover:bg-[#e5fcf5] ${
                navigate.pathname === "/reseller/transactions" && "bg-[#e5fcf5]"
              }`}
            >
              <span style={{ width: "22px", marginRight: "8px" }}>
                <FaDatabase
                  size={22}
                  className={`mr-3  ${
                    navigate.pathname === "/reseller/transactions"
                      ? "text-[#50C878]"
                      : "text-gray-400"
                  }  `}
                />
              </span>
              <span
                className={`font-semibold ${
                  navigate.pathname === "/reseller/transactions"
                    ? "text-[#50C878]"
                    : "text-gray-400"
                } lg:hidden`}
              >
                Transactions
              </span>
            </Link>
          </li>

          <li>
            <Link
              to="orders"
              className={`my-3 py-1 px-2 cursor-pointer flex items-center hover:bg-[#e5fcf5] ${
                navigate.pathname === "/reseller/orders" && "bg-[#e5fcf5]"
              }`}
            >
              <span style={{ width: "22px", marginRight: "8px" }}>
                <FaClipboardCheck
                  size={20}
                  className={`mr-3  ${
                    navigate.pathname === "/reseller/orders"
                      ? "text-[#50C878]"
                      : "text-gray-400"
                  }  `}
                />
              </span>
              <span
                className={`font-semibold ${
                  navigate.pathname === "/reseller/orders"
                    ? "text-[#50C878]"
                    : "text-gray-400"
                } lg:hidden`}
              >
                Orders
              </span>
            </Link>
          </li>

          <li>
            <Link
              to={`withdraw-request/${userInfo.id}`}
              className={`my-3 py-1 px-2 cursor-pointer flex items-center hover:bg-[#e5fcf5] ${
                navigate.pathname === "/reseller/withdraw-request" && "bg-[#e5fcf5]"
              }`}
            >
              <span style={{ width: "22px", marginRight: "8px" }}>
                <BiMoneyWithdraw 
                  size={20}
                  className={`mr-3  ${
                    navigate.pathname === "/reseller/withdraw-request"
                      ? "text-[#50C878]"
                      : "text-gray-400"
                  }  `}
                />
              </span>
              <span
                className={`font-semibold ${
                  navigate.pathname === "/reseller/withdraw-request"
                    ? "text-[#50C878]"
                    : "text-gray-400"
                } lg:hidden`}
              >
                withdraw
              </span>
            </Link>
          </li>
          {/* <li>
            <Link
              to="withdraw"
              className={`my-3 py-1 px-2 cursor-pointer flex items-center hover:bg-[#e5fcf5] ${
                navigate.pathname === "/reseller/withdraw" && "bg-[#e5fcf5]"
              }`}
            >
              <span style={{ width: "22px", marginRight: "8px" }}>
                <MdWallet
                  size={22}
                  className={`mr-3  ${
                    navigate.pathname === "/reseller/withdraw"
                      ? "text-[#50C878]"
                      : "text-gray-400"
                  }  `}
                />
              </span>
              <span
                className={`font-semibold ${
                  navigate.pathname === "/reseller/withdraw"
                    ? "text-[#50C878]"
                    : "text-gray-400"
                } lg:hidden`}
              >
                Withdraw
              </span>
            </Link>
          </li> */}
          <li>
            <Link
              to="team-members"
              className={`my-3 py-1 px-2 cursor-pointer flex items-center hover:bg-[#e5fcf5] ${
                navigate.pathname === "/reseller/team-members" && "bg-[#e5fcf5]"
              }`}
            >
              <span style={{ width: "22px", marginRight: "8px" }}>
                <RiTeamLine
                  size={22}
                  className={`${
                    navigate.pathname === "/reseller/team-members"
                      ? "text-[#50C878]"
                      : "text-gray-400"
                  }  `}
                />
              </span>
              <span
                className={`font-semibold ${
                  navigate.pathname === "/reseller/team-members"
                    ? "text-[#50C878]"
                    : "text-gray-400"
                } lg:hidden`}
              >
                Sales Team
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="refer-tree"
              className={`my-3 py-1 px-2 cursor-pointer flex items-center hover:bg-[#e5fcf5] ${
                navigate.pathname === "/reseller/refer-tree" && "bg-[#e5fcf5]"
              }`}
            >
              <span style={{ width: "22px", marginRight: "8px" }}>
                <VscReferences
                  size={22}
                  className={`${
                    navigate.pathname === "/reseller/refer-tree"
                      ? "text-[#50C878]"
                      : "text-gray-400"
                  }  `}
                />
              </span>
              <span
                className={`font-semibold ${
                  navigate.pathname === "/reseller/refer-tree"
                    ? "text-[#50C878]"
                    : "text-gray-400"
                } lg:hidden`}
              >
                Refer Tree
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="profile"
              className={`my-3 py-1 px-2 cursor-pointer flex items-center hover:bg-[#e5fcf5] ${
                navigate.pathname === "/reseller/profile" && "bg-[#e5fcf5]"
              }`}
            >
              <span style={{ width: "22px", marginRight: "8px" }}>
                <MdAdminPanelSettings
                  size={22}
                  className={`${
                    navigate.pathname === "/reseller/profile"
                      ? "text-[#50C878]"
                      : "text-gray-400"
                  }  `}
                />
              </span>
              <span
                className={`font-semibold ${
                  navigate.pathname === "/reseller/profile"
                    ? "text-[#50C878]"
                    : "text-gray-400"
                } lg:hidden`}
              >
                Profile
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
