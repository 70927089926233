import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import addToken from "../../api/addToken";
import api from "../../api/api";
import toast from "react-hot-toast";
import Loader from "../../utils/Loader";

const Withdraw = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [balance, setBalance] = useState();
  const [withdrawDetails, setWithdrawDetails] = useState();

  const getData = async () => {
    setLoading(true);
    try {
      const res = await api.get("reseller/wallet/states", addToken());
      setBalance(res.data);
    } catch (err) {
      toast(err);
    }
    setLoading(false);
  };

  //  console
  const handleChange = (e) => {
    setWithdrawDetails({
      ...withdrawDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleWithdraw = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post(
        "/reseller/request-withdraw",
        withdrawDetails,
        addToken()
      );
      toast.success(res.data.message);
      setWithdrawDetails("");
    } catch (err) {
      toast.error(err.response.data.message);
    }
    setLoading(false);
  };
  const getResellerData = async () => {
    try {
      const { data } = await api.get(
        `/reseller/withdraw-requests/${id}`,
        addToken()
      );
      setData(data.data);
      setLoading(false);
    } catch (error) {
      console(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getResellerData();
    getData();
  }, []);
  return (
    <div className="max-w-[1400px] mx-auto h-[100vh] overflow-y-scroll">
      <div className="bg-green-800 w-full p-10 rounded-3xl flex md:flex-col md:items-center md:px-1 md:text-center md:w-[95%]">
        {
          loading ? <Loader/>:
          <>
          <div className="w-1/2  text-[#FFFFE0] border-r md:border-0 md:pb-5 md:border-b md:flex md:flex-col md:items-center">
          Available Balance to withdraw -
          <div>
            <div className="text-8xl md:text-4xl my-3">
              ৳<span className="font-bold">{balance?.totalBalance}</span>
            </div>
          </div>
          <div>
            <div className="text-md my-3 text-[#fff6]">
              You have total earned - ৳
              <span className="font-bold">{balance?.totalEarned}</span>
            </div>
          </div>
        </div>
        <div className="w-1/2 md:mt-5">
          <div class="w-full max-w-sm p-4 rounded-lg sm:p-6 md:p-8 mx-auto">
            <form class="space-y-6" onSubmit={handleWithdraw}>
              <div>
                <label
                  for="Amount"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Withdraw Amount
                </label>
                <input
                  type="text"
                  name="amount"
                  id="amount"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 "
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  for="number"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Your Number (Bkash)
                </label>
                <input
                  type="tel"
                  name="number"
                  id="number"
                  placeholder="01234567890"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 "
                  onChange={handleChange}
                  required
                />
              </div>

              <button
                type="submit"
                class="w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Withdraw
              </button>
            </form>
          </div>
        </div>
          </>
        }
      </div>

      <div className="bg-green-600 rounded-3xl mt-10 p-10 md:px-2 mb-[140px] md:w-[95%]">
        <div className="pt-4">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-white">
              <thead className="text-xs text-gray-700 uppercase bg-white">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Order Id
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Number
                  </th>
                  <th scope="col" className="px-6 py-3">
                    status
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((o, i) => (
                  <tr key={i} className="border-b">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap"
                    >
                      {o._id}
                    </td>
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap"
                    >
                      ৳{o.amount}
                    </td>
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap"
                    >
                      {o?.number}
                    </td>
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap"
                    >
                      {o?.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
