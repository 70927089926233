import React, { useState, useEffect } from "react";
import api from "../../../api/api";
import addToken from "../../../api/addToken";

import toast from 'react-hot-toast'

const Greet = () => {

  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState();
  const [user, setUser] = useState();
  const time = new Date().getHours();
  const getUser =async ()=>{
    try{
      const { data } = await api.get(`/reseller/getResellerInfo`, addToken());
      // console.log(data)
      setUser(data.data[0]);
      setLoading(false);
    }catch(err){
      toast.error(err.response.data.message)
    }
  }
  useEffect(() => {
    getUser()
    if (time >= 5 && time < 12) setMsg("Morning");
    else if (time >= 12 && time < 18) setMsg("Afternoon");
    else setMsg("Evening");
  }, []);
  return (
    <div className="col-span-3 md:col-span-1 mb-5 h-full  bg-white">
      <div className=" rounded-3xl py-2 md:px-3 px-20 shadow-xl h-full flex flex-wrap-reverse justify-between md:justify-center items-center">
        <div>
          <div className="text-3xl font-bold">
            Good {msg && msg}, <br /> <span className="text-[#154425]">{user?.firstName}</span>
          </div>
          <p>Hope you'r having a great day!</p>
        </div>
        <div>
          <img src="/greet.svg" alt="greet" className="w-[220px] min-w-[200px]" />
        </div>
      </div>
    </div>
  );
};

export default Greet;
