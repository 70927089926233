import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  get_card_products,
  delete_card_product,
  messageClear,
  quantity_inc,
  quantity_dec,
} from "../../store/reducers/cardReducer";
import axios from "axios";
import { useEffect, useState } from "react";
import api from "../../api/api";
import addToken from "../../api/addToken";
import Loader from "../../utils/Loader";

import toast from 'react-hot-toast'

const FormData = require("form-data");

const ProfileEdit = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [imageData, setImageData] = useState(null); // State to store image data

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    // phoneNumber: "",
    address: "",
    district: "",
  });

  const getResellerData = async () => {
    try {
      const { data } = await api.get(`/reseller/getResellerInfo`, addToken());
      setData(data.data[0]);
      setState({
        firstName: data.data[0].firstName,
        lastName: data.data[0].lastName,
        // phoneNumber: data.data[0].phoneNumber.phoneAcc,
        address: data.data[0].address,
        district: data.data[0].district,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching reseller data:", error);
      setLoading(false);
    }
  };

  const handleImageChange = (event) => {
    setImageData(event.target.files[0]); // Store the image data in base64 format
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewUrl(e.target.result); // Set preview URL for visualization (optional)
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (imageData) {
      const formData = new FormData();
      formData.append("avatar", imageData);
      try {
        const response = await api.patch(
          "/reseller/upload-profile-picture",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data;", // Replace with a custom boundary if needed
              token: localStorage.getItem("Token"),
            },
          }
        );
        
        toast.success(response.data.status)
        // Handle successful upload (e.g., display success message)
      } catch (error) {
        console.log(error);
        toast.success(error.response.data.message || "Image upload failed.")
        // setErrorMessage(error.response.data.message || "Image upload failed.");
      }
    }
    try{
        const res = await api.patch("/reseller/update-profile", state, addToken())
        if(res){
            toast.success(res.data.status)
        }
    }catch(err){
        toast.success(err.response.data.message || "profile update failed")
    }
  };

  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getResellerData();
  }, []);

  return (
    <div className="h-full flex flex-col overflow-y-scroll max-h-[100%] p-4  bg-white rounded-3xl no-scrollbar">
      {loading ? (
        <Loader />
      ) : (
        <div className="sm:flex block">
          <div className="min-w-56">
            <div className="flex flex-col border-b border-b-indigo-100 py-3 items-center justify-center">
              <div
                alt="default-logo"
                class="h-[10vw] aspect-square rounded-full min-h-[100px] max-h-[200px] mr-4 bg-center bg-cover"
                style={{
                  backgroundImage: `url("${
                    previewUrl? previewUrl: data.profileImage
                      ? `/profile-pics/${data.profileImage}`
                      : "/images/user.png"
                  }")`,
                }}
              ></div>

              <div>
                <form
                  onSubmit={handleSubmit}
                  encType={"multipart/form-data"}
                  className="flex flex-col items-center justify-center"
                >
                  <input
                    className="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none py-5 px-2 dark:placeholder-gray-400 mt-5"
                    id="avatar"
                    type="file"
                    name="avatar"
                    accept="image/*"
                    onChange={handleImageChange}
                  />

                  <div className="flex justify-between gap-3">
                    <div className="relative py-3 w-[50%]">
                      <input
                        onChange={inputHandle}
                        value={state.firstName}
                        type="text"
                        className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                        id="name"
                        name="firstName"
                        //   placeholder="first name"
                        required
                      />
                      <label htmlFor="firstName" className="absolute label">
                        First Name *
                      </label>
                    </div>
                    <div className="relative py-3 w-[50%]">
                      <input
                        onChange={inputHandle}
                        value={state.lastName}
                        type="text"
                        className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                        id="name"
                        name="lastName"
                        // placeholder="lastName"
                        required
                      />
                      <label htmlFor="lastName" className="absolute label">
                        Last Name
                      </label>
                    </div>
                  </div>
                  {/* <div className="relative py-3 w-full">
                    <input
                      onChange={inputHandle}
                      value={state.phoneNumber}
                      type="text"
                      className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                      id="email"
                      name="phoneNumber"
                      //   placeholder="number"
                      required
                    />
                    <label htmlFor="Phone" className="absolute label">
                      Phone number *
                    </label>
                  </div> */}

                  <div className="relative py-3 w-full">
                    <input
                      onChange={inputHandle}
                      value={state.district}
                      type="text"
                      className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                      id="district"
                      name="district"
                      required
                    />
                    <label htmlFor="district" className="absolute label">
                      District *
                    </label>
                  </div>
                  <div className="relative py-3 w-full">
                    <input
                      onChange={inputHandle}
                      value={state.address}
                      type="text"
                      className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                      id="address"
                      name="address"
                      required
                    />
                    <label htmlFor="Address" className="absolute label">
                      Business Name *
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="bg-[#154425] text-white py-2 px-10 rounded-3xl"
                  >
                    Save
                  </button>
                </form>
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
              </div>
            </div>
          </div>
          {/* <div className="py-3 sm:px-5 w-full"></div> */}
        </div>
      )}
    </div>
  );
};

export default ProfileEdit;
