import React from "react";

const Rank = ({ length }) => {
  if (length >= 5 && length <= 99) {
    return <div className="text-xs text-green-600">Sales Representative</div>;
  } else if (length >= 100 && length <= 199) {
    return <div className="text-xs text-green-600">jon Sales Executive </div>;
  } else if (length >= 200 && length <= 299) {
    return <div className="text-xs text-green-600">Senior Sales Executive </div>;
  } else if (length >= 300 && length <= 399) {
    return <div className="text-xs text-green-600">Area Sales Manager</div>;
  } else if (length >= 400 && length <= 499) {
    return <div className="text-xs text-green-600">jSenior Area Sales Manager </div>;
  } else if (length >= 500 && length <= 999) {
    return <div className="text-xs text-green-600">Division Sales Manager </div>;
  } else if (length >= 1000) {
    return <div className="text-xs text-green-600">National Sales Manager</div>;
  } else {
    return <div className="text-xs text-gray-700">Unranked</div>;
  }
};

export default Rank;
