import React from 'react'
import Headers from '../components/Headers'
import BottomNav from '../components/BottomNav'
import Footer from '../components/Footer'

const ReplacePolicy = () => {
  return (
    <div>
        <Headers/>
        <BottomNav/>
        <Footer/>
    </div>
  )
}

export default ReplacePolicy