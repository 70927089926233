import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  get_card_products,
  delete_card_product,
  messageClear,
  quantity_inc,
  quantity_dec,
} from "../../store/reducers/cardReducer";
import axios from "axios";
import { useEffect, useState } from "react";
import api from "../../api/api";
import addToken from "../../api/addToken";
import Loader from "../../utils/Loader";
import Rank from "./Rank";
import toast from 'react-hot-toast'

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [stats, setStats] = useState();
  const [child, setChild] = useState();

  const getResellerData = async () => {
    try {
      const { data } = await api.get(`/reseller/getResellerInfo`, addToken());
      setData(data.data[0]);
      
      setLoading(false);
    } catch (error) {
      console(error);
      setLoading(false);
    }
  };

  const getTeamMember = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        "/reseller/get-reseller-refer-tree",
        addToken()
      );

      setChild(countReferredChildren(res?.data))
    } catch (error) {
      toast(error.message);
    }
    setLoading(false);
  };


  
  const getData = async () => {
    setLoading(true);
    try {
      const res = await api.get("reseller/wallet/states", addToken());
      setStats(res.data);
    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };


  function countReferredChildren(data, generation = 0) {
    let totalChildren = 0;
    if ("hasReferred" in data && generation <= 4) {
      for (const child of data.hasReferred) {
        totalChildren++;
        totalChildren += countReferredChildren(child, generation + 1);
      }
    }
    return totalChildren;
  }
  useEffect(() => {
    getData()
    getTeamMember()
    getResellerData();
  }, []);


  
  return (
    <div className="h-full flex flex-col overflow-y-scroll max-h-[100%] p-4  bg-white rounded-3xl no-scrollbar">
      {loading ? (
        <Loader />
      ) : (
        <div className="block">
          <div className="sm:border-r sm:border-r-indigo-100 pr-4 min-w-56">
            <div className="flex items-center border-b border-b-indigo-100 py-3">
              <div
                alt="default-logo"
                className="h-[5vw] aspect-square rounded-full min-h-[60px] max-h-[150px] mr-4 bg-center bg-cover"
                style={{
                  backgroundImage: `url("${
                    data?.profileImage
                      ? `/profile-pics/${data?.profileImage}`
                      : "/images/user.png"
                  }")`,
                }}
              ></div>
              <div>
                <h2 className="text-2xl font-semibold">
                  {data?.firstName + " " + data?.lastName}{" "}
                </h2>
                <Rank length={child}/>
                <p className="text-[12px]">
                  Referral:
                  <b>{data?.referralCode}</b>
                </p>
              </div>
            </div>
            <div className="py-3 border-b border-b-indigo-100">
              <p
                style={{ fontSize: "10px", fontWeight: "700" }}
                className="text-gray-400"
              >
                Account Balance
              </p>
              <p className="primary-font-color-blue">BDT: {stats?.totalBalance}tk</p>
            </div>
          </div>
          <div className="py-3 w-full">
            <div className="text-3xl mb-5">Your Information's</div>
            <div className="flex justify-between border-t border-t-indigo-100 p-2">
              <div>First Name: </div>
              <div>{data?.firstName}</div>
            </div>
            <div className="flex justify-between border-t border-t-indigo-100 p-2">
              <div>Last Name: </div>
              <div>{data?.lastName}</div>
            </div>
            <div className="flex justify-between border-t border-t-indigo-100 p-2">
              <div>District: </div>
              <div>{data?.district}</div>
            </div>
            <div className="flex justify-between border-t border-t-indigo-100 p-2">
              <div>Business Name: </div>
              <div>{data?.address}</div>
            </div>
            <div className="flex justify-between border-t border-t-indigo-100 p-2">
              <div>Email: </div>
              <div>{data?.email?.emailAcc}</div>
            </div>
            {data?.referredBy && (
              <div className="flex justify-between border-t border-t-indigo-100 p-2">
                <div>ReferredBy: </div>
                <a href={`/reseller/viewProfile/${data?.referredBy._id}`} className="text-[#154425]">{data?.referredBy.firstName + " " +data?.referredBy.lastName}</a>
              </div>
            )}
          </div>
          <div className="flex items-center justify-center">
            <a
              href="profile-edit"
              className="bg-[#154425] text-white py-2 px-10 rounded-3xl"
            >
              Edit info
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
