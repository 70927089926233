import React from 'react'
import Headers from '../components/Headers'
import BottomNav from '../components/BottomNav'
import Footer from '../components/Footer'

const RefundPolicy = () => {
  return (
    <div>
        <Headers/>
        <BottomNav/>
        <div className='max-w-[1200px] w-[80%] mx-auto'>
        <h1 className='text-3xl py-4'><b>
Return & Refund Policy:
    
    </b></h1>
        </div>
        <div className='bg-gray-100'>
        <div className='max-w-[1200px] w-[80%] py-10 mx-auto'>

<b>
Product Condition:

</b>
<br />
1. The product must be unused, unworn, unwashed and without any flaws. Fashion products can be tried on to see if they fit and will still be considered unworn. <br />
2. The product must include the original tags, user manual, warranty cards, freebies and accessories. <br />
3. The product must be returned in the original and undamaged manufacturer packaging / box. Do not put tape or stickers on the manufacturers box. You can return the item if: The original invoice slip and exchange form must be enclosed with all returns or exchanges. We do not accept returns or exchanges without the original invoice slip. You can bring your return items at any of our outlets. Return/ Refunds policy for online purchases will be- <br />
<br />

We don't process exchanges after 7 working days  of the original purchase date. <br />
Pack your exchange securely in the original package and attach the invoice slip. <br />
Exchange time 7 working days after receiving the parcel from online store or any of our stores. <br />
    </div>
        </div>
        <Footer/>
    </div>
  )
}

export default RefundPolicy