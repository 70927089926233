import React, { Fragment, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Range } from "react-range";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import Products from "../components/products/Products";
import { AiFillStar } from "react-icons/ai";
import { CiStar } from "react-icons/ci";
import { BsFillGridFill } from "react-icons/bs";
import { FaThList } from "react-icons/fa";
import ShopProducts from "../components/products/ShopProducts";
import Pagination from "../components/Pagination";
import {
  price_range_product,
  query_products,
} from "../store/reducers/homeReducer";
import { useDispatch, useSelector } from "react-redux";
import api from "../api/api";

import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import ProductCard from "../components/productCard";
import BottomNav from "../components/BottomNav";
import Loader from "../utils/Loader";

import {
  add_to_card,
  messageClear,
  add_to_wishlist,
} from "../store/reducers/cardReducer";
import toast from "react-hot-toast";
import SearchBox from "../components/SearchBox";

const SearchProducts = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category");
  const searchValue = searchParams.get("value");
  const { categorys, products, totalProduct, latest_product, priceRange } =
    useSelector((state) => state.home);
  let parPage = 12;
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [styles, setStyles] = useState("grid");
  const [filter, setFilter] = useState(true);
  const [state, setState] = useState({
    values: [priceRange.low, priceRange.high],
  });
  const [rating, setRatingQ] = useState("");
  const [sortPrice, setSortPrice] = useState("");
  const [items, setItems] = useState();

  useEffect(() => {
    dispatch(price_range_product());
  }, []);
  useEffect(() => {
    setState({
      values: [priceRange.low, priceRange.high],
    });
  }, [priceRange]);

  const { errorMessage, successMessage } = useSelector((state) => state.card);

  const test = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(
        `/home/search-products?search=${
          searchValue ? searchValue : ""
        }&&rating=${rating}&&sortPrice=${sortPrice}&&pageNumber=${pageNumber}`
      );
      setItems(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, successMessage]);

  useEffect(() => {
    test();
    //     dispatch(
    //         query_products({
    //             low: state.values[0] || '',
    //             high: state.values[1] || '',
    //             // category,
    //             rating,
    //             sortPrice,
    //             pageNumber,
    //             searchValue
    //         })
    //     )
  }, [
    state.values[0],
    state.values[1],
    category,
    rating,
    pageNumber,
    sortPrice,
    searchValue,
  ]);

  // const resetRating = () => {
  //     setRatingQ('')
  //     dispatch(query_products({
  //         low: state.values[0],
  //         high: state.values[1],
  //         category,
  //         rating: '',
  //         sortPrice,
  //         pageNumber
  //     }))
  // }

  const queryCategoey = (e, value) => {
    if (e.target.checked) {
      setNewCategory(value);
    } else {
      setNewCategory("");
    }
  };

  const sortOptions = [
    { name: "Price: Low to High", href: "#", current: false },
    { name: "Price: High to Low", href: "#", current: false },
  ];
  // console.log(items.totalProducts>parPage)
  const filters = [
    {
      id: "category",
      name: "Category",
      options: [
        { value: "new-arrivals", label: "New Arrivals", checked: false },
        { value: "sale", label: "Sale", checked: false },
        { value: "travel", label: "Travel", checked: true },
        { value: "organization", label: "Organization", checked: false },
        { value: "accessories", label: "Accessories", checked: false },
      ],
    },
  ];



  
  console.log(items);




  return (
    <div>
      <Headers />
      <SearchBox/>
      <section className="pb-10">
       

        <div className="bg-white">
          <div>
            
            {/* <Transition.Root show={mobileFiltersOpen} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-40"
                onClose={setMobileFiltersOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                      <div className="flex items-center justify-between px-4">
                        <h2 className="text-lg font-medium text-gray-900">
                          Filters
                        </h2>
                        <button
                          type="button"
                          className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                          onClick={() => setMobileFiltersOpen(false)}
                        >
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>

                      <form className="mt-4 border-t border-gray-200">
                        <h3 className="sr-only">Categories</h3>

                        {filters.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.id}
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                    <span className="font-medium text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <MinusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <PlusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-6">
                                    {categorys.map((c, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <input
                                          checked={
                                            category === c._id ? true : false
                                          }
                                          onChange={(e) =>
                                            queryCategoey(e, c._id)
                                          }
                                          type="checkbox"
                                          id={c.name}
                                          name={c.name}
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={c.name}
                                          className="ml-3 min-w-0 flex-1 text-gray-500"
                                        >
                                          {c.name}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root> */}

            <main className="mx-auto max-w-7xl px-4 sm:px-2 lg:px-8">
              <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-4">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                  Search Results
                </h1>

                <div className="flex items-center">
                  <div>
                    <select
                      onChange={(e) => setSortPrice(e.target.value)}
                      className="p-1 border outline-0 text-slate-600 font-semibold sm:max-w-[100px]"
                      name=""
                      id=""
                    >
                      <option value="">Sort By</option>
                      <option value="new" className="text-xs">
                        New Arrival
                      </option>
                      <option value="low-to-high" className="text-xs">
                        Low to High Price
                      </option>
                      <option value="high-to-low" className="text-xs">
                        High to Low Price
                      </option>
                    </select>
                  </div>
                  {/* <button
                    type="button"
                    className="-m-2 ml-6 p-2 text-gray-400 hover:text-gray-500 sm:ml-2"
                    onClick={() => setMobileFiltersOpen(true)}
                  >
                    <span className="sr-only">Filters</span>
                    <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                  </button> */}
                </div>
              </div>

              <section
                aria-labelledby="products-heading"
                className="pb-24 pt-6"
              >
                <h2 id="products-heading" className="sr-only">
                  Products
                </h2>

                {/* <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4"> */}
                {/* Filters */}
                {/* <form className="hidden lg:block">
                  <h3 className="sr-only">Categories</h3>
                  <ul role="list" className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                    {subCategories.map((category) => (
                      <li key={category.name}>
                        <a href={category.href}>{category.name}</a>
                      </li>
                    ))}
                  </ul>
  
                  {filters.map((section) => (
                    <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-gray-900">{section.name}</span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                ) : (
                                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-4">
                              {section.options.map((option, optionIdx) => (
                                <div key={option.value} className="flex items-center">
                                  <input
                                    id={`filter-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type="checkbox"
                                    defaultChecked={option.checked}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-${section.id}-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-600"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>3
                      )}
                    </Disclosure>
                  ))}
                </form> */}

                {/* Product grid */}
                <div className="max-w-[1200px] w-[90%] sm:w-[95%] flex flex-wrap mx-auto sm:grid sm:grid-cols-2">
                  {isLoading ? (
                    <Loader />
                  ) : items.totalProducts < 1 ? <div>No products found!</div>:(
                    items?.products.map((product, index) => (
                      <ProductCard product={product} index={index} />
                    ))
                  )}
                </div>
                {/* </div> */}

                <div className="w-full mt-3 flex justify-center">
                  {items?.totalProducts > parPage && (
                    <Pagination
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      totalItem={items?.totalProducts}
                      parPage={parPage}
                      showItem={Math.floor(items?.totalProducts / parPage)}
                    />
                  )}
                </div>
              </section>
            </main>
          </div>
        </div>
      </section>

      <BottomNav />
      <Footer />
    </div>
  );
};

export default SearchProducts;
