import React, { useEffect, useState } from 'react'
import api from '../../../api/api'
import addToken from '../../../api/addToken'
// import logo from "../../../public/logo192.png"

const Header = () => {
  
  const [data, setData] = useState()
  const getUserDetails = async ()=>{
    try{
        const res = await api.get('/reseller/getResellerInfo', addToken())
        setData(res.data.data[0])
    }catch(error){
        console.log(error)
    }
}
useEffect(()=>{
  getUserDetails()
},[])

  return (
    <nav className='h-[10%] px-2 items-center flex justify-end border border-b-indigo-100 '>
        <span>
          <div
                alt="default-logo"
                className="h-[4vw] aspect-square rounded-full min-h-[40px] max-h-[100px] mr-4 bg-center bg-cover"
                style={{
                  backgroundImage: `url("${data?.profileImage
                      ? `/profile-pics/${data?.profileImage}`
                      : "/images/user.png"
                  }")`,
                }}
              ></div>
        </span>
    </nav>
  )
}

export default Header