import { useState } from 'react'

const SideBarCategory = ({ category }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    return (
        <>
            {
                category.childId.length > 0 ? <><button className="bg-green-300 p-2 hover:bg-green-400 transition-all rounded-xl">
                    <div className='w-full flex justify-between items-center'>
                        <a href={`/products/${category._id}`} className="block">

                            <div className="font-semibold text-green-900 sm:text-sm">{category?.name}</div>
                        </a>
                        <span className="block text-2xl px-3" onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "-" : "+"}</span>
                    </div>

                    <div className='w-full'>
                        {isExpanded &&
                            category.childId.map((child, i) => {
                                return <a href={`/products/${child._id}`} className="my-1 block w-full bg-green-200 p-2 hover:bg-green-100 transition-all rounded-xl">
                                    <div className="font-semibold text-green-900 sm:text-sm">{child?.name}</div>
                                </a>
                            })
                        }
                    </div>
                </button>

                </>
                    :
                    <a href={`/products/${category._id}`} className="bg-green-300 p-2 hover:bg-green-400 transition-all rounded-xl flex flex-col justify-between">

                        <div className="font-semibold text-green-900 sm:text-sm">{category?.name}</div>
                    </a>

            }
        </>
    )
}

export default SideBarCategory