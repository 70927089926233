import React from "react";
import TransactionChart from "../reseller/subcomponents/Transactions";

const Transactions = () => {
  return (
    <div className="h-full flex flex-col overflow-y-scroll max-h-[100%] p-4 md:p-1 scrollbar">
      <p className="text-3xl">Transactions</p>

      <div >
        <TransactionChart/>
      </div>
    </div>
  );
};

export default Transactions;
