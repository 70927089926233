import React, { useEffect, useState } from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import { Link, useParams, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css/pagination";

import Ratings from "../components/Ratings";
import { AiFillHeart } from "react-icons/ai";
import Reviews from "../components/Reviews";
import { get_product } from "../store/reducers/homeReducer";
import {
  add_to_card,
  messageClear,
  add_to_wishlist,
} from "../store/reducers/cardReducer";
import toast from "react-hot-toast";
import ProductCard from "../components/productCard";
import Loader from "../utils/Loader";
import BottomNav from "../components/BottomNav";

const Details = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { product, relatedProducts } = useSelector((state) => state.home);
  const { userInfo } = useSelector((state) => state.auth);
  const { errorMessage, successMessage } = useSelector((state) => state.card);

  // const [image, setImage] = useState("");
  const [resellerPrice, setResellerPrice] = useState(0);
  const [canBuy, setCanBuy] = useState(true);
  const [total, setTotal] = useState(0);
  const [state, setState] = useState("reviews");
  const [size, setSize] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [color, setColor] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mdtablet: {
      breakpoint: { max: 991, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
    smmobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
    xsmobile: {
      breakpoint: { max: 440, min: 0 },
      items: 1,
    },
  };

  const relatedItems = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 200 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  const [quantity, setQuantity] = useState(1);

  const inc = () => {
    if (quantity >= product.stock) {
      toast.error("Out of stock");
    } else {
      setQuantity(quantity + 1);
    }
  };

  const dec = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const add_card = () => {
    if (userInfo) {
      if (product?.sizes.length > 0) {
        if (
          size == false ||
          color == false ||
          selectedProduct == false ||
          selectedProduct == ""
        ) {
          toast.error("please select color and Size to continue");
          return;
        } else {
          dispatch(
            add_to_card({
              userId: userInfo.id,
              quantity,
              productId: product._id,
              color,
              size,
            })
          );
        }
      } else if (product?.colors.length > 0) {
        if (color == false) {
          toast.error("please select a color to continue");
          return;
        } else {
          dispatch(
            add_to_card({
              userId: userInfo.id,
              quantity,
              productId: product._id,
              color,
              size,
            })
          );
        }
      } else {
        dispatch(
          add_to_card({
            userId: userInfo.id,
            quantity,
            productId: product._id,
            color,
            size,
          })
        );
      }
    } else {
      navigate("/login");
    }
  };

  const add_wishlist = () => {
    if (userInfo) {
      dispatch(
        add_to_wishlist({
          userId: userInfo.id,
          productId: product._id,
          name: product.name,
          price: product.totalPrice,
          image: product.images[0],
          discount: product.discount,
          rating: product.rating,
        })
      );
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    dispatch(get_product(slug));

    // setTotal(product.price + product.adminProfit - product?.discount);
  }, [slug]);
  // console.log
  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, successMessage]);

  const buy = () => {
    if (product?.sizes.length > 0) {
      if (
        size == false ||
        color == false ||
        selectedProduct == false ||
        selectedProduct == ""
      ) {
        toast.error("please select color and Size to continue");
        return;
      } else {
        let price = selectedProduct + product.adminProfit;
        // if (product.discount !== 0) {
        //   price = total
        // } else {
        //   price = product.totalPrice
        // }
        const obj = [
          {
            sellerId: product.sellerId,
            price:
              quantity * price +
              quantity * parseInt(resellerPrice) -
              quantity * product.discount,
            products: [
              {
                quantity,
                size,
                color,
                productInfo: product,
              },
            ],
          },
        ];
        navigate(`/shipping`, {
          state: {
            products: obj,
            resellerPrice: parseInt(resellerPrice),
            price:
              quantity * price +
              quantity * parseInt(resellerPrice) -
              quantity * product.discount,
            items: 1,
          },
        });
      }
    } else if (product?.colors.length > 0) {
      if (color == false) {
        toast.error("please select a color continue");
        return;
      } else {
        let price = product.price + product.adminProfit;
        // if (product.discount !== 0) {
        //   price = total
        // } else {
        //   price = product.totalPrice
        // }
        const obj = [
          {
            sellerId: product.sellerId,
            price:
              quantity * price +
              quantity * parseInt(resellerPrice) -
              quantity * product.discount,
            products: [
              {
                quantity,
                size,
                color,
                productInfo: product,
              },
            ],
          },
        ];
        navigate(`/shipping`, {
          state: {
            products: obj,
            resellerPrice: parseInt(resellerPrice),
            price:
              quantity * price +
              quantity * parseInt(resellerPrice) -
              quantity * product.discount,
            items: 1,
          },
        });
      }
    } else {
      let price = product.price + product.adminProfit;
      // if (product.discount !== 0) {
      //   price = total
      // } else {
      //   price = product.totalPrice
      // }
      const obj = [
        {
          sellerId: product.sellerId,
          price:
            quantity * price +
            quantity * parseInt(resellerPrice) -
            quantity * product.discount,
          products: [
            {
              quantity,
              size,
              color,
              productInfo: product,
            },
          ],
        },
      ];
      navigate(`/shipping`, {
        state: {
          products: obj,
          resellerPrice: parseInt(resellerPrice),
          price:
            quantity * price +
            quantity * parseInt(resellerPrice) -
            quantity * product.discount,
          items: 1,
        },
      });
    }
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      product?.images.map(async (image) => {
        const link = document.createElement("a");
        link.href = "https://aladdinshopee.com/products/" + image;
        link.download = image.split("/").pop();
        link.click();
        await new Promise((resolve) => setTimeout(resolve, 100));
      });
      setIsDownloading(false);
    } catch (error) {
      console.error("Error downloading images:", error);
      setIsDownloading(false);
    }
  };
  const handleResellerPrice = () => {
    if (resellerPrice < 0) {
      toast.error("Reseller Price can't be in a negative value");
    } else {
      if (product?.sizes.length > 0) {
        setTotal(
          selectedProduct +
            product.adminProfit +
            parseInt(resellerPrice) -
            product.discount
        );
      } else {
        setTotal(
          product.price +
            product.adminProfit +
            parseInt(resellerPrice) -
            product.discount
        );
      }
    }
  };

  // useEffect(()=>{
  //   handleResellerPrice()
  // },[selectedProduct])
  const handleResellerPrices = (e) => {
    if (product?.sizes.length > 0) {
      if (selectedProduct == "" || selectedProduct == false) {
        toast.error("First select a product variant to add reseller Price");
        return;
      }
      if (e.target.value < 0) {
        toast.error("Reseller Price can't be in a negative value");
      } else if (e.target.value >= 0) {
        setResellerPrice(e.target.value);
      }
    } else {
      if (e.target.value < 0) {
        toast.error("Reseller Price can't be in a negative value");
      } else if (e.target.value >= 0) {
        setResellerPrice(e.target.value);
      }
    }
  };
// console.log(product.parentCategory.name.trim()=="Smart Phone & Accessories")
  return (
    <div>
      <Headers />
      {/* <div className='max-w-[1300px] w-[80%] mx-auto rounded-xl overflow-hidden bg-[url("https://aladdinshopee.com/images/banner/order.jpg")] bg-[#242222bd] bg-blend-multiply  h-[15vw] max-h-[200px] mt-6 bg-cover bg-no-repeat relative bg-left my-7'>
        <h2 className="md:text-sm text-3xl font-bold text-white backdrop-blur-sm h-full w-full  flex justify-center items-center">
          SIngle product Page
        </h2>
      </div> */}
      <section>
        <div className="max-w-[1200px] w-[80vw] md:w-[80vw] sm:w-[90vw] lg:w-[90vw] h-full mx-auto pb-16">
          <div className="flex flex-wrap gap-5">
            <div className="w-[90vw] max-w-[500px] lg:aspect-[1.3/1] lg:mx-auto max-h-[420px] ">
              {product.images && (
                <Carousel
                  autoPlay={true}
                  infinite={true}
                  responsive={responsive}
                  transitionDuration={500}
                >
                  {product?.images.map((img, i) => {
                    return (
                      <img
                        key={i}
                        className="cursor-pointer rounded-xl h-full sm:max-h-[300px] object-contain mx-auto"
                        src={"https://aladdinshopee.com/products/" + img}
                        alt="product img"
                      />
                    );
                  })}
                </Carousel>
              )}
            </div>
            <div className="flex flex-col gap-5 max-w-[500px]">
              <div className="text-3xl text-slate-600 font-bold">
                <h2>{product.name}</h2>
              </div>
              <div className="flex justify-start items-center gap-4">
                <div className="flex text-xl">
                  <Ratings ratings={product.rating} />
                </div>
                {/* <span className="text-green-500">(23 reviews)</span> */}
              </div>
              {userInfo.role === "reseller" ? (
                <div className="text-xl flex flex-col gap-3 w-[23vw] max-w-[400px] min-w-[250px]">
                  {product.discount !== 0 ? (
                    <div className="flex flex-col gap-3">
                      <div>
                        <div className="flex items-center gap-2 bg-[#c02929a8] px-3 py-1 rounded-3xl text-white w-full my-1">
                          <span className="text-sm font-bold">MRP- </span>
                          {!selectedProduct && (
                            <h2 className="line-through text-gray-400">
                              ৳{product.totalPrice}
                            </h2>
                          )}

                          <h2 className="font-bold text-white ">
                            {selectedProduct
                              ? selectedProduct +
                                product.AdminPrice -
                                product.discount
                              : product.totalPrice - product.discount}
                            {" ৳ "}
                            (-
                            {Math.floor(
                              100 -
                                ((product.totalPrice - product.discount) /
                                  product.totalPrice) *
                                  100
                            )}
                            %)
                          </h2>
                        </div>
                        <div className="flex items-center gap-2 bg-green-500  px-3 py-1 rounded-3xl text-white w-full my-1">
                          <span className="text-sm font-bold">
                            Wholesale Price-
                          </span>

                          <h2 className="font-bold text-white">
                            {" "}
                            {selectedProduct
                              ? selectedProduct +
                                product.adminProfit -
                                product.discount
                              : product.price +
                                product.adminProfit -
                                product.discount}
                            {" ৳ "}
                          </h2>
                        </div>
                        <h2 className="bg-green-500 px-3 py-1 rounded-3xl text-white w-full my-1">
                          <span className="text-sm font-bold">
                            your profit- :
                          </span>{" "}
                          {selectedProduct
                            ? selectedProduct +
                              product.adminProfit +
                              parseInt(resellerPrice) -
                              product.discount -
                              (selectedProduct +
                                product.adminProfit -
                                product.discount)
                            : product.price +
                              product.adminProfit +
                              parseInt(resellerPrice) -
                              product.discount -
                              (product.price +
                                product.adminProfit -
                                product.discount)}{" "}
                          ৳
                        </h2>
                      </div>
                      <div className="flex justify-center">
                        <div class="relative w-full min-w-[150px] h-10 ">
                          <input
                            type="number"
                            class="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 border focus:border-2  text-sm px-3 py-2.5 rounded-3xl border-blue-gray-200 focus:border-gray-900"
                            placeholder="add your profit price "
                            value={resellerPrice}
                            onChange={handleResellerPrices}
                          />
                        </div>
                        <button
                          type="button"
                          class="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                          onClick={handleResellerPrice}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-3 w-[23vw] max-w-[400px] min-w-[250px]">
                      <div className="sm:flex sm:flex-col sm:justify-center sm:items-center">
                        <h2 className="bg-[#c02929a8] px-3 py-1 text-white  w-full rounded-3xl">
                          <span className="text-sm font-bold">MRP- :</span>{" "}
                          {selectedProduct
                            ? selectedProduct + product.AdminPrice
                            : product.totalPrice}{" "}
                          ৳
                        </h2>
                        <h2 className="bg-green-500 px-3 py-1 rounded-3xl text-white w-full my-2">
                          <span className="text-sm font-bold">
                            Wholesale price- :
                          </span>{" "}
                          {selectedProduct
                            ? selectedProduct + product.adminProfit
                            : product.price + product.adminProfit}{" "}
                          ৳
                        </h2>
                        <h2 className="bg-green-500 px-3 py-1 rounded-3xl text-white w-full">
                          <span className="text-sm font-bold">
                            your profit- :
                          </span>{" "}
                          {selectedProduct
                            ? selectedProduct +
                              product.adminProfit +
                              parseInt(resellerPrice) -
                              (selectedProduct + product.adminProfit)
                            : product.price +
                              product.adminProfit +
                              parseInt(resellerPrice) -
                              (product.price + product.adminProfit)}{" "}
                          ৳
                        </h2>
                      </div>

                      <div className="flex justify-between  md:w-[70%]">
                        <div class="relative w-full min-w-[150px] h-10">
                          <input
                            class="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200  border focus:border-2  text-sm px-3 py-2.5 rounded-3xl border-blue-gray-200 focus:border-gray-900"
                            placeholder="add your profit price "
                            value={resellerPrice}
                            onChange={handleResellerPrices}
                          />
                        </div>

                        <button
                          type="button"
                          class="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                          onClick={handleResellerPrice}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-xl flex gap-3">
                  {product.discount !== 0 ? (
                    <>
                      {!selectedProduct && (
                        <h2 className="line-through text-gray-400">
                          ৳{product.totalPrice}
                        </h2>
                      )}
                      <h2 className="font-bold text-gray-900">
                        {selectedProduct
                          ? selectedProduct +
                            product.AdminPrice -
                            product.discount
                          : product.totalPrice - product.discount}
                        {" ৳ "}
                        (-
                        {Math.floor(
                          100 -
                            ((product.totalPrice - product.discount) /
                              product.totalPrice) *
                              100
                        )}
                        %)
                      </h2>
                    </>
                  ) : (
                    <h2>
                      Price :{" "}
                      {selectedProduct
                        ? selectedProduct + product.AdminPrice
                        : product.totalPrice}{" "}
                      ৳
                    </h2>
                  )}
                </div>
              )}
              {userInfo.role == "reseller" && total > 0 ? (
                <div className="text-slate-600">
                  <p>Total Price -</p>
                  <p>{total} ৳</p>
                </div>
              ) : (
                ""
              )}
              {product.sizes && product?.sizes.length > 0 && (
                <div className="flex">
                  {product.parentCategory &&
                    (product?.parentCategory.name ==
                    "Smart Phone & Accessories" ? (
                      <div>Storage: </div>
                    ) : (
                      <div>Size: </div>
                    ))}
                  <div className="flex flex-wrap gap-1">
                    {product.sizes &&
                      product.sizes.map((crSize, i) => {
                        return (
                          <div
                            key={i}
                            className={`sm:text-xs border px-3 py-[2px] mx-[2px] cursor-pointer hover:bg-green-800 hover:text-white ${
                              size == crSize.size && "bg-green-800 text-white"
                            }`}
                            onClick={(e) => {
                              setSize(crSize.size);
                              setSelectedProduct(parseInt(crSize.price));
                            }}
                          >
                            {crSize.size}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {product?.colors && product?.colors.length > 0 && (
                <div className="flex">
                  {" "}
                  <div>Colors: </div>
                  <div className="flex flex-wrap gap-1 sm:text-xs">
                    {product.colors &&
                      product.colors.map((crColor, i) => {
                        return (
                          <div
                            key={i}
                            className={`border px-3 py-[2px] mx-[2px] cursor-pointer hover:bg-green-800 hover:text-white ${
                              color == crColor && "bg-green-800 text-white"
                            }`}
                            onClick={() => {
                              setColor(crColor);
                            }}
                          >
                            {crColor}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}

              {userInfo.role === "reseller" && (
                <div
                  onClick={handleDownload}
                  className="bg-green-400 cursor-pointer hover:bg-green-600 px-3 py-2 w-max rounded text-white"
                >
                  {isDownloading ? <Loader /> : "Download Product Image"}
                </div>
              )}

              {userInfo.role === "reseller" && (
                <div
                  onClick={handleDownload}
                  className="bg-green-400 cursor-pointer hover:bg-green-600 px-3 w-max rounded text-white"
                >
                  <a
                    target="_blank"
                    class="flex w-max items-center gap-2  text-white rounded px-3 py-1 bg-gradient-to-r to-gradient-1 from-gradient-2  "
                    href={`https://wa.me/+8801911182363?text=I want to know more about *${product.name}`}
                  >
                    <svg
                      height="22"
                      width="22"
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 448 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                    </svg>
                    <div class="text-xs">
                      <p class="font-SFProDisplayMedium leading-3">Message</p>
                      <p class="mt-[2px] leading-4">on Whatsapp</p>
                    </div>
                  </a>
                </div>
              )}
              <div className="flex gap-3 pb-10 border-b">
                {product.stock && userInfo.role === "reseller" ? (
                  canBuy && (
                    <>
                      <div className="flex bg-slate-200 h-[50px] justify-center items-center text-xl">
                        <div
                          onClick={dec}
                          className="px-6 md:px-4 sm:px-2 cursor-pointer"
                        >
                          -
                        </div>
                        <div className="px-5">{quantity}</div>
                        <div
                          onClick={inc}
                          className="px-6 md:px-4 sm:px-2 cursor-pointer"
                        >
                          +
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <button
                          onClick={buy}
                          className="px-8 md:px-5 sm:px-3 py-3 h-[50px] cursor-pointer hover:shadow-lg hover:shadow-emerald-500/40 bg-emerald-500 text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </>
                  )
                ) : (
                  <>
                    <div className="flex bg-slate-200 h-[50px] justify-center items-center text-xl">
                      <div
                        onClick={dec}
                        className="px-6 md:px-4 sm:px-2 cursor-pointer"
                      >
                        -
                      </div>
                      <div className="px-5">{quantity}</div>
                      <div
                        onClick={inc}
                        className="px-6 md:px-4 sm:px-2 cursor-pointer"
                      >
                        +
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={add_card}
                        className="px-8 py-3 h-[50px] sm:text-sm sm:p-2 cursor-pointer hover:shadow-lg hover:shadow-green-500/40 bg-green-500 text-white"
                      >
                        Add To Cart
                      </button>
                    </div>
                  </>
                )}
                <div>
                  <div
                    onClick={add_wishlist}
                    className="h-[50px] w-[50px] flex justify-center items-center cursor-pointer hover:shadow-lg hover:shadow-green-500/40 bg-green-500 text-white"
                  >
                    <AiFillHeart />
                  </div>
                </div>
              </div>
              {/* <div className="flex py-5 gap-5">
                <div className="w-[150px] text-black font-bold text-xl flex flex-col gap-5">
                  <span>Availability</span>
                  <span>Share on</span>
                </div>
                <div className="flex flex-col gap-5">
                  <span
                    className={`text-${product.stock ? "green" : "red"}-500`}
                  >
                    {product.stock
                      ? `In Stock(${product.stock})`
                      : "Out of Stock"}
                  </span>
                  <ul className="flex justify-start items-center gap-3">
                    <li>
                      <a
                        className="w-[38px] h-[38px] hover:bg-[#7fad39] hover:text-white flex justify-center items-center bg-indigo-500 rounded-full text-white"
                        href="#"
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        className="w-[38px] h-[38px] hover:bg-[#7fad39] hover:text-white flex justify-center items-center bg-cyan-500 rounded-full text-white"
                        href="#"
                      >
                        <AiOutlineTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        className="w-[38px] h-[38px] hover:bg-[#7fad39] hover:text-white flex justify-center items-center bg-purple-500 rounded-full text-white"
                        href="#"
                      >
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        className="w-[38px] h-[38px] hover:bg-[#7fad39] hover:text-white flex justify-center items-center bg-blue-500 rounded-full text-white"
                        href="#"
                      >
                        <AiFillGithub />
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="w-[90%] max-w-[1200px] mx-auto">
        <div className="  h-full mx-auto pb-16">
          <div className="flex flex-wrap">
            <div className="w-[80%] md-lg:w-[90%] mx-auto">
              <div className="pr-4 md-lg:pr-0">
                <div className="flex items-center justify-center">
                  <button
                    onClick={() => setState("description")}
                    className={`py-1 px-5 hover:font-bold ${
                      state === "description"
                        ? " border-b-2 border-b-indigo-700 font-bold"
                        : " border-b-0"
                    } rounded-sm`}
                  >
                    Description
                  </button>
                  <button
                    onClick={() => setState("reviews")}
                    className={`py-1  px-5 hover:font-bold ${
                      state === "reviews"
                        ? " border-b-2 border-b-indigo-700 font-bold"
                        : " border-b-0"
                    } rounded-sm`}
                  >
                    Reviews
                  </button>
                  {product?.parentCategory &&
                    product?.parentCategory.name.trim() =="Smart Phone & Accessories" && (
                      <button
                        onClick={() => setState("exchanage")}
                        className={`py-1  px-5 hover:font-bold ${
                          state === "exchanage"
                            ? " border-b-2 border-b-indigo-700 font-bold"
                            : " border-b-0"
                        } rounded-sm`}
                      >
                        Exchange
                      </button>
                    )}
                </div>
                <div>
                  {state === "reviews" ? (
                    <Reviews product={product} />
                  ) : state === "exchanage" ? (
                    <div className="py-10 ">
                      <div>
                        <b>How to Exchange Any Device</b>
                        <br />
                        <br />
                        অনেককেই দেখছি আক্ষেপ করে বলতে "ফোন টা ভালো লেগেছে কিন্তু
                        এই মুহূর্তে তো এতো নগদ টাকা তো হাতে নেই, তাছাড়া বর্তমানে
                        যেই মোবাইলটি ব্যবহার করছি নতুন আরেকটি কিনলে ঐটার কি হবে
                        ! " তাই সামর্থ্য থাকা সত্ত্বেও যারা এতদিন পছন্দের ফোনটি
                        কিনতে পারছিলেন না এখন আর তাদের আক্ষেপ করতে হবে না কারণ
                        আমরা আপনাদের কথা ভেবে আমরা Exchange সুবিধা নিয়ে এসেছি।
                        <br />
                        <br />
                        <b> Exchange সুবিধা আসলে কি?</b>
                        <br />
                        <br />
                        মনে করুন, আপনি 14 Pro Max মডেলটি ব্যবহার করছেন প্রায়
                        ১বছর এখন আপনার 15 Pro Max এই মডেলটি পছন্দ হয়েছে কিন্তু
                        এই মুহূর্তে আপনার হাতে এতো টাকা নেই তাছাড়া নতুন আরেকটি
                        মোবাইল কিনলে বর্তমানে ব্যবহৃত মোবাইলটিও (14 Pro Max) বা
                        কি করবেন! আপনার এই চাহিদার কথা মাথায় রেখেই আমরা নিয়ে
                        এসেছি Exchange সুবিধা ! এখন থেকে আপনি চাইলেই আমাদের শপ
                        থেকে আপনার পুরনো মোবাইলটি Exchange করে নতুন আরেকটি
                        মোবাইল কিনতে পারবেন।
                        <br />
                        <br />
                        <b>
                          {" "}
                          Exchange সুবিধা পাওয়ার জন্য কিছু শর্ত পূরণ করতে হবে :
                        </b>
                        <br />
                        <br />
                        ১. মোবাইলটিকে অবশ্যই অরিজিনাল হতে হবে এবং এর IMEI
                        ম্যাচিং বক্স থাকতে হবে। <br />
                        ২. iPhone ছাড়া Android ফোন গুলো বাজারে চাহিদা আছে এরকম
                        মডেল হতে হবে বা ২০২১ সালের মডেল হতে হবে।
                        <br />
                        ৩. iPhone এর ক্ষেত্রে ব্যাটারি লাইফ ৮৫%-৯৫% হতে হবে। এর
                        নিচে থাকলে আমরা গ্রহন করবো না।
                        <br />
                        ৪. মোবাইলটি রিফারভিশড (Refurbished) হতে পারবে না এবং এর
                        কোন ধরনের অভ্যন্তরীণ বা বাহ্যিক সমস্যা থাকলে অথবা এর
                        বডিতে কোন অতিরিক্ত দাগ থাকলেও এটি গ্রহনযোগ্য হবে না।
                        <br />
                        <br />
                        <b>Exchange আসলে কিভাবে করা হবে?</b>
                        <br />
                        <br />
                        প্রথমে আপনার মোবাইলটি দেখার পর কন্ডিশন অনুযায়ী এটির একটি
                        মূল্য নির্ধারন করা হবে, এরপর আপনি চাইলে সেই মূল্যের সাথে
                        অতিরিক্ত টাকা যোগ করে যে কোন মোবাইল কিনতে পারবেন। এছাড়াও
                        আপনি চাইলে অবশিষ্ট মূল্য নগদ ছাড়াও ক্রেডিট কার্ডের
                        মাধ্যমে ই এম আই (EMI) সুবিধার মাধ্যমে পরিশোধ করতে
                        পারবেন।
                        <br />
                        <br />
                        <b> বিঃদ্রঃ</b> আপনার ডিভাইসটির মূল্য কত ধরা হবে তা আসলে
                        সরাসরি না দেখে ধারণা দেওয়া সম্ভব নয় কেননা অনেক সময় ফোন
                        কলে অনুমানের ভিত্তিতে দাম বললে ভুল হওয়ার সম্ভাবনা বেশি
                        হয় তাই এক্সচেঞ্জ করতে চাইলে ডিভাইসটি নিয়ে অবশ্যই আমাদের
                        শপ নিয়ে আসতে হবে।
                        <br />
                        <br />
                        এক্সচেঞ্জ করতে আসার সময় অবশ্যই আপনার এন আই ডি কার্ড অথবা
                        ছবি সম্বলিত কোনো পরিচয়পত্র নিয়ে আসবেন ।
                      </div>
                    </div>
                  ) : (
                    <div className="py-10 whitespace-pre-wrap">
                      {product.description}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-[90vw] max-w-[1200px] mx-auto">
        <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto">
          <h2 className="text-2xl py-8 text-slate-600">Related Products</h2>
          <div>
            <Carousel
              responsive={relatedItems}
              autoPlay={true}
              swipeable={true}
              draggable={true}
              showDots={false}
              infinite={true}
              partialVisible={false}
              dotListClass="custom-dot-list-style"
            >
              {relatedProducts.map((p, i) => {
                return (
                  <div className="w-full flex justify-center items-center">
                    <ProductCard product={p} index={i} />;
                  </div>
                );
              })}
            </Carousel>
          </div>
          <div className="w-full flex justify-center items-center py-10">
            <div className="custom_bullet justify-center gap-3 !w-auto"></div>
          </div>
        </div>
      </section>
      );
      <BottomNav />
      <Footer />
    </div>
  );
};

export default Details;
