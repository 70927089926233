import React from "react";
import { Link } from "react-router-dom";

const ProfileCard = ({ info }) => {
  return (
    <div className="block">
      <div className="sm:border-r sm:border-r-indigo-100 min-w-[100px] w-[15vw] max-w-[150px]">
        <div className="flex flex-col border-b border-b-indigo-100 py-3 items-center justify-center">
          <div className="py-3 sm:px-2 w-full">
            <div class="max-w-2xl mx-4 sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto bg-white shadow-xl rounded-lg text-gray-900">
              <div class="rounded-t-lg h-20 overflow-hidden">
                <img
                  class="object-cover object-top w-full"
                  src="https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
                  alt="Mountain"
                />
              </div>
              <div class="mx-auto min-w-[45px] max-w-[55px] w-[7vw] aspect-square relative -mt-16 md:-mt-10 border-4 border-white rounded-full overflow-hidden">
                <img
                  class="object-cover object-center min-w-[40px] max-w-[50px] w-[7vw] aspect-square"
                  src={`${
                    info?.profileImage
                      ? `/profile-pics/${info?.profileImage}`
                      : "/images/user.png"
                  }`}
                  alt="reseller profile"
                />
              </div>
              <div class="text-center mt-1">
                <h2 class="font-semibold">{info?.firstName + " " + info?.lastName}</h2>
                <p class="text-gray-500">{info?.address}</p>
              </div>
              <div class="p-4 md:p-1 border-t mt-2">
                <Link to={`/reseller/viewProfile/${info?._id}`} class=" block text-center mx-auto rounded-full bg-gray-900 hover:shadow-lg font-semibold text-white px-6 py-2 md:px-1 text-xs">
                  View
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
