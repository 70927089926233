import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import {Link} from "react-router-dom"


const Categories = () => {
  const { categorys } = useSelector((state) => state.home);

  const WordTrim = (props) => {
    if(props.length>10){
      return props.slice(0,10) +"..."
    }else {
      return props
  }
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 200 },
      items: 3,
      slidesToSlide: 1,
    },
  };
  return (
    <div className="max-w-[1200px] w-[90%] sm:w-[90%] mx-auto rounded-2xl mt-7">
      <div className="flex items-center text-4xl text-gray-900 font-bold relative pb-[45px]">
          <h2 className="sm:text-xl text-green-600">Shop by Categories</h2>
          <div className="w-[100px] h-[2px] bg-[#154425] ml-4"></div>
        </div>
      <div className="parent">
        
      <Carousel
              responsive={responsive}
              autoPlay={true}
              swipeable={true}
              draggable={true}
              // showDots={false}
              infinite={true}
              partialVisible={false}
              // dotListClass="custom-dot-list-style"
            >
      {categorys?.map((category, i) => {
        if(category.childId.length>0){
          return <div className="flex items-center justify-center h-full">
            <Link to={`/sub-categories/${category._id}`} className="bg-green-50 hover:bg-green-100 transition-all rounded-xl pb-4 w-[150px] sm:w-[100px] overflow-hidden flex flex-col justify-between items-center h-full">
            <div >
              <img src={'/category/'+category?.image} className="w-full aspect-square object-cover" alt="category" />
            </div>
            <div className="mt-4 font-semibold text-green-900 sm:text-sm px-4">{category?.name}</div>
          </Link>
          </div>
        }else{
          return <div className=" flex items-center justify-center h-full">
          
          <Link to={`/products/${category._id}`} className="bg-green-50 hover:bg-green-100 transition-all rounded-xl pb-4 w-[150px] sm:w-[100px] overflow-hidden flex flex-col justify-between items-center h-full">
            <div >
              <img src={'/category/'+category?.image} className="w-full aspect-square object-cover" alt="category" />
            </div>
            <div className="mt-4 font-semibold text-green-900 sm:text-sm px-4">{category?.name}</div>
          </Link>
          </div>
        }
      })}
      </Carousel>
      
      </div>
    </div>
  );
};

export default Categories;
