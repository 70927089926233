import React from 'react'
import { Link } from 'react-router-dom'
import { IoLogoWhatsapp } from "react-icons/io";

const WhatsAppCircle = () => {
  return (
    <a href='https://wa.me/+8801911182363?text=Hi, I need your help!' class="fixed z-50 w-[50px] aspect-square bg-green-500 border border-green-200  bottom-[50px] sm:bottom-[100px] right-[40px] sm:right-[20px] rounded-full cursor-pointer group" >
           <div className='w-[50px] aspect-square object-cover pointer-events-none hidden group-hover:absolute justify-center items-center'>
            How Can We help you?
           </div><div className='w-[50px] aspect-square object-cover pointer-events-none hidden group-hover:flex justify-center items-center'>
           <IoLogoWhatsapp className='text-3xl text-white'/>
           </div>
           <img src='seller.png' className='w-[50px] pointer-events-none aspect-square object-cover group-hover:hidden'/>
        </a>
  )
}

export default WhatsAppCircle