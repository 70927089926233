import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../api/api";
import addToken from "../../api/addToken";
import Loader from "../../utils/Loader";
import ProfileCard from "./ProfileCard";

const ReferTree = () => {
  const [teamMembers, setTeamMembers] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getTeamMember = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        "/reseller/get-reseller-refer-tree",
        addToken()
      );
      setTeamMembers(res.data);
    } catch (error) {
      toast(error.message);
    }
    setIsLoading(false);
  };
  function countReferredChildren(data, generation = 0) {
    let totalChildren = 0;
    if ("hasReferred" in data && generation <= 4) {
      for (const child of data.hasReferred) {
        totalChildren++;
        totalChildren += countReferredChildren(child, generation + 1);
      }
    }
    return totalChildren;
  }
  useEffect(() => {
    getTeamMember();
  }, []);

  const totalChildren =teamMembers&& countReferredChildren(teamMembers)
  console.log(totalChildren)
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : !teamMembers?.hasReferred?.length ? (
        <div>You have not referred anyone yet</div>
      ) : (
        <div className="h-full flex flex-col overflow-y-scroll max-h-[100%] scrollbar md:pr-0">
          <p className="text-3xl font-semibold">Your Refer Tree</p>
          <p className="text-xl font-semibold">Total  - {totalChildren && totalChildren}</p>
          <div>
            Directly referred by you
            <hr />
            <div className="flex flex-wrap">
              {teamMembers?.hasReferred?.map((member) => {
                return <ProfileCard info={member} />;
              })}
            </div>
          </div>

          <div>
            Second Generation refers
            <hr />
            <div className="flex flex-wrap">
              {teamMembers?.hasReferred?.map((member) => {
                return (
                  member.hasReferred.length > 0 &&
                  member.hasReferred.map((submember) => {
                    return <ProfileCard info={submember} />;
                  })
                );
              })}
            </div>
          </div>

          <div>
            Third Generation refers
            <hr />
            <div className="flex flex-wrap">
              {teamMembers?.hasReferred?.map((member) => {
                return member.hasReferred.map((submember) => {
                  return (
                    submember.hasReferred.length > 0 &&
                    submember.hasReferred.map((sub2member) => {
                      return <ProfileCard info={sub2member} />;
                    })
                  );
                });
              })}
            </div>
          </div>

          <div>
            Fourth Generation refers
            <hr />
            <div className="flex flex-wrap">
              {teamMembers?.hasReferred?.map((member) => {
                return member.hasReferred.map((submember) => {
                  return submember.hasReferred.map((sub2member) => {
                    return (
                      sub2member.hasReferred.length > 0 &&
                      sub2member.hasReferred.map((sub3member) => {
                        return <ProfileCard info={sub3member} />;
                      })
                    );
                  });
                });
              })}
            </div>
          </div>

          <div>
            Fifth Generation refers
            <hr />
            <div className="flex flex-wrap">
              {teamMembers?.hasReferred?.map((member) => {
                return member.hasReferred.map((submember) => {
                  return submember.hasReferred.map((sub2member) => {
                    return sub2member.hasReferred.map((sub3member) => {
                      return (
                        sub3member.hasReferred.length > 0 &&
                        sub3member.hasReferred.map((sub4member) => {
                          return <ProfileCard info={sub4member} />;
                        })
                      );
                    });
                  });
                });
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReferTree;
