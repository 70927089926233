import React, { useEffect, useState } from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import { FaFacebookF } from "react-icons/fa";
import FadeLoader from "react-spinners/FadeLoader";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineGoogle } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { FaEye } from "react-icons/fa";
import { FaLock } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa";

import { customer_register, messageClear } from "../store/reducers/authReducer";

const Register = () => {
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const { loader, successMessage, errorMessage, userInfo } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });

  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const register = (e) => {
    e.preventDefault();
    dispatch(customer_register(state));
    console.log(state);
  };
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (userInfo) {
      navigate("/");
    }
  }, [successMessage, errorMessage]);

  return (
    <div>
      {loader && (
        <div className="w-screen h-screen flex justify-center items-center fixed left-0 top-0 bg-[#38303033] z-[999]">
          <FadeLoader />
        </div>
      )}
      <Headers />
      <div className="border  border-t-[#0002] mt-4">
        <div className="w-full justify-center items-center p-10 md:px-2">
        <div className="flex justify-center mb-6">
            <Link to={'/login'} className="text-3xl text-gray-400 mr-3 font-extrabold">Log In</Link>
            <Link to={'/register'} className="text-3xl text-gray-900 ml-3 font-extrabold">Register</Link>

          </div>
          <div className="min-w-[350px] w-[40%]  mx-auto bg-white rounded-3xl border border-[#0002]">
            <div className="px-8 py-8">
              <h2 className="text-center w-full text-xl text-slate-600 font-bold">
                Register
              </h2>
              <div>
                <form onSubmit={register} className="text-slate-600">
                  <div className="flex justify-between gap-3">
                    <div className="relative py-3 w-[50%]">
                      <input
                        onChange={inputHandle}
                        value={state.name}
                        type="text"
                        className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                        id="name"
                        name="name"
                        //   placeholder="first name"
                        required
                      />
                      <label htmlFor="name" className="absolute label">
                        Name *
                      </label>
                    </div>
                  </div>
                  <div className="relative py-3">
                    <input
                      onChange={inputHandle}
                      value={state.email}
                      type="email"
                      className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                      id="email"
                      name="email"
                      //   placeholder="email"
                      required
                    />
                    <label htmlFor="email" className="absolute label">
                      Email *
                    </label>
                  </div>
                  <div className="relative py-3">
                    <input
                      onChange={inputHandle}
                      value={state.phone}
                      type="text"
                      className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                      id="email"
                      name="phone"
                      //   placeholder="number"
                      required
                    />
                    <label htmlFor="Phone" className="absolute label">
                      Phone number *
                    </label>
                  </div>
                  <div className="relative py-3">
                    <input
                      onChange={inputHandle}
                      value={state.password}
                      type={showPass ? "text" : "password"}
                      className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                      id="password"
                      name="password"
                      //   placeholder="password"
                      required
                    />
                    <label htmlFor="password" className="absolute label">
                      Password *
                    </label>
                    <div
                      className="absolute right-[20px] top-[50%] translate-y-[-50%] cursor-pointer"
                      onClick={() => {
                        setShowPass(!showPass);
                      }}
                    >
                      {showPass ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>

                  <div className="flex flex-col justify-center items-center">
                    <button
                      //   onClick={handlePayment}
                      className="bg-[#154425] md:px-14 px-28 py-3 rounded-xl text-white font-bold flex items-center shadow-lg hover:shadow-[#1544258b]"
                    >
                      <span>Register</span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="text-center text-slate-600 pt-9">
                <p>
                  Already A Member?{" "}
                  <Link className="text-blue-500" to="/login">
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
