import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import addToken from "../../api/addToken";
import Loader from "../../utils/Loader";
const ViewProfile = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const getResellerData = async () => {
    try {
      const { data } = await api.get(
        `/reseller/getSingleReseller/${id}`,
        addToken()
      );
      setData(data.data[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getResellerData();
  }, []);

  
  return (
    <div className="h-full flex flex-col overflow-y-scroll max-h-[100%] p-4  bg-white rounded-3xl no-scrollbar">
      {loading ? (
        <Loader />
      ) : data == undefined ? (
        <div className="w-full h-full flex items-center justify-center text-4xl md:text-xl text-center">No Reseller found with that Id</div>
      ) : (
        <div className="sm:flex block justify-center">
          <div className="min-w-56">
            <div className="flex flex-col border-b border-b-indigo-100 py-3 items-center justify-center">
              <div
                alt="default-logo"
                className="h-[10vw] aspect-square rounded-full min-h-[100px] max-h-[200px] bg-center bg-cover"
                style={{
                  backgroundImage: `url("${
                    data?.profileImage
                      ? `/profile-pics/${data?.profileImage}`
                      : "/images/user.png"
                  }")`,
                }}
              ></div>

              <div className="w-full flex flex-col items-center mt-5">
                <div>
                  <h2 className="text-2xl font-semibold">
                    {data?.firstName + " " + data?.lastName}
                  </h2>
                </div>

                <div className="py-3 sm:px-5 w-full">
                  <div className="text-3xl mb-5 md:text-sm">
                    {data.firstName}'s Information
                  </div>
                  <div className="flex justify-between border-t border-t-indigo-100 p-2">
                    <div>First Name: </div>
                    <div>{data?.firstName}</div>
                  </div>
                  <div className="flex justify-between border-t border-t-indigo-100 p-2">
                    <div>Last Name: </div>
                    <div>{data?.lastName}</div>
                  </div>
                  <div className="flex justify-between border-t border-t-indigo-100 p-2">
                    <div>Email: </div>
                    <div>{data?.email?.emailAcc}</div>
                  </div>
                  <div className="flex justify-between border-t border-t-indigo-100 p-2">
                    <div>District: </div>
                    <div>{data?.district}</div>
                  </div>
                  {data?.referredBy && (
                    <div className="flex justify-between border-t border-t-indigo-100 p-2">
                      <div>ReferredBy: </div>
                      <a href={`/reseller/viewProfile/${data?.referredBy._id}`} className="text-[#154425]">
                        {data?.referredBy.firstName +
                          " " +
                          data?.referredBy.lastName}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="py-3 sm:px-5 w-full "></div> */}
        </div>
      )}
    </div>
  );
};

export default ViewProfile;
