import React, { useEffect, useState } from "react";
import Greet from "./subcomponents/greet";
import StateBox from "./subcomponents/stateBox";
import SalesReportChart from "./subcomponents/salesReportChart";
import TransactionChart from "./subcomponents/Transactions";
import toast from "react-hot-toast";
import api from "../../api/api";
import addToken from "../../api/addToken";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../utils/Loader";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";

const ResellerHome = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [banner, setBanner] = useState();
  const dispatch = useDispatch();


  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await api.get("reseller/wallet/states", addToken());
      setData(res.data);
    } catch (err) {
      if(err.response.status === 401){
        toast.error("session expired! login again")
        localStorage.removeItem('Token')
            navigate('/login')
      }
    }
    setIsLoading(false);
  };
  const getBanner = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get("/banner", addToken());
      setBanner(data);
    } catch (err) {
      toast.error(err.response.data.message);
    }
    setIsLoading(false);
  };

  
  useEffect(() => {
    getData();
    getBanner();
  }, []);


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="h-full flex flex-col overflow-y-scroll max-h-[100%] scrollbar md:pr-0 max-w-[1200px] mx-auto">
          <p className="text-3xl mb-5">Dashboard</p>
          <div className="flex justify-between md:flex-col">
            <div className="w-[70%] h-full md:w-[95%] pr-5 md:pr-0">
              <Carousel autoPlay={true} infiniteLoop={true}  className="bg-white h-full rounded-3xl overflow-hidden">
                <Greet />
                {
                  banner?.map((banner,i)=>{
                    return <div key={i} className="col-span-3 md:col-span-1 mb-5 h-full">
                    <div className=" bg-white rounded-3xl py-2 md:px-3 px-20 shadow-xl h-full flex flex-wrap-reverse justify-between md:justify-center items-center">
                      <img src={"https://aladdinshopee.com/resellerBanner/"+banner.image} alt="banner" className="max-h-[300px] object-cover" />
                    </div>
                  </div>
                  })
                }
              </Carousel>
            </div>
            <div className="w-[30%] md:w-[95%]">
              <StateBox
                totalBalance={data?.totalEarned}
                totalWithdrew={data?.totalWithdrew}
                remainingBalance={data?.totalBalance}
                totalTransactions={data?.totalTransactions}
                totalOrders={data?.orders.length}
              />
            </div>
          </div>
          <div className="mt-7">
            <SalesReportChart chart={data?.chart} />
          </div>
          <div className="md:w-[95%]">
            <TransactionChart />
          </div>
        </div>
      )}
    </>
  );
};

export default ResellerHome;
