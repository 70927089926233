import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaSearch } from "react-icons/fa";

const SearchBox = () => {
    const [searchValue, setSearchValue] = useState()
    const navigate = useNavigate()

    const search = () => {
        navigate(`/search/products?value=${searchValue}`);
    };
    return (
        <div className="max-w-[1200px] w-[90%] lg:w-[90%]  mx-auto mb-3">
            <div className="flex flex-wrap w-full justify-between items-center md-lg:gap-6">
                <div className="w-full">
                    <div className="flex border h-[50px] items-center relative gap-5 rounded-full">
                        {/* <div className="relative after:absolute after:h-[25px] after:w-[1px] after:-right-[15px] md:hidden">
                    <select
                      onChange={(e) => setCategory(e.target.value)}
                      className="w-[150px] text-slate-900 font-semibold bg-transparent px-2 h-full outline-0 border-none"
                      name=""
                      id=""
                    >
                      <option value="">Select category</option>
                      {categorys.map((c, i) => (
                        <option key={i} value={c.name}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div> */}
                        <input
                            className="w-full relative bg-transparent text-slate-500 outline-0 px-3 h-full"
                            onChange={(e) => setSearchValue(e.target.value)}
                            type="text"
                            name=""
                            id=""
                            placeholder="what do you need"
                        />
                        <button
                            onClick={search}
                            className="bg-green-700 right-0 absolute px-5 h-full font-semibold uppercase text-white rounded-e-full"
                        >
                            <FaSearch />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchBox