import React, { useState } from "react";
import api from "../../api/api";
import addToken from "../../api/addToken";
import toast from 'react-hot-toast'
const ChangePassword = () => {
  const [pass, setPass] = useState({
    newPass: "",
    oldPass: "",
    confirmPass: "",
  });


  const handleSubmit = async (e) => {
    e.preventDefault();
    if(pass.newPass !== pass.confirmPass ){
      toast.error("new password and confirm password does not match!")
    }else{
    try{
        const data = await api.patch("/change-password",{"oldPass":pass.oldPass,"newPass":pass.newPass}, addToken())
        toast.success("changed successfully")
    }catch(err){
        toast.error(err.response.data.message)
    }
  }
  };
  return (
    <div className="p-4 bg-white">
      <h2 className="text-xl text-slate-600 pb-5">Change Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-1 mb-2">
          <label htmlFor="old_password">Old Password</label>
          <input
            type="password"
            id="old_password"
            name="oldPass"
            placeholder="old password"
            className="outline-none px-3 py-1 border rounded-md text-slate-600"
            onChange={(e)=> setPass({
                ...pass,
            [e.target.name]: e.target.value
            })}
          />
        </div>
        <div className="flex flex-col gap-1 mb-2">
          <label htmlFor="new_password">New Password</label>
          <input
            type="password"
            id="new_password"
            name="newPass"
            placeholder="new password"
            className="outline-none px-3 py-1 border rounded-md text-slate-600"
            onChange={(e)=> setPass({
                ...pass,
                [e.target.name]: e.target.value
            })}
          />
        </div>
        <div className="flex flex-col gap-1 mb-2">
          <label htmlFor="new_password">Confirm Password</label>
          <input
            type="password"
            id="new_password"
            name="confirmPass"
            placeholder="new password"
            className="outline-none px-3 py-1 border rounded-md text-slate-600"
            onChange={(e)=> setPass({
                ...pass,
                [e.target.name]: e.target.value
            })}
          />
        </div>
        <div>
          <button className="px-8 py-2 bg-purple-500 shadow-lg hover:shadow-purple-500/30 text-white rounded-md">
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
