import React, { useState,useEffect } from 'react'
import Headers from '../../components/Headers'
import Footer from '../../components/Footer'
import { FaFacebookF } from 'react-icons/fa'
import { Link,useNavigate, useParams, useLocation } from 'react-router-dom'
import { AiOutlineGoogle } from 'react-icons/ai'
import FadeLoader from 'react-spinners/FadeLoader'
import { useSelector, useDispatch } from 'react-redux'
import { customer_login, messageClear } from '../../store/reducers/authReducer'
import toast from 'react-hot-toast'
import api from '../../api/api'
import Loader from '../../utils/Loader'

const VerifyEmail = () => {
    const [isLoading, seIsLoading] = useState(true)
    const { loader, successMessage, errorMessage, userInfo } = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const {
        state: {firstName, lastName, password, address, district, email, phoneNumber,referredBy },
      } = useLocation()
    const navigate = useNavigate()
    const [state, setState] = useState({
        otp: ''
    })
    const inputHandle = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        seIsLoading(true)
        try{
            const data = await api.post("/reseller/register", {firstName, lastName, password, address, district, email, phoneNumber,referredBy, otp:state.otp})
                  if(data.status === 200){
                    window.location.href = data.data.bkashURL
                  }
        }catch(err){
            toast.error(err.response.data.message)
        }
        seIsLoading(false)
    }



    useEffect(() => {
        seIsLoading(false)
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
        }
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if(userInfo){
            navigate('/')
        }
    }, [successMessage, errorMessage])

    return (
        <div>
            <Headers />
            {
                loader && <div className='w-screen h-screen flex justify-center items-center fixed left-0 top-0 bg-[#38303033] z-[999]'>
                    <FadeLoader />
                </div>
            }
            <div className='border  border-t-[#0002] mt-4'>
                <div className='w-full justify-center items-center p-10 sm:p-0'>
                     <div className="min-w-[350px] w-[40%]  mx-auto bg-white rounded-3xl border border-[#0002]">
                        <div className='px-8 py-8 w-full'>
                            <h2 className='text-center w-full text-xl text-slate-600 font-bold'>OTP</h2>
                            <div>
                                <form onSubmit={handleSubmit} className='text-slate-600'>
                                    <div className='relative py-3 gap-1 mb-2'>
                                        <input onChange={inputHandle} value={state.otp} type="text" className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md' id='email' name='otp' required />
                                        
                                        <label htmlFor="email"  className="absolute label">Enter otp from your email</label>
                                    </div>
                                    <div>*Make sure to check spam folder if you cant find email</div>
                                    {
                                        isLoading ? <Loader/>:
                                        <button className='px-8 w-full py-2 bg-[#154425] shadow-lg hover:shadow-[#1544258b] text-white rounded-md'>Verify OTP</button>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default VerifyEmail