import React,{useEffect} from "react";
import toast from 'react-hot-toast'
import Ratings from "../components/Ratings";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  add_to_card,
  messageClear,
  add_to_wishlist,
} from "../store/reducers/cardReducer";
import { FaHeart } from "react-icons/fa";

const ProductCard = ({ product, index, bgColor }) => {

  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const nameTrim = (name) => {
    if (name.length > 25) {
      return name.slice(0, 25) + "...."
    } else {
      return name
    }
  }


  const add_card = () => {
    if (userInfo) {
      dispatch(
        add_to_card({
          userId: userInfo.id,
          quantity: 1,
          productId: product._id,
        })
      );
    } else {
      navigate("/login");
    }
  };


  

  const add_wishlist = () => {
    if (userInfo) {
      dispatch(
        add_to_wishlist({
          userId: userInfo.id,
          productId: product._id,
          name: product.name,
          price: product.totalPrice,
          image: product.images[0],
          discount: product.discount,
          rating: product.rating,
        })
      );
    } else {
      navigate("/login");
    }
  };


  const buy = () => {
    let price = product.price + product.adminProfit;
    // if (product.discount !== 0) {
    //   price = total
    // } else {
    //   price = product.totalPrice
    // }
    const obj = [
      {
        sellerId: product.sellerId,
        price: product.totalPrice - product.discount,
        products: [
          {
            quantity: 1,
            productInfo: product,
          },
        ],
      },
    ];
    navigate(`/shipping`, {
      state: {
        products: obj,
        price: product.totalPrice - product.discount,
        items: 1,
      },
    });
  };


  return (
    <div
      key={index}
      className="sm:flex-none m-2 w-[250px] sm:w-[150px]"
    >

      <div class="relative flex w-full flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md">
        <Link class="relative mx-3 mt-3 flex h-60 sm:h-40 overflow-hidden rounded-xl"
          to={`/product/details/${product._id}`}>
          <img class="object-cover mx-auto" src={"/products/" + product?.images[0]} alt="product image" />
          {product.discount > 0 && <span class="absolute top-0 left-0 m-2 rounded-full bg-black px-2 text-center text-sm font-medium text-white">৳{Math.floor(100 - ((product.totalPrice - product.discount) / product.totalPrice) * 100)}% OFF</span>}
        </Link>
        <Link class="mt-4 px-5" to={`/product/details/${product._id}`}>
          <div>
            <h5 class="text-base tracking-tight text-slate-900">{product.name}</h5>
          </div>
          <div class="mt-2 mb-5 flex flex-col items-center justify-between">
            <p className="flex flex-col items-center">
              <span class="text-3xl sm:text-xl font-bold text-slate-900">৳{product.totalPrice - product.discount
              }</span>
              {product.discount > 0 && <span class="text-sm text-slate-900 line-through">৳{product.totalPrice}%</span>}
            </p>
            <div class="flex items-center">
              <Ratings ratings={product.rating} />

              <span class="mr-2 ml-3 rounded bg-yellow-200 px-2.5 py-0.5 text-xs font-semibold">{product.rating}</span>
            </div>
          </div>
        </Link>
        <div className="pb-3 px-5">
<button onClick={add_wishlist} class="w-full flex items-center justify-center rounded-md bg-green-900 px-5 sm:px-2 py-2.5 text-center text-sm sm:text-xs font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
          <FaHeart className="mr-2"/>
            <span className="sm:text-[9px]">
            Add to Wishlist
            </span>
            </button>


          {/* <button onClick={buy} class="w-full mt-2 flex items-center justify-center rounded-md bg-yellow-500 px-5 sm:px-2 py-2.5 text-center text-sm sm:text-xs font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-6 w-6 sm:h-4 sm:w-4 sm:mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            Buy now</button> */}
        </div>
      </div>

    </div>
  );
};

export default ProductCard;
