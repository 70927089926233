import React from "react";
import { GiDeliveryDrone } from "react-icons/gi";
import { MdOutlineSupportAgent } from "react-icons/md";
import { RiSecurePaymentFill } from "react-icons/ri";

const InfoStates = () => {
  return (
    <div className="flex justify-between items-center sm:items-start max-w-[1200px] w-[90%] mx-auto py-24 sm:py-14 flex-1">
      <div className="flex justify-center sm:flex-col sm:items-center text-[#315e20] sm:m-3 sm:flex-1">
        <div className="max-w-[100px] min-w-[60px] w-[10vw] aspect-square border-[#315e20]  border-4 rounded-full flex justify-center items-center group hover:bg-[#315e20] transition-all cursor-pointer">
          <GiDeliveryDrone className="text-4xl group-hover:text-white group-hover:text-6xl transition-all" />
        </div>
        <div className="ml-6 sm:ml-0 sm:text-center">
          <p className="text-2xl text-gray-900 font-bold sm:text-xl">Home Delivery</p>
          <p className="text-gray-700">All Over Bangladesh</p>
        </div>
      </div>
      <div className="flex justify-center sm:flex-col sm:items-center text-[#315e20] sm:m-3 sm:flex-1">
        <div className="max-w-[100px] min-w-[60px] w-[10vw] aspect-square border-[#315e20]  border-4 rounded-full flex justify-center items-center group hover:bg-[#315e20] transition-all cursor-pointer">
          <MdOutlineSupportAgent className="text-4xl group-hover:text-white group-hover:text-6xl transition-all" />
        </div>
        <div className="ml-6 sm:ml-0 sm:text-center">
          <p className="text-2xl text-gray-900 font-bold sm:text-xl">Online Support</p>
          <p className="text-gray-700">4/10 Technical Support</p>
        </div>
      </div>
      <div className="flex justify-center sm:flex-col sm:items-center text-[#315e20] sm:m-3 sm:flex-1">
        <div className="max-w-[100px] min-w-[60px] w-[10vw] aspect-square border-[#315e20]  border-4 rounded-full flex justify-center items-center group hover:bg-[#315e20] transition-all cursor-pointer">
          <RiSecurePaymentFill className="text-4xl group-hover:text-white group-hover:text-6xl transition-all" />
        </div>
        <div className="ml-6 sm:ml-0 sm:text-center">
          <p className="text-2xl text-gray-900 font-bold sm:text-xl">Secure Payment</p>
          <p className="text-gray-700">All Payments are secure</p>
        </div>
      </div>
    </div>
  );
};

export default InfoStates;
