import React, { useEffect, useState } from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import { FaFacebookF } from "react-icons/fa";
import FadeLoader from "react-spinners/FadeLoader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineGoogle } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import Loader from "../utils/Loader"
import "./formStyle.css";

import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

import { FaLock } from "react-icons/fa6";

import { reseller_register, messageClear } from "../store/reducers/authReducer";
import api from "../api/api";

const ResellerRegister = () => {
  const navigate = useNavigate();
  const { loader, successMessage, errorMessage, userInfo } = useSelector(
    (state) => state.auth
  );
  const {pathname} = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch();
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    address: "",
    district: "",
    referredBy: undefined,
  });
  
  const [showPass, setShowPass] = useState(false)
  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  // register reseller
  const register = async(e) => {
    setIsLoading(true)
    e.preventDefault();
    
    try {
      const data = await api.post("/reseller/validation", state)
      if(data.status === 200){
        navigate('/reseller/verify-email', {
          state:{
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
            phoneNumber: state.phoneNumber,
            password: state.password,
            address: state.password,
            district: state.district,
            referredBy: state.referredBy,
          }
        })
      }
    }catch(error){
      toast(error.response.data.message)
    }
    setIsLoading(false)
  };
  useEffect(() => {
    setIsLoading(true)
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (userInfo) {
      navigate("/");
    }
    setIsLoading(false)
  }, [successMessage, errorMessage]);

  return (
    <div>
      {loader && (
        <div className="w-screen h-screen flex justify-center items-center fixed left-0 top-0 bg-[#38303033] z-[999]">
          <FadeLoader />
        </div>
      )}
      <Headers />
      <div className="border  border-t-[#0002] mt-4">
        <div className="w-full justify-center items-center p-10 md:px-2">
          <div className="flex justify-center mb-6">
            <Link to={'/reseller/login'} className="text-3xl text-gray-400 mr-3 font-extrabold">Log In</Link>
            <Link to={'/reseller/register'} className="text-3xl text-gray-900 ml-3 font-extrabold">Register</Link>

          </div>
          <div className="min-w-[350px] w-[40%]  mx-auto bg-white rounded-3xl border border-[#0002]">
            <div className="px-8 py-8">
              <h2 className="text-center w-full text-xl text-slate-600 font-bold">
                Register As Reseller
              </h2>
              <div>
                <form onSubmit={register} className="text-slate-600">
                  <div className="flex justify-between gap-3">
                    <div className="relative py-3 w-[50%]">
                      <input
                        onChange={inputHandle}
                        value={state.firstName}
                        type="text"
                        className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                        id="name"
                        name="firstName"
                        //   placeholder="first name"
                        required
                      />
                      <label htmlFor="firstName" className="absolute label">
                        First Name *
                      </label>
                    </div>
                    <div className="relative py-3 w-[50%]">
                      <input
                        onChange={inputHandle}
                        value={state.lastName}
                        type="text"
                        className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                        id="name"
                        name="lastName"
                        // placeholder="lastName"
                        required
                      />
                      <label htmlFor="lastName" className="absolute label">
                        Last Name
                      </label>
                    </div>
                  </div>
                  <div className="relative py-3">
                    <input
                      onChange={inputHandle}
                      value={state.email}
                      type="email"
                      className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                      id="email"
                      name="email"
                      //   placeholder="email"
                      required
                    />
                    <label htmlFor="email" className="absolute label">
                      Email *
                    </label>
                  </div>
                  <div className="relative py-3">
                    <input
                      onChange={inputHandle}
                      value={state.phoneNumber}
                      type="text"
                      className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                      id="email"
                      name="phoneNumber"
                      //   placeholder="number"
                      required
                    />
                    <label htmlFor="Phone" className="absolute label">
                      Phone number *
                    </label>
                  </div>
                  <div className="relative py-3">
                    <input
                      onChange={inputHandle}
                      value={state.password}
                      type={showPass? "text":"password"}
                      className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                      id="password"
                      name="password"
                      //   placeholder="password"
                      required
                    />
                    <label htmlFor="password" className="absolute label">
                      Password *
                    </label>
                    
                    <div
                      className="absolute right-[20px] top-[50%] translate-y-[-50%] cursor-pointer"
                      onClick={() => {
                        setShowPass(!showPass);
                      }}
                    >
                      {showPass ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                  <hr className="md:my-3 my-7" />
                  <div className="relative py-3">
                    <input
                      onChange={inputHandle}
                      value={state.district}
                      type="text"
                      className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                      id="district"
                      name="district"
                      required
                    />
                    <label htmlFor="district" className="absolute label">
                      District *
                    </label>
                  </div>
                  <div className="relative py-3">
                    <input
                      onChange={inputHandle}
                      value={state.address}
                      type="text"
                      className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                      id="address"
                      name="address"
                      required
                    />
                    <label htmlFor="Address" className="absolute label">
                      Business Name *
                    </label>
                  </div>
                  <div className="relative py-3">
                    <input
                      onChange={inputHandle}
                      value={state.referredBy}
                      type="text"
                      className="w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#154425] rounded-xl"
                      id="referredBy"
                      name="referredBy"
                      optional
                    />
                    <label htmlFor="password" className="absolute label">
                      Referral
                    </label>
                  </div>
                  <div className="md:text-lg text-3xl text-center font-bold border border-[#154425] flex text-[#154425] items-center justify-center py-2 rounded-xl">
                    
                  <FaLock className="mr-4" />
                    <span>
                        
                    Secure Payment
                    </span>
                  </div>

                  <div className="flex flex-col justify-center items-center">
                    <p className="my-3">
                      Reseller Sign-up fee: 
                      <b className="font-[900]"> 1200 BDT</b>
                    </p>
                    {isLoading? <Loader/> :
                    <button
                      //   onClick={handlePayment}
                      className="bg-[#154425] md:px-14 px-28 py-3 rounded-xl text-white font-bold flex items-center shadow-lg hover:shadow-[#1544258b]"
                    >
                      <FaLock className="mr-4" />
                      <span>Join As Reseller</span>
                    </button>
                    }
                  </div>
                </form>
              </div>
              <div className="text-center text-slate-600 pt-9">
                <p>
                  Already A Member?{" "}
                  <Link className="text-blue-500" to="/reseller/login">
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResellerRegister;
