import React from 'react'
import { Link } from 'react-router-dom'

const OrderPaymentSuccess = () => {
  return (
    <div className='flex h-[100vh] items-center justify-center flex-col'>
      <img src="/success.svg" alt="success img" />
      <Link to={"/dashboard"} className='mt-6 bg-green-800 px-24 py-4 rounded-full text-white text-xl font-bold'>Dashboard</Link>
    </div>
  )
}

export default OrderPaymentSuccess