const addToken = () => {
    const token = localStorage.getItem("Token") || sessionStorage.getItem("Token");
    if(token === undefined){
        return 1;
    }
    return {
        headers: {
            token: token
        }
    };
};

export default addToken;