import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get_order } from "../../store/reducers/orderReducer";
import api from "../../api/api";

const Order = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const { myOrder } = useSelector((state) => state.order);
  const { userInfo } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState();
  const [deliveryStatus, setDeleveryStatus] = useState();
  useEffect(() => {
    dispatch(get_order(orderId));
  }, [orderId]);

  const getSteadFast = async () => {
    try {
      const { data } = await api.get(`/steadfast/check-status/${orderId}`);
      setDeleveryStatus(data.data);
    } catch (err) {
      setDeleveryStatus(false);
    }
  };
  useEffect(() => {
    // setIsLoading(true);
    getSteadFast();
  }, []);
  console.log(myOrder);
  return (
    <div className="bg-white p-5">
      <h2 className="text-slate-600 font-semibold">
        Oreder ID: #{myOrder._id}
      </h2>
      <p className="text-slate-600 font-semibold">Date: {myOrder.date}</p>
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-3">
        <div className="flex flex-col gap-1">
          <h2 className="text-slate-600 font-semibold">
            Deliver to: {myOrder?.shippingInfo?.totalAddress}
          </h2>
          <p>
            <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
              Home
            </span>
            <span className="text-slate-600 text-sm">
              {myOrder.shippingInfo?.address} {myOrder.shippingInfo?.province}{" "}
              {myOrder.shippingInfo?.city} {myOrder.shippingInfo?.area}
            </span>
          </p>
          {/* <p className="text-slate-600 text-sm font-semibold">
            Email to {userInfo.email}
          </p> */}
          <p className="text-slate-600 text-sm font-semibold">
            Number: {myOrder&& myOrder?.shippingInfo?.state?.phone}
          </p>
          {/* <p className="text-slate-600 text-sm font-semibold">
          consignment id: {myOrder?.consignment_id}
          </p> */}
          {myOrder?.tracking_code&&<p className="text-slate-600 text-sm font-semibold">
          Tracking code: {myOrder?.tracking_code}
          </p>}
        </div>
        <div className="text-slate-600">
          <h2>Price: ${myOrder.price} including shipping fee</h2>
          <p>
            Payment status:{" "}
            <span
              className={`py-[1px] text-xs px-3 ${
                myOrder.payment_status === "paid" || myOrder.onlyDeliveryPaid === true ? "bg-green-100 text-green-800"
                  : "bg-red-100 text-red-800"
              } rounded-md `}
            >
              {myOrder.onlyDeliveryPaid === true? "Delivery charge Paid": myOrder.payment_status}
            </span>
          </p>
          <p>
            Order status:{" "}
            {deliveryStatus ? (
              <span
                className={`py-[1px] text-xs px-3 ${
                  myOrder.payment_status === "paid"
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800"
                } rounded-md `}
              >
                {deliveryStatus.delivery_status}
              </span>
            ) : (
              <span
                className={`py-[1px] text-xs px-3 ${
                  myOrder.delivery_status === "paid"
                    ? "bg-indigo-100 text-indigo-800"
                    : "bg-red-100 text-red-800"
                } rounded-md `}
              >
                {myOrder.delivery_status}
              </span>
            )}
          </p>
        </div>
      </div>
      <div className="mt-3">
        <h2 className="text-slate-600 text-lg pb-2">Products</h2>
        <div className="flex gap-5 flex-col">
          {myOrder.products?.map((p, i) => (
            <div key={i}>
              <div className="flex gap-5 justify-start items-center text-slate-600 sm:flex-col">
                <div className="flex gap-2">
                  <img
                    className="w-[55px] h-[55px] object-cover"
                    src={"/products/" + p.images[0]}
                    alt="image"
                  />
                  <div className="flex text-sm flex-col justify-start items-start">
                    <Link>{p.name}</Link>
                    <p>
                      <span>Brand: {p.brand}</span>
                      <span>Quantity: {p.quantity}</span>
                    </p>
                    <p>
                      <span>Quantity: {p.quantity}</span>
                    </p>
                    <p>
                      <span>Color: {p.color}</span>
                    </p>
                    {
                      p.parentCategory.name === "Smart phone & accessories"?
                      
                    <p>
                    <span>Storage: {p.size}</span>
                  </p>:
                  
                  <p>
                  <span>Size: {p.size}</span>
                </p>
                    }
                  </div>
                </div>
                <div className="pl-4 sm:pl-0">
                  <h2 className="text-md text-orange-500">
                    ৳{userInfo.role !== 'reseller'?p.totalPrice - p.discount:
                    <span> Cash on delivery Amount -   {myOrder.price - myOrder.shippingFee}</span>
                    }
                  </h2>
                  {p.discount > 0 && (
                    <p className="line-through">{p.totalPrice}</p>
                  )}
                  {p.discount > 0 && (
                    <p>
                      -
                      {Math.floor(
                        100 - ((p.totalPrice - p.discount) / p.totalPrice) * 100
                      )}
                      %
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {
       myOrder?.tracking_code && <div className="flex w-full justify-center items-center sm:mt-3"> 
        <a className="text-lg text-green-600 hover:underline sm:text-sm" href={`https://steadfast.com.bd/t/${myOrder?.tracking_code}`}>Track Your Product here</a>
      </div>}
    </div>
  );
};

export default Order;
