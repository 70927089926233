import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import api from "../api/api";
import { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import Loader from "../utils/Loader";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Story = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState();
  const [parPage, setParPage] = useState(5);

  const getBanner = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get("/home/stories/");
      setImages(data);
    } catch (err) {
      // toast.error("error fetching image")
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getBanner();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 3,
      slidesToSlide: 1, 
    },
  };

  return (
    <div className="max-w-[1200px] w-[90%] flex flex-wrap mx-auto rounded-2xl py-16 sm:pt-5">
      <div className="w-full">
        <div className="flex items-center mb-10">
            
        <p className="text-4xl font-bold flex items-center sm:text-xl text-green-600">Stories</p>
        
        <div className="w-[100px] h-[2px] bg-[#154425] ml-4"></div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="parent">
            <Carousel
              responsive={responsive}
              autoPlay={true}
              swipeable={true}
              draggable={true}
              // showDots={false}
              infinite={true}
              partialVisible={false}
              // dotListClass="custom-dot-list-style"
            >
              {images.map((imageUrl, index) => {
                return (
                  <div className="slider sm:p-1 w-full flex justify-center" key={index} >
                    <img src={"https://aladdinshopee.com/home/" + imageUrl.image} className="rounded-2xl" alt="story" />
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
      </div>
    </div>
  );
};

export default Story;
