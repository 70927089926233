import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Headers from "../components/Headers";
import Banner from "../components/Banner";
import Story from "../components/story";
import InfoStates from "../components/infoStates";

import FeatureProducts from "../components/products/FeatureProducts";
import Products from "../components/products/Products";
import Footer from "../components/Footer";
import { get_products } from "../store/reducers/homeReducer";
import { Link } from "react-router-dom";
import Button from "../components/Button";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import api from "../api/api";
import { useState } from "react";
import Categories from "../components/Categorys";
import BottomNav from "../components/BottomNav";
import SearchBox from "../components/SearchBox";

import {
  add_to_card,
  messageClear,
  add_to_wishlist,
} from "../store/reducers/cardReducer";
import toast from 'react-hot-toast'
import WhatsAppCircle from "../components/WhatsAppCircle";
const Home = () => {
  const dispatch = useDispatch();
  const { products, latest_product, topRated_product, discount_product } =
    useSelector((state) => state.home);
  const { errorMessage, successMessage } = useSelector((state) => state.card);


  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState();
  const [parPage, setParPage] = useState(5);

  const getBanner = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get("/home/banner/");
      setImages(data);
    } catch (err) {
      // toast.error("error fetching image")
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getBanner();
  }, []);

  const { userInfo } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(get_products());
  }, []);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, successMessage]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 200 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="w-full">
      <Headers />
      <SearchBox/>

      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        <Banner />
        {images != undefined &&
          images?.map((banner, i) => {
            return (
              banner && (
                <div
                  key={i}
                  className="w-full md-lg:mt-6 sm:mt-2 flex items-center justify-center"
                >
                  <img
                    src={"https://aladdinshopee.com/home/" + banner.image}
                    alt="banner"
                    className="object-cover max-w-[1200px] min-w-[300px] w-[90%] sm:w-[95%] rounded-3xl h-[500px] sm:h-[240px]"
                  />
                </div>
              )
            );
          })}
      </Carousel>
      <Story />
          <Categories/>
      <div className="py-10">
        <div className="max-w-[1200px] w-[90%] sm:w-[95%] flex flex-wrap mx-auto">
          <div className="w-full">
            <Products title="New Arrivals" products={products} />
          </div>
        </div>
      </div>

      <div className="bg-[#154425] text-center py-16 mt-10">
        <div className="text-4xl font-bold text-white my-6">
          Get Exiting new offers right at your fingertips!?
        </div>
        <Button text={"Get your offer!"} link={"/shops"} />
      </div>
      <div className="py-10">
        <div className="max-w-[1200px] w-[80%] sm:w-[95%] flex flex-wrap mx-auto">
          <div className="w-full">
            <Products title="Discount Products" products={discount_product} />
          </div>
        </div>
      </div>
      {!userInfo && (
        <div className="bg-[#fbde44ff] text-center py-16">
          <div className="text-4xl font-bold text-gray-900 my-6">
            Want to earn with us?
          </div>
          <a
            href={"/reseller/register"}
            className="border-2 border-white rounded-full px-6 py-3 text-white font-bold hover:bg-white hover:text-gray-900 transition-all block w-max mx-auto"
          >
            Join us as a Reseller
          </a>
        </div>
      )}
      

      <div className="py-10">
        <div className="max-w-[1200px] w-[80%] sm:w-[95%] flex flex-wrap mx-auto">
          <div className="w-full">
            <Products title="Products for you" products={latest_product} />
          </div>
        </div>
      </div>
      <WhatsAppCircle/>
      <BottomNav/>
      <InfoStates />
      <Footer />
    </div>
  );
};

export default Home;
