import { useParams, Link } from "react-router-dom";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import api from "../api/api";
import { useEffect, useState} from "react";
import BottomNav from "../components/BottomNav";

const SubCategory = () => {
  const {cateId} = useParams();
  const [category, setCategory]= useState('')

  const getSubCategory = async ()=>{
    try{
      const {data} = await api.get(`/categoryBy/${cateId}`)
      setCategory(data)
    }catch(err){
      console.log(err)
    }
  }
  useEffect(()=>{
    getSubCategory()
  },[])

  
  return (
    <div>
      <Headers />
      <div className="mb-5">
        <div className="max-w-[1200px] w-[90%] mx-auto rounded-2xl mt-7">
          <div className="flex items-center text-4xl text-gray-900 font-bold relative pb-[45px]">
            {/* <h2>Select Sub Category</h2> */}
            {/* <div className="w-[100px] h-[2px] bg-[#154425] ml-4"></div> */}
          </div>
          <div className="flex items-center justify-center">
          <div className="flex flex-wrap gap-1 justify-center">
            {category && category?.totalCategory[0].childId?.map((sub, i) => {
                return (
                  <a
                  key={i}
                    href={`/products/${sub._id}`}
                    className="bg-green-200 hover:bg-green-400 transition-all rounded-xl pb-4 w-[11vw] max-w-[180px] min-w-[100px] flex flex-col justify-between overflow-hidden"
                  >
                    <div>
                      <img
                        src={"https://aladdinshopee.com/category/" + sub?.icon}
                        className="w-full aspect-square object-cover"
                        alt="category"
                      />
                    </div>
                    <div className="mt-4 px-2 font-semibold text-green-900 sm:text-sm">
                      {sub?.name}
                    </div>
                  </a>
                );
              
            })}
          </div>
          </div>
        </div>
      </div>
      <BottomNav/>
      <Footer />
    </div>
  );
};

export default SubCategory;
