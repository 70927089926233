import React, { useState,useEffect } from 'react'
import Headers from '../components/Headers'
import Footer from '../components/Footer'
import { FaFacebookF } from 'react-icons/fa'
import { Link,useNavigate, useParams } from 'react-router-dom'
import { AiOutlineGoogle } from 'react-icons/ai'
import FadeLoader from 'react-spinners/FadeLoader'
import { useSelector, useDispatch } from 'react-redux'
import { customer_login, messageClear } from '../store/reducers/authReducer'
import toast from 'react-hot-toast'
import api from '../api/api'
import Loader from '../utils/Loader'

const ForgotPass = () => {
    const {role} = useParams()
    const [isLoading, seIsLoading] = useState(true)
    const { loader, successMessage, errorMessage, userInfo } = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const [state, setState] = useState({
        email: ''
    })
    const inputHandle = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        seIsLoading(true)
        try{
            const {data} = await api.post('/forgot-password', {email: state.email, role:role})
            toast.success(data.message)
            navigate(`/verify-otp/${role}/${state.email}`)
        }catch(err){
            toast.error(err.response.data.message)
        }
        seIsLoading(false)
    }

    useEffect(() => {
        seIsLoading(false)
        if (successMessage) {
            toast.success(successMessage)
            dispatch(messageClear())
        }
        if (errorMessage) {
            toast.error(errorMessage)
            dispatch(messageClear())
        }
        if(userInfo){
            navigate('/')
        }
    }, [successMessage, errorMessage])

    return (
        <div>
            <Headers />
            {
                loader && <div className='w-screen h-screen flex justify-center items-center fixed left-0 top-0 bg-[#38303033] z-[999]'>
                    <FadeLoader />
                </div>
            }
            <div className='border  border-t-[#0002] mt-4'>
                <div className='w-full justify-center items-center p-10 sm:p-0'>
                     <div className="min-w-[350px] w-[40%]  mx-auto bg-white rounded-3xl border border-[#0002]">
                        <div className='px-8 py-8 w-full'>
                            <h2 className='text-center w-full text-xl text-slate-600 font-bold'>Your Email</h2>
                            <div>
                                <form onSubmit={handleSubmit} className='text-slate-600'>
                                    <div className='relative py-3 gap-1 mb-2'>
                                        <input onChange={inputHandle} value={state.email} type="email" className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-indigo-500 rounded-md' id='email' name='email' required />
                                        
                                        <label htmlFor="email"  className="absolute label">Enter the email address of your account</label>
                                    </div>
                                    {
                                        isLoading ? <Loader/>:
                                        <button className='px-8 w-full py-2 bg-[#154425] shadow-lg hover:shadow-[#1544258b] text-white rounded-md'>Get Otp</button>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ForgotPass