import React, { useState } from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import Stripe from "../components/Stripe";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import addToken from "../api/addToken";
import api from "../api/api";
import Loader from "../utils/Loader";
const Payment = () => {
  const {
    state: { price, items, orderId, shipping_fee },
  } = useLocation();
  const [paymentMethod, setPaymentMethod] = useState("deliveryOnlyBkash");
  const [isLoading, setIsLoading] = useState(false);

  const handleBkash = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await api.post(
        "/payment/bkash/payment/order",
        { orderId },
        addToken()
      );
      if (data.status === 200) {
        window.location.href = data.data.bkashURL;
      }
    } catch (error) {
      toast(error.response.data.message);
    }
    setIsLoading(false);
  };
  const handleDeliveryOnlyBkash = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await api.post(
        "/payment/bkash/payment/order/delivery-only",
        { orderId },
        addToken()
      );
      if (data.status === 200) {
        window.location.href = data.data.bkashURL;
      }
    } catch (error) {
      toast(error.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Headers />
      <section className="bg-[#eeeeee]">
        <div className="w-[85%] lg:w-[90%] md:w-[90%] sm:w-[90%] mx-auto py-16 mt-4">
          <div className="flex flex-col justify-center items-center">
            <div className="w-7/12 md:w-full">
              <div className="md:pl-0 md:mb-0">
                <div className="bg-white shadow p-5 text-slate-600 flex flex-col gap-3">
                  <h2>Order Summary</h2>
                  <div className="flex justify-between items-center">
                    <span>{items} items and shipping fee included</span>
                    <span>৳{price}</span>
                  </div>
                  <div className="flex justify-between items-center font-semibold">
                    <span>Total Amount</span>
                    <span className="text-lg text-green-500">৳{price}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-7/12 md:w-full bg-white">
              <div className="">
                <div className="flex">
                  <div
                    onClick={() => setPaymentMethod("bkash")}
                    className={`w-[50%]  cursor-pointer py-4  ${
                      paymentMethod === "bkash" ? "bg-[#f7006e]" : "bg-slate-100"
                    }`}
                  >
                    <div className="flex flex-col justify-center items-center">
                      <span  className={` font-bold  ${
                      paymentMethod === "bkash" ? "text-white" : "text-slate-600"
                    }`}>Full Payment</span>
                    </div>
                  </div>
                  <div
                    onClick={() => setPaymentMethod("deliveryOnlyBkash")}
                    className={`w-[50%]  cursor-pointer py-4  ${
                      paymentMethod === "deliveryOnlyBkash" ? "bg-[#f7006e]" : "bg-slate-100"
                    }`}
                  >
                    <div className="flex flex-col justify-center items-center">
                      <span className={` font-bold  ${
                      paymentMethod === "deliveryOnlyBkash" ? "text-white" : "text-slate-600"
                    }`}>
                    Cash on Delivery</span>
                    </div>
                  </div>

                </div>
                {paymentMethod === "bkash" ? (
                  <div className="w-full px-4 py-8 bg-white shadow-sm">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div className="flex flex-col items-center">
                        
                        <img src="https://aladdinshopee.com/images/payment/bkash.png" alt="bkash" />
                        
                        <div className="font-semibold py-4 sm:text-sm sm: text-center">*Pay total ৳<span className="text-[#f7006e]">
                            {price}</span> including shipping fee</div>
                        <button
                        className="w-full py-4 hover:shadow-orange-500/20 hover:shadow-lg bg-green-500 text-white rounded-xl"
                        onClick={handleBkash}
                      >
                        Pay Now
                      </button>
                      </div>
                    )}
                  </div>
                ):
                <div className="w-full px-4 py-8 bg-white shadow-sm">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div className="flex flex-col items-center">
                        
                        <img src="https://aladdinshopee.com/images/payment/bkash.png" alt="bkash" />
                        <div className="font-semibold py-4 sm:text-sm sm: text-center">*Pay delivery Charge ৳<span className="text-[#f7006e]">
                            {shipping_fee}</span> to confirm order</div>
                        <button
                        className="w-full py-4 hover:shadow-orange-500/20 hover:shadow-lg bg-green-500 text-white rounded-xl"
                        onClick={handleDeliveryOnlyBkash}
                      >
                        Pay Now
                      </button>
                      </div>
                    )}
                  </div>
                
                }
                {/* {
                                    paymentMethod === 'nogot' && <div className='w-full px-4 py-8 bg-white shadow-sm'>
                                        <button className='px-10 py-[6px] rounded-sm hover:shadow-wrange-500/20 hover:shadow-lg bg-orange-500 text-white'>Pay Now</button>
                                    </div>
                                }
                                {
                                    paymentMethod === 'roket' && <div className='w-full px-4 py-8 bg-white shadow-sm'>
                                        <button className='px-10 py-[6px] rounded-sm hover:shadow-wrange-500/20 hover:shadow-lg bg-orange-500 text-white'>Pay Now</button>
                                    </div>
                                } */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Payment;
