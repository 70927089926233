import React from 'react'
import { Link } from 'react-router-dom'

const OrderPaymentFailed = () => {
  return (
    <div className='flex h-[100vh] items-center justify-center flex-col'>
      <img src="/failed.webp" alt="failed img" className='w-[20vw] max-w-[250px] min-w-[150px]'/>
      <Link to={"/dashboard"} className='mt-6 bg-red-800 px-24 py-4 rounded-full text-white text-xl font-bold'>Dashboard</Link>
    </div>
  )
}

export default OrderPaymentFailed