import React from "react";
import Headers from "../components/Headers";
import BottomNav from "../components/BottomNav";
import Footer from "../components/Footer";

const Terms = () => {
  return (
    <div>
      <Headers />
      <BottomNav />
      <div className="max-w-[1200px] w-[80vw] mx-auto text-gray-900">
        <b>
          <h1 className="text-3xl py-7">TERMS & CONDITIONS</h1>
        </b>
      </div>
      <div className="bg-gray-100">
        <div className="max-w-[1200px] w-[80vw] mx-auto text-gray-900">
          <div className="pt-6 pb-16">
            <b>
              <h3>DELIVERY/SHIPPING TERMS & CONDITIONS</h3>
            </b>
            We, AladdinShopee values most the online order’s from our honorable
            customer’s. We want to make sure your order’s is/are safe and
            secured by the terms of your demands and ensure you the fastest
            possible delivery into your door step. Once the order is confirmed,
            you will receive a notification from our customer service department
            and if any misinformation arrives, our hotline member will reach you
            for the fastest delivery on course.
            <br />
            <br />
            <b>DELIVERY TIME INSIDE DHAKA:</b>
            <br />
            Once your order is confirmed, we start the processing by 24 hours
            and delivery process will be completed by maximum 3 working days
            into your hand. But mostly, we make sure to get it by 48 hours.
            <br />
            <br />
            <b>DELIVERY TIME OUTSIDE DHAKA:</b>
            <br />
            Once your order is confirmed, we start the processing by 24 hours
            and delivery process will be completed by 5 working days into your
            hand. But mostly, we make sure to get it by 3 full working days.
            <br />
            <b>
              <br />
              DELIVERY CHARGES INSIDE DHAKA:
            </b>
            <br />1 piece product will be charged by Tk.60
            <b>
              <br />
              DELIVERY CHARGES OUTSIDE DHAKA:
            </b>
            <br />
            1 piece product will be charged by Tk.120
            <br />
            <b>DELIVERY METHOD:</b>
            <br />
            We are working with the well reputed Delivery Company’s in
            Bangladesh.
            <br />
            No Refund or Exchange for Discounted Product.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
